import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { LegacyProgressSpinnerMode as ProgressSpinnerMode } from '@angular/material/legacy-progress-spinner';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { InstanceService } from 'src/app/core/services/instance-management/instance.service';
import { SdkServiceService } from 'src/app/core/services/sdk-mangement/sdk-service.service';
import { MatSort, Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { DomSanitizer, Title } from '@angular/platform-browser';
import * as _ from 'lodash';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { AzureBlobStorageService } from 'src/app/core/services/azure-blob-storage/azure-blob-storage.service';
import { MatIconRegistry } from '@angular/material/icon';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import * as JSZip from 'jszip';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { DatePipe } from '@angular/common';
import { InstanceManagement } from 'src/app/shared/enum/instance-management';
import { Deployment } from 'src/app/shared/enum/deployment';
import { take } from 'rxjs';
import { enableDisableIDNFeatures } from 'src/app/shared/enum/disable-idn-feature';
import { USER_CATEGORY_ID } from 'src/app/shared/constants/idn-constants';
import { ShareDataService } from 'src/app/core/services/role-management/share-data.service';
const NO_DOCUMENT = `
    <svg xmlns="http://www.w3.org/2000/svg" width="34.536" height="37.53" viewBox="0 0 34.536 37.53">
    <g id="Group_465" data-name="Group 465" transform="translate(-45.48 -10.639)">
      <path id="Path_301" data-name="Path 301" d="M461.891,292.259H443.219a1.431,1.431,0,0,0-1.429,1.429v9.355h2.873a1.235,1.235,0,0,1,1.051.581l1,1.585.952,1.515.179.285,1.09,1.733.012.02H463.32V293.688A1.431,1.431,0,0,0,461.891,292.259Z" transform="translate(-392.926 -281.62)" fill="#ccc"/>
      <path id="Path_302" data-name="Path 302" d="M476.241,319.769H466.99a.925.925,0,1,0,0,1.849h9.251a.925.925,0,1,0,0-1.849Z" transform="translate(-414.677 -306.27)" fill="#fff"/>
      <path id="Path_303" data-name="Path 303" d="M481.367,363.449h-14.3a1.009,1.009,0,1,0,0,2.019h14.3a1.009,1.009,0,1,0,0-2.019Z" transform="translate(-414.673 -345.409)" fill="#fff"/>
      <path id="Path_304" data-name="Path 304" d="M490.425,398.01a1.009,1.009,0,0,0-.913-.581h-14.3l-.064,0a1.239,1.239,0,0,1,.456.43l1,1.585h12.91a1.008,1.008,0,0,0,.913-1.437Z" transform="translate(-422.817 -375.856)" fill="#fff"/>
      <path id="Path_305" data-name="Path 305" d="M511.237,432.282a1.009,1.009,0,0,0-1-.873H498.28l.179.285,1.089,1.733h10.689a1,1,0,0,0,.585-.188,1.008,1.008,0,0,0,.423-.821A.962.962,0,0,0,511.237,432.282Z" transform="translate(-443.543 -406.304)" fill="#fff"/>
      <path id="Path_306" data-name="Path 306" d="M524.191,310.408l-1.455-.569-7.256-2.832L506.8,303.62a1.432,1.432,0,0,0-1.852.813l-.3.779-.723,1.849-1.05,2.692-.788,2.019-.591,1.514L500.7,315.3l-.592,1.515-.017.044L500,317.1l1.09,1.733.012.02h20.18a1.239,1.239,0,0,1,.966.462l.085-.216,1.042-2.668L525,312.261A1.432,1.432,0,0,0,524.191,310.408Z" transform="translate(-445.084 -291.712)" fill="#6332a4"/>
      <path id="Path_307" data-name="Path 307" d="M571.215,339.971l-.778-.3-7.841-3.06a.925.925,0,1,0-.673,1.724l2.59,1.01,3.2,1.25,2.721,1.062.1.041a.925.925,0,1,0,.673-1.724Z" transform="translate(-500.043 -321.302)" fill="#fff"/>
      <path id="Path_308" data-name="Path 308" d="M559.8,382.74l-3.9-1.524-2.787-1.087-1.489-.581-3.878-1.514-1.26-.492a1.009,1.009,0,0,0-1.271.492c-.013.026-.025.053-.035.081a1.009,1.009,0,0,0,.573,1.307l.322.126,5.169,2.018,4.656,1.817,3.171,1.238a1.009,1.009,0,1,0,.734-1.881Z" transform="translate(-485.497 -357.974)" fill="#fff"/>
      <path id="Path_309" data-name="Path 309" d="M544.819,413.368l-.25-.1-2.45-.956-2.238-.873L536,409.926l-1.878-.733a1.009,1.009,0,1,0-.733,1.881l.94.367,4.688,1.83.482.188.05.02H545.1Z" transform="translate(-474.425 -386.335)" fill="#fff"/>
      <path id="Path_310" data-name="Path 310" d="M524.267,441.816l-.482-.188-2.016-.787a1.008,1.008,0,0,0-1.364.787.952.952,0,0,0-.011.188.118.118,0,0,0,0,.02h3.923Z" transform="translate(-463.357 -414.693)" fill="#fff"/>
      <path id="Path_311" data-name="Path 311" d="M428.833,399.906l-.482-.188h-7.544a.955.955,0,0,0-.011.188h-1.031l.012.02h9.106Zm0,0h-9.068l.012.02h9.106Zm0,0h-9.068l.012.02h9.106Zm.05.02-.05-.02h-9.068l.012.02Zm-18.194,20.818a1.243,1.243,0,0,1-1.241-1.241V395.449a1.243,1.243,0,0,1,1.241-1.242h1.935V394h-1.935a1.452,1.452,0,0,0-1.449,1.45V419.5a1.45,1.45,0,0,0,.849,1.318h18.316l-.05-.077Zm30.319-20.573a1.442,1.442,0,0,0-1.051-.453H419.893l-1.123-1.786-.027-.044-.952-1.515-1.067-1.7A1.453,1.453,0,0,0,415.5,394h-4.808a1.452,1.452,0,0,0-1.449,1.45V419.5a1.45,1.45,0,0,0,.849,1.318,1.429,1.429,0,0,0,.6.131h29.268a1.434,1.434,0,0,0,.6-.131,1.45,1.45,0,0,0,.849-1.318V401.168a1.442,1.442,0,0,0-.4-1ZM441.2,419.5a1.243,1.243,0,0,1-1.242,1.241H410.689a1.243,1.243,0,0,1-1.241-1.241V395.449a1.243,1.243,0,0,1,1.241-1.242H415.5a1.235,1.235,0,0,1,1.051.581l1,1.585.952,1.515.179.285,1.09,1.733.012.02h20.18a1.243,1.243,0,0,1,1.242,1.242Zm-12.366-19.6h-9.068l.013.02h9.106Zm0,0h-9.068l.013.02h9.106Zm0,0h-9.068l.013.02h9.106Zm0,0-.482-.188h-8.458l-1.123-1.786-.095.241,1.09,1.733.012.02h9.106Z" transform="translate(-363.76 -372.783)" fill="#3f3d56" opacity="0.4"/>
    </g>
    </svg>

    `;
const DEPLOYMENT_MENU = `
<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16.224 18.192" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
<path id="Subtraction_1" data-name="Subtraction 1" d="M15.628,19.132H9.878l.031-7.04,1.633,2.982h4.086v4.057Zm-6.582,0H3.331V15.074H7.555l1.521-2.967-.03,7.024ZM17.6,14.206H12.024L9.935,10.391h5.854L17.6,14.2Zm-10.548,0H1.429l-.051-.024,1.872-3.791H9.012L7.055,14.205Zm5.591-4.683H6.468a9.211,9.211,0,0,1-.206-2.979H7.9a1.667,1.667,0,0,0,3.262,0h1.688a9.072,9.072,0,0,1-.21,2.978ZM9.536,7a.889.889,0,1,1,.851-.888A.871.871,0,0,1,9.536,7ZM7.9,5.676H6.4A8.066,8.066,0,0,1,9.528.941a8.065,8.065,0,0,1,3.186,4.734H11.167a1.667,1.667,0,0,0-3.262,0Z" transform="translate(-1.379 -0.94)" fill="#757575"></path>
</svg>
`;
const PACKAGE_ICON = `
<svg xmlns="http://www.w3.org/2000/svg" width="46.13" height="46.807" viewBox="0 0 46.13 46.807">
<g id="Group_576" data-name="Group 576" transform="translate(-72.438)">
  <path id="Path_439" data-name="Path 439" d="M11075.541,384.726l22.932,11.987v23.036l-22.932-11.57Z" transform="translate(-11003 -373.052)" fill="#926e43"/>
  <path id="Path_440" data-name="Path 440" d="M11098.577,396.5l22.932-11.57v23.348l-22.932,11.576Z" transform="translate(-11003.104 -373.052)" fill="#a67e4f"/>
  <path id="Path_441" data-name="Path 441" d="M11075.438,384.726l23.035,11.987,23.095-11.987-23.095-11.778Z" transform="translate(-11003 -372.948)" fill="#d9ac80"/>
  <path id="Path_442" data-name="Path 442" d="M11083.984,380.452l5.941-3.127,22.827,12.091-5.629,2.919Z" transform="translate(-11002.896 -373.156)" fill="#f2d3ba"/>
  <path id="Path_443" data-name="Path 443" d="M11107.124,392.221l5.733-3.013v5.524l-2.919-1.251-2.814,4.065Z" transform="translate(-11003 -372.872)" fill="#d7baa4"/>
</g>
</svg>

`;
@Component({
  selector: 'app-instance-list',
  templateUrl: './instance-list.component.html',
  styleUrls: ['./instance-list.component.scss'],
})
export class InstanceListComponent implements OnInit {
  displayedColumnsAdmin: string[] = [
    'instanceType',
    'customerName',
    'cname',
    'iciversion',
    'requiredBy',
    'status',
    'action',
  ];
  displayedColumnsOps: string[] = [
    'instanceType',
    'partnerName',
    'customerName',
    'cname',
    'iciversion',
    'requesterdDetail',
    'status',
    'action',
  ];
  dataSource: any;
  // isDeploymentDrawer: boolean = false;
  iciInstanceId: number = 0;
  uploadextData: any = { showProgressBar: 'false' };
  value = 50;
  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'indeterminate';
  @ViewChild(MatPaginator, { static: true })
  paginator!: MatPaginator;
  @ViewChild(MatSort) sort: MatSort | undefined;
  breadCrumbItems: any;
  globalList: any;
  @ViewChild('search_input') inputName: any;
  @ViewChild('instanceStatus') custStatus!: MatSelect;
  selectAll = 'selectAll';
  pageIndex: any = 0;
  instanceId: any;
  showDrawer: boolean = false;
  ViewData: any = [];
  viewDrawer: any = false;
  isTrackStatusDrawer: boolean = false;
  currentInstanceStatusID: any;
  StatusClasses: any = [];
  extensionType: any = [];
  extensionName: any = [];
  solutionPackages: any = [];
  showSolutionPackage: any = false;
  isViewInstance: boolean = false;
  isApproveInstance: boolean = false;
  isRejectInstance: boolean = false;
  isViewDeployment: boolean = false;
  isInitiateDeployment: boolean = false;
  isAddInstance: boolean = false;
  isEditInstance: boolean = false;
  isSubmitInstance: boolean = false;
  isViewICITools: boolean = false;
  isWriteUtilities: any;
  skuList: any = [];
  instanceStatusChanges: any = [];
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pageNumber = 1;
  @ViewChild('empTbSort') empTbSort = new MatSort();
  dateError: boolean = false;
  todayDate: any = new Date();
  sortBy: string = '';
  filter: string = '';
  status: string = '';
  pageLength: any = 0;
  sidenavIsOpen: Boolean = false;
  public instanceEnum = InstanceManagement;
  public enableDisableIdnFeaturesEnum = enableDisableIDNFeatures;
  public deploymentEnum = Deployment;
  multiTenantPodMajor: number = 0;
  multiTenantPodMinor: number = 0;
  customerId: number = 0;
  instanceTypeId: number = 0;
  noContent: Boolean = false;
  multiTenantPodPatch: number = 0;
  skuData: any = {};
  isConfigDrawer: boolean = false;
  isLoggingUtilities: any;
  viewIciWorkertaskConfiguration:any;
  isIciWorkertaskConfiguration: boolean = false;
  isViewICIToolLogActionsProd: boolean = false;
  isViewICIToolWriteActionsProd: boolean = false;
  hasApprovalAccessToDebuggingUtilitiesProd:boolean = false;
  statusList:any = [{name:'Draft', id:'1'},
   {name:'Requested', id:'2'},
   {name:'Creation Scheduled', id:'3'},
   {name:'Creation In Progress', id:'4'},
   {name:'Creation Failed', id:'5'},
   {name:'Creation Rejected', id:'6'},
   {name:'Created', id:'7'}];
  listPartner: boolean =false;
  partnerData:any={
     title: 'Partner',
     list: [],
  };
  instanceStatusEnum: any = {
    draft: 1,
    created: 7
  };

  frmRequestDeployment: FormGroup = new FormGroup({
    instanceId: new FormControl(0),
    extensionTypeId: new FormControl(null, Validators.required),
    solutionPackageId: new FormControl(null, Validators.required),
    extensionId: new FormControl(null, Validators.required),
    packageSourceId: new FormControl(0, Validators.required),
    skuId: new FormControl(null, Validators.required),
  });
  userCategory: any;
  isCustomerUser: any;

  constructor(
    private instanceService: InstanceService,
    private sdkService: SdkServiceService,
    private cd: ChangeDetectorRef,
    private router: Router,
    private titleService: Title,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private blobService: AzureBlobStorageService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private permissionService: PermissionsService,
    private datePipe: DatePipe,
    private sharedata: ShareDataService,
  ) {
    iconRegistry.addSvgIconLiteral(
      'no_document_icon',
      sanitizer.bypassSecurityTrustHtml(NO_DOCUMENT)
    );
    iconRegistry.addSvgIconLiteral(
      'deployment_menu',
      sanitizer.bypassSecurityTrustHtml(DEPLOYMENT_MENU)
    );
    iconRegistry.addSvgIconLiteral(
      'package_icon',
      sanitizer.bypassSecurityTrustHtml(PACKAGE_ICON)
    );
  }

  ngOnInit(): void {
    let instanceLabel: string;
    let instancePath: string;
    localStorage.removeItem('instanceID');
    this.titleService.setTitle('Instance Requests | IDN');
    this.viewIciWorkertaskConfiguration  = localStorage.getItem('enableICIWorkerTaskConfigurationFeature');
    this.isIciWorkertaskConfiguration = this.permissionService.getPermission('ViewSDK');
    this.isViewInstance = this.permissionService.getPermission('ViewInstance');
    this.isApproveInstance =
      this.permissionService.getPermission('ApproveInstance');
    this.isRejectInstance =
      this.permissionService.getPermission('RejectInstance');
    this.isViewDeployment =
      this.permissionService.getPermission('ViewDeployment');
    this.isInitiateDeployment =
      this.permissionService.getPermission('InitiateDeployment');
    this.isAddInstance = this.permissionService.getPermission('AddInstance');
    this.isEditInstance = this.permissionService.getPermission('EditInstance');
    this.isSubmitInstance =
      this.permissionService.getPermission('SubmitInstance');
    this.isViewICITools = this.permissionService.getPermission('ViewInstance');
    this.isWriteUtilities = this.permissionService.getPermission('WriteUtilities');
      this.isLoggingUtilities = this.permissionService.getPermission("LoggingUtilities");
      this.userCategory = this.permissionService.getUsercategoryId();
      if (this.userCategory == USER_CATEGORY_ID.Customer) {
          this.isCustomerUser = true;
          this.displayedColumnsAdmin = [
              'instanceType',
              'cname',
              'iciversion',
              'requiredBy',
              'status',
              'action',
          ];
      }
      else {
          this.isCustomerUser = false;
      }
    this.isViewICIToolLogActionsProd = this.permissionService.getPermission('LoggingUtilities-Prod');
    this.isViewICIToolWriteActionsProd = this.permissionService.getPermission('WriteUtilities-Prod');
    this.hasApprovalAccessToDebuggingUtilitiesProd = this.permissionService.getPermission('ApprovalAccessToDebuggingUtilities-Prod');
    this.statusList = this.statusList?.sort((a:any, b:any) =>{ return a.name.localeCompare(b.name);});
    if (this.isApproveInstance) {
      instanceLabel = 'Instance Requests';
      instancePath = 'instanceoperations/instance-requests';
    } else {
      instanceLabel = 'Instance Requests';
      instancePath = 'instanceoperations/instance-requests';
    }

    this.breadCrumbItems = [
      { label: 'Home', path: 'resources' },
      { label: 'Instance Operations', path: instancePath },
      { label: instanceLabel, path: instancePath },
      { label: 'List', path: 'instanceoperations/instance-requests' },
    ];

    this.paginator._intl.itemsPerPageLabel = 'Records per page';
    try {
      this.sdkService
        .on<any>()
        .pipe(take(1))
        .subscribe((data) => {
          if (data != null && data != '') {
            this.uploadextData = data;
            this.getAllInstance();
            this.cd.detectChanges();
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  ngAfterViewInit() {
    this.getAllInstance();
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.pageNumber=event.pageIndex+1;
    let filters = localStorage.getItem('FilterRecordInsta');
    if (filters != null) {
      let Json = {
        search: this.inputName.nativeElement.value,
        status:
          this.custStatus.value == undefined ||
          this.custStatus.value == this.selectAll
            ? ''
            : this.custStatus.value,
        pagesize: this.pageSize,
        pageNumber: this.pageNumber,
        currentPage: this.currentPage,
      };
      localStorage.setItem('FilterRecordInsta', JSON.stringify(Json));
    }

    this.getAllInstance();
  }

  //Get all the instances
  getAllInstance() {
    try {
      let filterValue: any = localStorage.getItem('FilterRecordInsta');
      if (
        filterValue != undefined &&
        filterValue != null &&
        filterValue != ''
      ) {
        this.filter = JSON.parse(filterValue).search;
        if(this.inputName){
          this.inputName.nativeElement.value = this.filter;
        }
        this.status =
          JSON.parse(filterValue).status != undefined &&
          JSON.parse(filterValue).status != this.selectAll
            ? JSON.parse(filterValue).status
            : '';
        if(this.custStatus){
          this.custStatus.value = this.status;
        }
        this.pageSize = JSON.parse(filterValue).pagesize;
        this.pageNumber = JSON.parse(filterValue).pageNumber;
        this.currentPage = JSON.parse(filterValue).currentPage;
      }
      let filterResult: any = [];
      this.instanceService
        .getInstance(
          this.filter,
          this.sortBy,
          this.pageNumber,
          this.pageSize,
          this.status
        )
        .subscribe((resp: any) => {
          if (resp != null && resp != undefined) {
            this.noContent = false;
            if (
              (this.isApproveInstance || this.isRejectInstance) &&
              !(
                this.isViewInstance ||
                !this.isAddInstance ||
                !this.isEditInstance
              )
            ) {
              filterResult = resp.records.filter(
                (obj: any) => obj.status != 'Draft'
              );
            } else {
              filterResult = resp.records;
            }
          } else {
            this.noContent = true;
            filterResult = [];
          }
          this.dataSource = new MatTableDataSource(filterResult);
          this.empTbSort.disableClear = true;
          this.paginator.pageIndex = this.currentPage;
          this.paginator.length = resp.matchingCount;
          this.globalList = filterResult;
          this.dataSource.sortingDataAccessor = (
            data: any,
            sortHeaderId: any
          ) => data[sortHeaderId].toLocaleLowerCase();
        });
    } catch (error) {
      console.log(error);
    }
  }

  openSite(siteUrl:any) {
    this.dialogService.confirmDialog({
      title: this.instanceEnum.redirect_dialog_heading,
      module: 'resources',
      message: this.instanceEnum.redirect_dialog_body,
      confirmText: this.instanceEnum.redirect_dialog_primary_btn,
      cancelText: this.instanceEnum.redirect_dialog_secondary_btn
    }).subscribe((res: any) => {
      if(res){
        window.open("https://" + siteUrl, '_blank');
      }
    });
 }



  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    if (sortState.direction == 'desc') {
      this.sortBy = sortState.active + '_' + sortState.direction;
    } else {
      this.sortBy = sortState.active;
    }
    this.getAllInstance();
  }

  //To Edit instance
  edit(navLink: any, element: any) {
    let Json = {
      search: this.filter,
      status: this.status,
      pagesize: this.pageSize,
      pageNumber: this.pageNumber,
      currentPage: this.currentPage,
    };
    localStorage.setItem('FilterRecordInsta', JSON.stringify(Json));
    localStorage.setItem('instanceID', element.id);
    let instance: any = this.isApproveInstance
      ? 'instance-requests'
      : 'instance-requests';
    navLink = 'home/instanceoperations/' + instance + '/' + navLink;
    this.router.navigate([navLink]);
  }

  //To view instance
  viewInstance(navLink: any, element: any) {
    let Json = {
      search: this.filter,
      status: this.status,
      pagesize: this.pageSize,
      pageNumber: this.pageNumber,
      currentPage: this.currentPage,
    };
    localStorage.setItem('FilterRecordInsta', JSON.stringify(Json));
    localStorage.setItem('instanceID', element.id);
    localStorage.setItem('pageIndex', this.pageIndex);
    let instance: any = this.isApproveInstance
      ? 'instance-requests'
      : 'instance-requests';
    navLink = 'home/instanceoperations/' + instance + '/' + navLink;
    this.sharedata.sendData(JSON.stringify(element));
    this.router.navigate([navLink]);
  }

  public doFilter = () => {
    let filters = localStorage.getItem('FilterRecordInsta');
    if (filters != null) {
      let Json = {
        search: this.inputName.nativeElement.value,
        status:
          this.custStatus.value == undefined ||
          this.custStatus.value == this.selectAll
            ? ''
            : this.custStatus.value,
        pagesize: this.pageSize,
        pageNumber: this.pageNumber,
        currentPage: this.currentPage,
      };
      localStorage.setItem('FilterRecordInsta', JSON.stringify(Json));
    } else {
      this.filter = this.inputName.nativeElement.value;
      this.status =
        this.custStatus.value == undefined ||
        this.custStatus.value == this.selectAll
          ? ''
          : this.custStatus.value;
    }

    this.getAllInstance();
  };

  clearFilter() {
    localStorage.removeItem('FilterRecordInsta');
    this.inputName.nativeElement.value = '';
    this.custStatus.value = '';
    this.pageNumber = 1;
    this.pageSize = 10;
    this.currentPage = 0;
    this.doFilter();
  }

  ClearInput(event: any, filter: any) {
    this.inputName.nativeElement.value = '';
    this.doFilter();
  }

  //Approve instance
  approveRequest(instanceId: number, reqDate: string) {
    this.dialogService
      .confirmDialog({
        title: this.instanceEnum.instance_dialog_confirm_approve_request_title,
        module: 'instance',
        message: this.instanceEnum.instance_dialog_confirm_approve_request_body,
        confirmText:
          this.instanceEnum.instance_dialog_confirm_approve_request_confirm_btn,
        cancelText:
          this.instanceEnum.instance_dialog_confirm_approve_request_cancel_btn,
      })
      .subscribe((res: any) => {
        if (res) {
          let dateResult = this.checkDate(reqDate);
          if (dateResult) {
            this.notificationService.showNotification(
              this.instanceEnum.instance_notify_cannotSendApproval_body,
              'error',
              this.instanceEnum.instance_notify_cannotSendApproval_heading
            );
          } else {
            this.instanceService
              .submitForApproval(instanceId)
              .subscribe((resp: any) => {
                if (resp.status == 200) {
                  this.notificationService.showNotification(
                    this.instanceEnum.instance_notify_instanceRequested_body,
                    'success',
                    this.instanceEnum.instance_notify_instanceRequested_heading
                  );
                  this.getAllInstance();
                  if(this.isApproveInstance || this.isRejectInstance){
                    this.instanceService.getUpdatedCount(true);
                  }
                }
              });
          }
        }
      });
  }



  approveOrReject(id: any) {
    localStorage.setItem('instanceID', id);
    let navLink = 'home/instanceoperations/instance-requests/instance-approval';
    this.router.navigate([navLink]);
  }
  // developer tools changes --start

  onICITools(instanceId: any) {
    localStorage.setItem('instanceID', instanceId);
      let navLink = 'home/instanceoperations/instance-requests/instance-tools-landing';
      this.router.navigate([navLink]);
  }

  // developer tools changes --end
  closedrawer(event: any) {
    this.viewDrawer = false;
    this.getAllInstance();
  }

  navigateDeploymentPage(instanceId: any) {
    let Json = {
      search: this.filter,
      status: this.status,
      pagesize: this.pageSize,
      pageNumber: this.pageNumber,
      currentPage: this.currentPage,
    };
    localStorage.setItem('FilterRecordInsta', JSON.stringify(Json));
    localStorage.setItem('instanceID', instanceId);
    localStorage.setItem('pageIndex', this.pageIndex);
    let navLink = 'home/instanceoperations/instance-requests/initiate-deployment';
    this.router.navigate([navLink]);
  }

  toggleTrackStatus(statusID: any, instanceStatusChanges: any) {
    this.currentInstanceStatusID = statusID;
    this.instanceStatusChanges = instanceStatusChanges.filter(
      (obj: any, pos: any, arr: any) => {
        return (
          arr.map((mapObj: any) => mapObj.statusId).indexOf(obj.statusId) == pos
        );
      }
    );
    for (let i = 1; i <= 7; i++) {
      if (i <= statusID) {
        this.StatusClasses.push('completed');
      } else if (i == statusID + 1) {
        this.StatusClasses.push('progress');
      } else {
        this.StatusClasses.push('noClass');
      }
    }
    if (statusID == 4) {
      this.StatusClasses.splice(6, 0, 'progress');
      this.StatusClasses.join();
    }
    this.isTrackStatusDrawer = !this.isTrackStatusDrawer;
  }
  closeTrackStatus() {
    this.isTrackStatusDrawer = !this.isTrackStatusDrawer;
    this.StatusClasses = [];
  }

  
  //Download zip files
  downloadAllfiles = async (files: any, id: any) => {
    try {
      const zip = new JSZip();
      var FileSaver = require('file-saver');
      let downloadedFiles: any = [];
      let fileName = 'Instance request documents';
      files.forEach((x: any) => {
        this.instanceService
          .getBlobDownloadConnection(id, x)
          .subscribe((resp: any) => {
            if (resp != null && resp != undefined) {
              this.blobService.downloadImageExt(
                resp.sasUrl,
                resp.container,
                resp.blob,
                (response: any) => {
                  if (
                    response._response != undefined &&
                    response._response.status == '200'
                  ) {
                    response.blobBody.then((onres: any) => {
                      downloadedFiles.push([x, onres]);
                      if (files.length == downloadedFiles.length) {
                        downloadedFiles.forEach((item: any) => {
                          zip.file(item[0], item[1]);
                        });
                        zip
                          .generateAsync({ type: 'blob' })
                          .then(function (content) {
                            FileSaver.saveAs(content, fileName);
                          });
                        this.notificationService.showNotification(
                          this.instanceEnum
                            .instance_notify_documentsDownloaded_body,
                          'success',
                          this.instanceEnum
                            .instance_notify_documentsDownloaded_heading
                        );
                      }
                    });
                  } else {
                    this.notificationService.showNotification(
                      this.instanceEnum
                        .instance_notify_documentsNotDownloaded_body,
                      'error',
                      this.instanceEnum
                        .instance_notify_documentsNotDownloaded_heading
                    );
                  }
                }
              );
            }
          });
      });
    } catch (error) {
      console.log(error);
    }
  };

 
  checkDate(instanceDate: any) {
    let instDate: any = this.datePipe.transform(instanceDate, 'MM/dd/yyyy');
    let todaysDate: any = this.datePipe.transform(this.todayDate, 'MM/dd/yyyy');
    let instanceModDate = new Date(instDate);
    let currentModDate = new Date(todaysDate);
    if (instanceModDate.getTime() < currentModDate.getTime()) {
      return true;
    } else {
      return false;
    }
  }

  ngOnDestroy(): void {
    let instanceString = this.isApproveInstance
      ? 'instance-requests'
      : 'instance-requests';
    const url1: string = '/home/instanceoperations/' + instanceString + '/view';
    const url2: string = '/home/instanceoperations/ + instanceString + /edit';
    if (window.location.pathname != url1 && window.location.pathname != url2) {
      localStorage.removeItem('FilterRecordInsta');
    }
  }

  // Download Instance Provisioning Log
  downloadProvisioningLog = async (id: number, type: string) => {
    const datepipe: DatePipe = new DatePipe('en-US');
    try {
      let FileSaver = require('file-saver');
      let dateTime = new Date();
      let fileNameTimeStamp = datepipe.transform(
        dateTime,
        'dd_MMMM_YYYY_HH_mm_ss'
      );
      let fileName =
        'Instance_' +
        type +
        '_' +
        id +
        '_Provisioning_log_' +
        fileNameTimeStamp;
      this.instanceService
        .getBlobDownloadConnectionLog(id)
        .subscribe((resp: any) => {
          let _sasUrl = resp.sasUrl;
          let _container = resp.container;
          let _blob = resp.blob;
          if (resp != null && resp != undefined) {
            this.blobService.downloadImageExt(
              _sasUrl,
              _container,
              _blob,
              (response: any) => {
                if (
                  response._response != undefined &&
                  response._response.status == 200
                ) {
                  response.blobBody.then((onresponse: any) => {
                    FileSaver.saveAs(onresponse, fileName);
                    this.notificationService.showNotification(
                      this.instanceEnum
                        .instance_notify_provisioning_log_success_body,
                      'success',
                      this.instanceEnum
                        .instance_notify_provisioning_log_success_heading
                    );
                  });
                } else {
                  if (response.status == 404) {
                    this.notificationService.showNotification(
                      this.instanceEnum
                        .instance_notify_provisioning_log_failed_404_body,
                      'warning',
                      this.instanceEnum
                        .instance_notify_provisioning_log_failed_heading
                    );
                  } else {
                    this.notificationService.showNotification(
                      this.instanceEnum
                        .instance_notify_provisioning_log_failed_body,
                      'warning',
                      this.instanceEnum
                        .instance_notify_provisioning_log_failed_heading
                    );
                  }
                }
              }
            );
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  closeListDrawer(event:any){
    if(event == false){
      this.listPartner = event
    } else{
      this.listPartner = true;
      this.partnerData = {
        title:"Partner",
        list: event.partners
      }
    }
  }
}
