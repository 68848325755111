<app-pageheader [pageTitle]="'Notifications'" [clearFilter]="false" [auditLogfilter]="true" [globalAuditList]="globalList"  [breadCrumbs]="this.breadCrumbItems"></app-pageheader>

<div class="idn-table-container">
  <div class="idn-filter">

    <form class="idn-grid-filter" [formGroup]="auditFilter">
      <mat-form-field class="idn-grid-filter-search">
        <mat-label>Search Audit Log</mat-label>
        <input (keydown.enter)="doFilter($event,'search')" #search_input matInput placeholder="Search within Log for event, initiator..." #search>
        <span class="idn-custom-search" matSuffix>
          <mat-icon (click)="doFilter($event,'search')">search</mat-icon>
          <button type="button" matSuffix mat-icon-button aria-label="Clear" (click)="ClearInput()">
            <mat-icon>close</mat-icon>
          </button>
        </span>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Choose Date</mat-label>
        <mat-select formControlName="byMonths" (selectionChange)="doFilter($event,'chooseList')" #month>
          <mat-option *ngFor="let option of options" [value]="option.value" (click)="clearInputFields('byMonthsAudit')">
            {{option.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>custom date range</mat-label>
        <mat-date-range-input [rangePicker]="picker" [max]="maxDate">
        <input matStartDate formControlName="start" placeholder="Start date" value="startDate" #start>
        <input matEndDate formControlName="end" (dateChange)="doFilter($event,'customDate')" placeholder="End date" value="endDate" #end>
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker" (click)="clearInputFields('datePickerAudit')"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>

    </form>
    <button mat-stroked-button color="primary" (click)="clearFilter()">
      <mat-icon class="icon-btn-spacer">filter_list_off</mat-icon>
      <span>Clear Filter</span>
    </button>
  </div>
  <div >
    
 <div class="table-scroll">
  <table mat-table [dataSource]="dataSource" matSort class="idn-grid-position" #empTbSort="matSort" (matSortChange)="announceSortChange($event)">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- Position Column -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Event </th>
      <td mat-cell *matCellDef="let element"> {{element.tableName == infraDBScheduleEntry ? "" : element.tableName}} {{element.tableName == "Instance User Provisioned" && element.type == "Updated" ? "" : element.type}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> User Name </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <!-- Weight Column -->
    <!-- <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> User Role </th>
      <td mat-cell *matCellDef="let element"> {{element.roleName}} </td>
    </ng-container> -->

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Email Address </th>
      <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>

    <ng-container matColumnDef="dateTime">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Date & Time </th>
      <td mat-cell *matCellDef="let element"> {{element.dateTime | date:'dd MMM yyyy'}} at {{element.dateTime | date:'HH:mm' }} (UTC) </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef class="action-column"> Details </th>
      <td mat-cell *matCellDef="let element" class="action-column">
        <button mat-icon-button (click)="toggleDetailsDrawer1(element)" aria-label="view Details" aria-label="Open Drawer Button">
          <mat-icon>visibility</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
 </div>
</div>

  <mat-paginator #paginator [pageIndex]="currentPage" [pageSize]="10" [pageSizeOptions]="[5,10, 15, 20, 25, 50]" showFirstLastButtons
  class="mat-paginator-sticky" (page)="pageChanged($event)" [length]="pageLength"
    aria-label="Select page of periodic elements">
  </mat-paginator>
</div>

<mat-drawer-container class="idn-drawer-internal" *ngIf="isDetailsDrawer">
  <mat-drawer #drawerFilter class="idn-drawer">
    <div class="header">
      <div class="heading">
          <mat-icon>web_stories</mat-icon>
          <h2>Audit Log Details</h2>
      </div>
      <button mat-icon-button (click)="toggleDetailsDrawer()" aria-label="Close Button">
          <mat-icon>close</mat-icon>
      </button>
  </div>

  <div class="body" tabindex="0">
    <div class="page-container" >
      <h4 class="tableCaption" *ngIf=" usrCustLabel == ''">{{auditData.tableName == infraDBScheduleEntry ? '' : auditData.tableName }} {{auditData.type}}  - {{auditDetailsUserTitle}}</h4>
      <h4 class="tableCaption" *ngIf=" usrCustLabel != ''">{{usrCustLabel}}  - {{auditDetailsUserTitle}}</h4>
      <div class="auditTable" *ngIf="!isInstanceToolsEvent && !isCloudOpsAuditLog">
        <table>     
         <thead>
          <tr>
            <th class="">Attribute Name</th> 
            <th>Old Values</th>
            <th>New Values</th>
          </tr>
         </thead>
         <tbody>
          <tr *ngFor ="let x of keys">
            <ng-container 
            *ngIf="x != 'ModifiedBy' && x != 'ModifiedAt' && 
            x != 'UniqueIdentifier' && x != 'Timestamp' && 
            x != 'CreatedBy' && x != 'CreatedAt' && x != 'PartnerId' && x !='SolutionPackageId' && x !='SkuId' &&
            x !='DataContainerName' && x !='BackendContainerName' && x !='Title' && x != 'StatusId' && x != 'ApproverUserId' && x != 'FeatureCategoryId'  && x != 'RequesterUserId' && x != 'ToolsFeatureId' && x != 'ToolsFeatureId' ">
            <td class="overflow-data" matTooltip="{{(x.includes('Requestor')? x.replace('Requestor', 'Requester') : x) | camelCase}}" [matTooltipDisabled]="isDisabled(statusValuesToolTip)" matTooltipClass="my-tooltip" matTooltipPosition="below" #statusValuesToolTip>{{(x.includes('Requestor')? x.replace('Requestor', 'Requester') : x) | camelCase}}: </td>
            <td class="overflow-data" *ngIf="auditData.tableName != 'SolutionCertification'" matTooltip="{{auditDataOldValues[x] | dateFilter}}" [matTooltipDisabled]="isDisabled(oldValuesToolTip)" matTooltipClass="my-tooltip" matTooltipPosition="below" #oldValuesToolTip>{{auditDataOldValues[x] == null ? '--' : auditDataOldValues[x] |dateFilter }}</td> 
            <td class="overflow-data" *ngIf="auditData.tableName != 'SolutionCertification'" matTooltip="{{auditDataNewValues[x] | dateFilter}}" [matTooltipDisabled]="isDisabled(newValuesToolTip)" matTooltipClass="my-tooltip" matTooltipPosition="below" #newValuesToolTip>{{auditDataNewValues[x] == null ? '--' : auditDataNewValues[x] |dateFilter }}</td>
            <td *ngIf="auditData.tableName == 'SolutionCertification' && (auditDataOldValues[x] == null || auditDataNewValues[x] == null)"  >--</td>
            <td class="overflow-data" *ngIf="auditData.tableName == 'SolutionCertification' && auditDataOldValues[x] != null"><quill-view-html [content]="auditDataOldValues[x]"  theme="snow"></quill-view-html></td>
            <td class="overflow-data" *ngIf="auditData.tableName == 'SolutionCertification' && auditDataNewValues[x] != null"><quill-view-html [content]="auditDataNewValues[x]"  theme="snow"></quill-view-html></td>
          </ng-container>
          </tr>
        </tbody>
        </table>   
    </div>
    </div>
  </div>
  <div class="pss-drawer-button-wrapper">
    <button mat-flat-button (click)="toggleDetailsDrawer()">Close</button>
  </div>
  </mat-drawer>
</mat-drawer-container>
