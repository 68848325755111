import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AzureBlobStorageService } from 'src/app/core/services/azure-blob-storage/azure-blob-storage.service';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { InstanceService } from 'src/app/core/services/instance-management/instance.service';
import { Deployment } from 'src/app/shared/enum/deployment';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { ExtensionService } from 'src/app/core/services/extension-management/extension.service';
import { DatePipe } from '@angular/common';
import { catchError, map } from 'rxjs';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';

const HELP_ICON = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="49" height="48" viewBox="0 0 49 48" >
<defs>
  <filter id="Ellipse_40" x="0" y="0" width="79" height="78" filterUnits="userSpaceOnUse">
    <feOffset dy="8" input="SourceAlpha"/>
    <feGaussianBlur stdDeviation="5" result="blur"/>
    <feFlood flood-opacity="0"/>
    <feComposite operator="in" in2="blur"/>
    <feComposite in="SourceGraphic"/>
  </filter>
</defs>
<g id="Component_308_1" data-name="Component 308 - 1" transform="translate(0 0)">
  <g transform="matrix(1, 0, 0, 1, -15, -7)" filter="url(#Ellipse_40)">
    <ellipse id="Ellipse_40-2" data-name="Ellipse 40" cx="24.5" cy="24" rx="24.5" ry="24" transform="translate(15 7)" />
  </g>
  <path id="help_FILL0_wght400_GRAD0_opsz48" d="M92.576,195.705a1.169,1.169,0,1,0-.84-.342A1.143,1.143,0,0,0,92.576,195.705Zm-1.09-4.545h1.837a5.1,5.1,0,0,1,.2-1.479,3.665,3.665,0,0,1,1.261-1.541,5.447,5.447,0,0,0,1.37-1.588,3.655,3.655,0,0,0,.4-1.712,3.437,3.437,0,0,0-1.074-2.646,4.022,4.022,0,0,0-2.848-1,4.812,4.812,0,0,0-2.693.763,4.356,4.356,0,0,0-1.7,2.1l1.65.623a2.9,2.9,0,0,1,1.027-1.354,2.738,2.738,0,0,1,1.619-.482,2.5,2.5,0,0,1,1.712.576,1.881,1.881,0,0,1,.654,1.479,2.289,2.289,0,0,1-.4,1.292,6.2,6.2,0,0,1-1.183,1.261,6.331,6.331,0,0,0-1.385,1.6A4.238,4.238,0,0,0,91.487,191.16Zm.965,9.744a12.048,12.048,0,0,1-4.825-.981,12.545,12.545,0,0,1-6.646-6.646,12.438,12.438,0,0,1,0-9.681,12.444,12.444,0,0,1,2.677-3.953,12.764,12.764,0,0,1,3.969-2.662,12.438,12.438,0,0,1,9.681,0,12.432,12.432,0,0,1,6.615,6.615,12.438,12.438,0,0,1,0,9.681,12.764,12.764,0,0,1-2.662,3.969,12.444,12.444,0,0,1-3.953,2.677A12.125,12.125,0,0,1,92.452,200.9Zm0-1.868a10.575,10.575,0,1,0-7.487-3.1A10.185,10.185,0,0,0,92.452,199.036ZM92.452,188.452Z" transform="translate(-66.869 -164.53)" fill="#fff"/>
</g>
</svg>`

const DATE_TIME_FORMAT ='YYYY-MM-ddTHH:mm:ss';
@Component({
  selector: 'app-view-deployment',
  templateUrl: './view-deployment.component.html',
  styleUrls: [
    '../deployment/deployment.component.scss',
    './view-deployment.component.scss',
  ],
})
export class ViewDeploymentComponent implements OnInit {
  isapprovalDrawer: boolean = false;
  isTrackStatusDrawer: boolean = false;
  deploymentId: any;
  ViewData: any = [];
  breadCrumbItems: any;
  currentInstanceStatusID: any;
  StatusClasses: any = [];
  deploymentStatusChanges: any = [];
  deploymentEnum = Deployment;
  isInitiateDeployment: boolean = false;
  isRejectDeployment: boolean = false;
  isApproveDeployment: boolean = false;
  isProdInstance=false;
  displayedColumns: string[] = [
    'DeploymentFor',
    'ExtensionType',
    'ExtensionName',
    'BinaryFile',
  ];
  configDisplayedColumns: string[] = [
    'Name',
    'Value',
    'ValueType',
    'OverrideType',
    'IsEncrypted'
  ];
  dataSource: any;
  configDataSource: any
  minRecords: any =4;
  displayedRows:any = {'deployment': this.minRecords, 'configKey':this.minRecords};
  loggedInUserPartnerAdmin: any;
  userCategory: any;
  userCategoryID: { [key: string]: number } = {
    Icertis: 1,
    Partner: 2,
    Customer: 3
  };
  hasGlobalDataAccess: boolean = false;
 
  constructor(
    private instanceService: InstanceService,
    private extensionservice: ExtensionService,
    private router: Router,
    private permissionService: PermissionsService,
    private notificationService: NotificationService,
    private blobService: AzureBlobStorageService,
    private datePipe: DatePipe,
    private dialogService: DialogService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
  ) { 
    iconRegistry.addSvgIconLiteral('HELP_ICON', sanitizer.bypassSecurityTrustHtml(HELP_ICON));
  }

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: 'Home', path: 'resources' },
      { label: 'Instance Operations', path: 'instanceoperations/deployment' },
      { label: 'Deployment Requests', path: 'instanceoperations/deployment' },
      { label: 'View', path: 'instanceoperations/deployment/view' },
    ];
    this.deploymentId = localStorage.getItem('deploymentId');
    this.isInitiateDeployment = this.permissionService.getPermission('InitiateDeployment');
    this.isApproveDeployment = this.permissionService.getPermission('ApproveDeployment');
    this.isRejectDeployment = this.permissionService.getPermission('RejectDeployment');
    this.loggedInUserPartnerAdmin = this.permissionService.getPartnerDetails().id;
    this.hasGlobalDataAccess = this.permissionService.hasGlobalDataAccess();
    this.userCategory = this.permissionService.getUsercategoryId();
    this.getDeploymentById();
  }
  getDeploymentById() {
    try {
      this.instanceService.getDeploymentByID(this.deploymentId).subscribe(
        (resp: any) => {
          let res = resp.body;
          if (res != undefined && res != null) {
            this.ViewData = res;
            let configHistory= res.deploymentRequestConfigHistoryList?.map((property:any)=>{
              if (property.valueType.includes('System.Boolean')) {
                property.valueType = 'System.Boolean';
              } else if (property.valueType.includes('System.String')) {
                property.valueType = 'System.String';
              } else if (property.valueType.includes('System.Int32')) {
                property.valueType = 'System.Int32';
              } else if (property.valueType.includes('System.Int64')) {
                property.valueType = 'System.Int64';
              } else if (property.valueType.includes('System.Json')) {
                property.valueType = 'System.Json';
              }
              return property;
            })
            let tableData = res.deploymentPackageDetails?.map((data:any)=> {
              let value = (data.extensionPartnerIds?.includes(this.loggedInUserPartnerAdmin) || (this.loggedInUserPartnerAdmin == null && this.hasGlobalDataAccess) || (this.userCategory == this.userCategoryID.Customer))
              return {...data,'hasExtensionAccess': value}
            });
            this.dataSource = new MatTableDataSource(tableData);
            this.configDataSource = new MatTableDataSource(configHistory);
            if(res.instanceType =="Production"){
              this.isProdInstance=true;
            }else{
              this.isProdInstance=false;
            }
          } else {
            this.notificationService.showNotification(
              this.deploymentEnum.deployment_notify_infoNotAvailable_body,
              'warning',
              this.deploymentEnum.deployment_notify_infoNotAvailable_heading
            );
            this.router.navigate(['home/instanceoperations/deployment']);
          }
        },
        (error: HttpErrorResponse) => {
          let message: any = error;
          this.notificationService.showNotification(
            message,
            'warning',
            this.deploymentEnum.deployment_notify_infoNotAvailable_heading
          );
          this.router.navigate(['home/instanceoperations/deployment']);
        }
      );
    } catch (error) {
      console.log(error);
    }
  }

  closeTrackStatus() {
    this.isTrackStatusDrawer = !this.isTrackStatusDrawer;
    this.StatusClasses = [];
  }

  toggleTrackStatus(statusID: any, deploymentStatusChanges: any) {
    this.currentInstanceStatusID = statusID;
    this.deploymentStatusChanges = deploymentStatusChanges.filter(
      (obj: any, pos: any, arr: any) => {
        return (
          arr.map((mapObj: any) => mapObj.statusId).indexOf(obj.statusId) == pos
        );
      }
    );
    this.deploymentStatusChanges = this.deploymentStatusChanges.map(
      (status: any) => {
        if (
          status.statusId == 5 ||
          status.statusId == 3 ||
          status.statusId == 7
        ) {
          return { icon: 'dangerous', ...status };
        } else {
          return { icon: 'check_circle', ...status };
        }
      }
    );
    this.deploymentStatusChanges.forEach((status: any, index: any) => {
      if (this.deploymentStatusChanges.length == index + 1) {
        if (status.statusId == 9) {
          this.StatusClasses.push('completed');
          this.deploymentStatusChanges = [
            ...this.deploymentStatusChanges,
            {
              icon: 'check_circle',
              statusDisplayName:
                this.deploymentEnum.deployment_status_deployment_requested
            },
            {
              icon: 'check_circle',
              statusDisplayName:
                this.deploymentEnum.deployment_status_package_bundling_started,
            },
            {
              icon: 'check_circle',
              statusDisplayName:
                this.deploymentEnum
                  .deployment_status_package_bundling_completed,
            },
            {
              icon: 'check_circle',
              statusDisplayName:
                this.deploymentEnum.deployment_status_deployment_in_progress,
            },
            {
              icon: 'check_circle',
              statusDisplayName:
                this.deploymentEnum.deployment_status_deployment_completed,
            },
          ];
        } else if (status.statusId == 1) {
          this.StatusClasses.push('completed');
          this.deploymentStatusChanges = [
            ...this.deploymentStatusChanges,
            {
              icon: 'check_circle',
              statusDisplayName: this.deploymentEnum.deployment_status_package_bundling_started,
            },
            {
              icon: 'check_circle',
              statusDisplayName: this.deploymentEnum.deployment_status_package_bundling_completed,
            },
            {
              icon: 'check_circle',
              statusDisplayName: this.deploymentEnum.deployment_status_deployment_in_progress,
            },
            { icon: 'check_circle', statusDisplayName: this.deploymentEnum.deployment_status_deployment_completed },
          ];
        } else if (status.statusId == 6) {
          this.StatusClasses.push('completed');
          this.deploymentStatusChanges = [
            ...this.deploymentStatusChanges,
            {
              icon: 'check_circle',
              statusDisplayName: this.deploymentEnum.deployment_status_package_bundling_completed,
            },
            {
              icon: 'check_circle',
              statusDisplayName: this.deploymentEnum.deployment_status_deployment_in_progress,
            },
            { icon: 'check_circle', statusDisplayName: this.deploymentEnum.deployment_status_deployment_completed },
          ];
        } else if (status.statusId == 8) {
          this.StatusClasses.push('completed');
          this.deploymentStatusChanges = [
            ...this.deploymentStatusChanges,
            {
              icon: 'check_circle',
              statusDisplayName: this.deploymentEnum.deployment_status_deployment_in_progress,
            },
            { icon: 'check_circle', statusDisplayName: this.deploymentEnum.deployment_status_deployment_completed },
          ];
        } else if (status.statusId == 2) {
          this.StatusClasses.push('completed');
          this.deploymentStatusChanges = [
            ...this.deploymentStatusChanges,
            { icon: 'check_circle', statusDisplayName: this.deploymentEnum.deployment_status_deployment_completed },
          ];
        }
        if (
          status.statusId == 5 ||
          status.statusId == 3 ||
          status.statusId == 7
        ) {
          this.StatusClasses.push('rejected');
        } else if (status.statusId == 4) {
          this.StatusClasses.push('completed');
        } else {
          this.StatusClasses.push('progress');
        }
      } else if (
        status.statusId == 1 ||
        status.statusId == 4 ||
        status.statusId == 2 ||
        status.statusId == 6 ||
        status.statusId == 8 ||
        status.statusId == 9
      ) {
        this.StatusClasses.push('completed');
      } else if (
        status.statusId == 3 ||
        status.statusId == 5 ||
        status.statusId == 7
      ) {
        this.StatusClasses.push('rejected');
      } else {
        this.StatusClasses.push('noClass');
      }
    });
    this.isTrackStatusDrawer = !this.isTrackStatusDrawer;
  }

  toggleTrackStatusDrawer() {
    this.isTrackStatusDrawer = !this.isTrackStatusDrawer;
  }

  cancel() {
    this.router.navigate(['home/instanceoperations/deployment']);
  }
  // Download Instance Provisioning Log
  downloadDeploymentLog = async ( id: number, deployment: string ) => {
    const datepipe: DatePipe = new DatePipe('en-US');
    try {
      let FileSaver = require('file-saver');
      let dateTime = new Date ();
      let fileNameTimeStamp = datepipe.transform(dateTime, 'dd_MMMM_YYYY_HH_mm_ss');
      let fileName = 'Deployment_' + deployment + '_' + id + '_log_' + fileNameTimeStamp;
      this.instanceService.GetDeploymentLogDownloadConnection(id).subscribe((resp: any) => {
        if (resp != null && resp != undefined) {
          this.blobService.downloadImageExt(resp.sasUrl, resp.container, resp.blob, (response: any) => {
            if (response._response != undefined && response._response.status == 200) {
              response.blobBody.then(
                (onresponse: any) => {
                  FileSaver.saveAs(onresponse, fileName);
                  this.notificationService.showNotification(this.deploymentEnum.deployment_notify_deployment_log_success_body, 'success', this.deploymentEnum.deployment_notify_deployment_log_success_heading);
                }
              )
            } else {
              if (response.status == 404){
                this.notificationService.showNotification(this.deploymentEnum.deployment_notify_deployment_log_failed_404_body, 'warning', this.deploymentEnum.deployment_notify_deployment_log_failed_404_heading);
              } else {
                this.notificationService.showNotification(this.deploymentEnum.deployment_notify_deployment_log_failed_body, 'warning', this.deploymentEnum.deployment_notify_deployment_log_failed_heading);
              }

            }
          })
        }
      })
    } catch ( error ) {
      console.log( error );
    }
  }

    // To Download Solution package FIle
    downloadSingleFile(id: any, fileNames: any, fileHeading: any) {
      for (let [index, fileName] of fileNames.entries()) {
        if (fileName) {
          try {
            var FileSaver = require('file-saver');
            this.extensionservice.getBlobDownloadConnectionForSolution(id, fileName)
              .pipe(
                map((data: any) => {
                  this.blobService.downloadImageExt(data.sasUrl, data.container, data.blob, (response: any) => {
                    if (response._response != undefined && response._response.status == "200") {
                      response.blobBody.then(
                        (onres: any) => {
                          FileSaver.saveAs(onres, fileName);
                          this.notificationService.showNotification(fileHeading[index].toString()+' '+this.deploymentEnum.deployment_view_downloadfile_body_p1, 'success', fileHeading[index].toString()+' '+this.deploymentEnum.deployment_view_downloadfile_heading);
                        })
                    } else {
                      this.notificationService.showNotification(this.deploymentEnum.deployment_view_failedDownloadfile_body_p1+' '+fileHeading[index].toString(), 'error', fileHeading[index].toString()+' '+this.deploymentEnum.deployment_view_faileddownloadfile_heading);
                    }
                  });
                }), catchError((err) => {
                  console.log(err);
                  return err;
                })
              ).subscribe();
          } catch (err) {
            console.log(err);
          }
        }
      }
    }

    downloadOrchestratedPackage(id: any) {
      const datepipe: DatePipe = new DatePipe('en-US');
    try {      
      let FileSaver = require('file-saver');
      let dateTime = new Date ();  
      let fileNameTimeStamp = datepipe.transform(dateTime, 'dd_MMMM_YYYY_HH_mm_ss');
      let fileName = 'Orchestrated_package_'+fileNameTimeStamp+".zip";
      this.instanceService.GetOrchestratedPackageLogDownloadConnection(id).subscribe((resp: any) => {      
        if (resp != null && resp != undefined) {
         this.blobService.downloadImageExt(resp.sasUrl, resp.container, resp.blob, (response: any) => {
          if (response._response != undefined && response._response.status == 200) {
              response.blobBody.then(
                (onresponse: any) => {
                  FileSaver.saveAs(onresponse, fileName);
                  this.notificationService.showNotification(this.deploymentEnum.deployment_notify_orchestration_package_success_body, 'success', this.deploymentEnum.deployment_notify_orchestration_package_success_heading);
                }
              )
            } else {
              if (response.status == 404){
                this.notificationService.showNotification(this.deploymentEnum.deployment_notify_orchestration_package_failed_404_body, 'warning', this.deploymentEnum.deployment_notify_orchestration_package_failed_heading);
              } else {
                this.notificationService.showNotification(this.deploymentEnum.deployment_notify_orchestration_package_failed_body, 'warning', this.deploymentEnum.deployment_notify_orchestration_package_failed_heading);
              }

            }
          })
        }
      })
    } catch ( error ) {
      console.log( error );
    }
    }
    
    redirectToHelp() {
      this.dialogService.confirmDialog({
        title: this.deploymentEnum.redirect_dialog_heading,
        module: 'resources',
        message: this.deploymentEnum.redirect_dialog_body,
        confirmText: this.deploymentEnum.redirect_dialog_primary_btn,
        cancelText: this.deploymentEnum.redirect_dialog_secondary_btn
      }).subscribe((res: any) => {
        if(res){
          window.open('https://developer-help.icertis.com/docs/managing-extensions-and-deploying-packages/#deploy-package', '_blank');
        }
      });
   }

   approveReject(deploymentId: any, deploymentDate: any) {
    this.dialogService
      .confirmDialogComments({
        title: 'Confirm',
        module: 'approve-deployment',
        message: deploymentDate,
        confirmText: 'Confirm',
        cancelText: 'Cancel',
      })
      .subscribe((res: any) => {
        if (res) {
          let data = {
            id:deploymentId,
            requestedDate: this.transformDate(res.requiredBy),
            comment: res.comments,
            reviewerId: this.permissionService.getUniqueUserid()
          }
          if(res.event == this.deploymentEnum.deployment_constant_approve){
            this.instanceService.approveDeploymentRequest(data).subscribe((res)=>{
              if (res.status == 200) {
                this.getDeploymentById();
                if(this.isApproveDeployment || this.isRejectDeployment){
                  this.instanceService.getUpdatedCount(true);
                }
                this.notificationService.showNotification(
                  this.deploymentEnum.deployment_notify_deployment_request_approved_body,
                  'success',
                  this.deploymentEnum.deployment_notify_deployment_request_approved_heading
                );                
              } else {
                this.notificationService.showNotification(
                  this.deploymentEnum.deployment_notify_deployment_request_approval_failed_body,
                  'error',
                  this.deploymentEnum.deployment_notify_deployment_request_approval_failed_heading,
                );
              }
            })
          } else if(res.event == this.deploymentEnum.deployment_constant_reject){
            this.instanceService.rejectDeploymentRequest(data).subscribe((res)=>{
              if (res.status == 200) {
                this.getDeploymentById();
                this.notificationService.showNotification(
                  this.deploymentEnum.deployment_notify_deployment_request_rejected_body,
                  'success',
                  this.deploymentEnum.deployment_notify_deployment_request_rejected_heading
                );
              } else {
                this.notificationService.showNotification(
                  this.deploymentEnum.deployment_notify_deployment_request_rejection_failed_body,
                  'error',
                  this.deploymentEnum.deployment_notify_deployment_request_rejection_failed_heading
                );
              }
            })
          }
          
        }
      });
  }

   //datetime Validation
   transformDate(selectedDate: any) {
    return this.datePipe.transform((selectedDate.toISOString()), DATE_TIME_FORMAT);
  }

  showMore(name:any) {
    if(name ==  'deployment'){
      this.displayedRows[name] += this.dataSource?.data.length; // Increase the number of displayed rows
    } else {
      this.displayedRows[name] += this.configDataSource?.data.length;
    }
  }

  showLess(name:any) {
    if (this.displayedRows[name] > this.minRecords) {
      this.displayedRows[name] = this.minRecords;
    }
  }

  isDisabled(e:any) {
    return e.scrollWidth <= e.clientWidth;
  }
  
  viewMoreData(element:any){
    this.dialogService.confirmDialog({
      title: "Config Key Details",
      module: 'config-details',
      message: JSON.stringify(element),
      confirmText: '',
      cancelText: 'Close'
    }).subscribe((res: any) => {
    });
  }
}
