<mat-form-field class="idn-grid-filter-search">
    <mat-label>Search</mat-label>
    <input [(ngModel)]="searchInput" placeholder="{{reference == 'approvalList'? 'Search by partner, customer, request for, and requested by...': ''}}" type="text" #search_input matInput (keyup.enter)="onSearch()">
    <span class="idn-custom-search" matSuffix>
      <mat-icon (click)="onSearch()" >search</mat-icon>
      <button type="button" matSuffix mat-icon-button aria-label="Clear" (click)="onClear()">
        <mat-icon>close</mat-icon>
      </button>
    </span>
</mat-form-field>
