import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { InstanceService } from 'src/app/core/services/instance-management/instance.service';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';
import { InstanceManagement } from 'src/app/shared/enum/instance-management';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { DatePipe, Location } from '@angular/common';

@Component({
  selector: 'app-instance-approval',
  templateUrl: './instance-approval.component.html',
  styleUrls: ['./instance-approval.component.scss'],
})
export class InstanceApprovalComponent implements OnInit {
  @ViewChild('stepper', { static: false }) mystepper!: MatStepper;
  breadCrumbItems: any;
  public instanceEnum = InstanceManagement;
  instanceId: any;
  ViewData: any = [];
  @ViewChild('comments') comments: any;
  inputCnamewrkvalue = 'imt';
  inputiciVersionvalue='';
  isViewInstance: boolean = false;
  isApproveInstance: boolean = false;
  isRejectInstance: boolean = false;
  multiTenantPods: any = [];
  deploymentRegions: any = [];
  subscriptionsList: any;
  environmentList:any = [];
  subscriptionId: any;
  deploymentRegionId: any;
  multiTenantPODsFromReferenceData:any=[];
  instanceType:any = [];
  billingTypes: any =[];
  today: any = new Date();
  minDate: any = new Date();
  cnameError: any = false;
  checkList:any = [ 
    {data:this.instanceEnum.instance_approval_checklist_question2, isSelected:false}, 
    {data:this.instanceEnum.instance_approval_checklist_question3, isSelected:false},
    {data:this.instanceEnum.instance_approval_checklist_question4, isSelected:false}, 
    {data:this.instanceEnum.instance_approval_checklist_question5, isSelected:false}, 
    {data:this.instanceEnum.instance_approval_checklist_question6, isSelected:false},
    {data:this.instanceEnum.instance_approval_checklist_question7, isSelected:false}]
  

  frmApproveInstance: FormGroup = new FormGroup({
    id: new FormControl(0),
    cnamewrk: new FormControl(null, [
      Validators.required,
      Validators.pattern(/^(imt)[a-z0-9][a-z0-9\s]*$/),
    ]),
    multiTenantPODId: new FormControl(null, Validators.required),
    regionId: new FormControl([], Validators.required),
    subscriptionId: new FormControl(null, Validators.required),
    InstanceVersion: new FormControl('',Validators.required),
    iciVersion:new FormControl('', [Validators.required,Validators.pattern(/^[1-9]{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(?:)?$/)]),
    InstanceTagId:new FormControl(null, Validators.required),
    podCode: new FormControl('',Validators.required),
    instanceTypeId: new FormControl(null,Validators.required),
    billingTypeId: new FormControl(null,Validators.required),
    expirationDate: new FormControl(null),
  });

  firstStepControl = new FormControl('');
  secondStepControl = new FormControl('');
  thirdStepControl = new FormControl('');

  constructor(
    private instanceService: InstanceService,
    private notificationService: NotificationService,
    private permissionService: PermissionsService,
    private router: Router,
    private dialogService: DialogService,
    private location: Location,
    private datePipe: DatePipe,
    private changeDetector: ChangeDetectorRef
    ) {}

  ngOnInit(): void {
    this.minDate.setDate(this.today.getDate()+1);
    this.breadCrumbItems = [
      { label: 'Home', path: 'resources' },
      { label: 'Instance Operations', path: 'instanceoperations/instance-requests' },
      { label: 'Instance Requests', path: 'instanceoperations/instance-requests' },
      { label: 'Instance Approval', path: 'instance-approval' },
    ];
    this.instanceId = localStorage.getItem('instanceID');
    this.getInstanceByID();
    this.isApproveInstance =
      this.permissionService.getPermission('ApproveInstance');
    this.isRejectInstance =
      this.permissionService.getPermission('RejectInstance');
  }

  ngAfterViewInit(): void {
    this.changeDetector.detectChanges();
  }
  //Get all the instances
  getInstanceByID() {
    try {
      this.instanceService
        .getInstanceByID(this.instanceId)
        .subscribe((resp: any) => {
          let result = resp.body;
          if (result != undefined && result != null) {
            this.ViewData = result;
            this.frmApproveInstance.controls['InstanceVersion'].disable();
            this.frmApproveInstance.controls.podCode.disable();
            this.getReferenceData();
            this.getInstanceTag();
              this.frmApproveInstance.patchValue({
                cnamewrk: this.ViewData.cnamewrk? this.ViewData.cnamewrk : this.inputCnamewrkvalue,
                comment: this.ViewData.cloudOpsApprovalComment,
                multiTenantPODId: this.ViewData.multiTenantPodid,
                regionId: this.ViewData.deploymentRegionId,
                subscriptionId:this.ViewData.subscriptionId,
                iciVersion:this.ViewData.iciVersion,
                InstanceTagId:this.ViewData.instanceTagId,
                instanceTypeId: this.ViewData.infraDBInstanceTypeId,
                podCode:this.ViewData.podCode,
                billingTypeId: this.ViewData.billingTypeId,
                expirationDate: this.ViewData.expirationDate
              })
            if (this.ViewData.instanceVersion == this.instanceEnum.instance_approval_current_podVersion) {
              this.frmApproveInstance
                .get('InstanceVersion')
                ?.setValue(this.instanceEnum.instance_view_approval_current_podVersion);
            } else if (this.ViewData.instanceVersion == this.instanceEnum.instance_approval_previous_podVersion) {
              this.frmApproveInstance
                .get('InstanceVersion')
                ?.setValue(this.instanceEnum.instance_view_approval_previous_podVersion);
              }

              if(new Date(this.ViewData.requiredBy) <= this.today){
                this.minDate.setDate((new Date()).getDate()+1)
              } else{
                this.minDate = new Date(this.ViewData.requiredBy);
                this.minDate.setDate(this.minDate.getDate()+1)
              }
              
            if(this.ViewData.expirationDate){
              this.frmApproveInstance.controls.expirationDate.markAllAsTouched()
            }
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  //get instance tag reference data.
  getInstanceTag() {
    try {
      this.instanceService.GetInstanceTags().subscribe((resp: any) => {
        if (resp != null) {
          this.environmentList = resp;
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  //function to approve instance
  approveInstance(instanceID: any) {
    if (!this.frmApproveInstance.valid) {
      this.frmApproveInstance.markAllAsTouched();
      this.notificationService.showNotification(
        'Please enter required fields',
        'warning',
        'Not valid'
      );
      return;
    }
    let podICIVersion = this.multiTenantPODsFromReferenceData
      .filter(
        (a: any) =>
          a.id == this.frmApproveInstance.controls['multiTenantPODId'].value
      )
      .map(function (pod: any) {
        return pod.iciVersion;
      });

    if (this.inputiciVersionvalue != podICIVersion) {
      this.dialogService
        .confirmDialog({
          title: this.instanceEnum.instance_approve_confirmation,
          module: 'Approve Instance',
          message: this.instanceEnum.instance_approve_popuptext
            .toString()
            .split('{inputiciversion}')
            .join(this.inputiciVersionvalue)
            .split('{podiciversion}')
            .join(podICIVersion),
          confirmText: 'Confirm',
          cancelText: 'Cancel',
        })
        .subscribe((res: any) => {
          if (res == false) return;
          else {
            this.approveInstanceFromService(instanceID);
          }
        });
    } else {
      this.approveInstanceFromService(instanceID);
    }
  }

  //function for calling api to approve instance
  approveInstanceFromService(instanceID: any) {
    this.dialogService
        .confirmDialogComments({
          title: "Confirm Approval",
          module: '',
          message: '',
          confirmText: 'Confirm',
          cancelText: 'Cancel',
        })
        .subscribe((res: any) => {
          if(res){
            this.frmApproveInstance.controls['id'].setValue(instanceID);
            let JSON = this.frmApproveInstance.getRawValue();
            JSON.expirationDate = this.datePipe.transform(
              JSON.expirationDate,
              'yyyy-MM-ddTHH:mm:ss'
            );
            JSON.instanceICIVersion=this.inputiciVersionvalue;
            JSON.comment = res;
            JSON.infraDBInstanceTypeId = this.frmApproveInstance.controls.instanceTypeId.value;
            this.instanceService.approveInstance(JSON).subscribe((resp: any) => {
              if (resp.status == 200) {
                if(this.isApproveInstance || this.isRejectInstance){
                  this.instanceService.getUpdatedCount(true);
                }
                let instance: any = this.isApproveInstance
                  ? 'instance-requests'
                  : 'instance-requests';
                this.router.navigate(['home/instanceoperations/' + instance]);
                this.notificationService.showNotification(
                  'Instance request is approved and forwarded for creation process',
                  'success',
                  'Instance request approved'
                );
              }
            });
          }
        });
  }

  //function to reject instance creation
  rejectInstance(instanceID: any) {  
    this.dialogService
        .confirmDialogComments({
          title: "Confirm Rejection",
          module: '',
          message: '',
          confirmText: 'Confirm',
          cancelText: 'Cancel',
        })
        .subscribe((res: any) => {
            if(res){
              this.frmApproveInstance.controls['id'].setValue(instanceID);
              let JSON = {
                id: this.frmApproveInstance.controls['id'].value,
                comment: res,
              };
              this.instanceService.rejectInstance(JSON).subscribe((resp: any) => {
                if (resp.status == 200) {
                  if(this.isApproveInstance || this.isRejectInstance){
                    this.instanceService.getUpdatedCount(true);
                  }
                  let instance: any = this.isApproveInstance
                    ? 'instance-requests'
                    : 'instance-requests';
                  this.router.navigate(['home/instanceoperations/' + instance]);
                  this.notificationService.showNotification(
                    'Instance request is rejected',
                    'error',
                    'Instance request rejected'
                  );
                }
              });
            }
          });
  }

  //get region and subscription list
  getReferenceData() {
    try {
      let inputParam='Approval';
      this.instanceService.getReferenceData(inputParam).subscribe((resp: any) => {
        if (resp != null) {
          this.deploymentRegions = resp.deploymentRegions;
          this.subscriptionsList = resp.subscriptions;
          this.multiTenantPODsFromReferenceData = resp.multiTenantPODs;
          this.instanceType = resp.infraDBInstanceTypes.filter((types:any)=>{
            return types.typeOfInstanceId == this.ViewData.typeOfInstanceId
          })
          this.billingTypes = resp.billingTypes;
          this.getMultiTenantPod();
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  //function to get multi tenant pod reference data
  getMultiTenantPod() {
    let regionId = this.frmApproveInstance.get('regionId')?.value;
    let subscription = this.frmApproveInstance.get('subscriptionId')?.value;
    this.deploymentRegionId = regionId != null?regionId:this.ViewData.deploymentRegionId;
    this.subscriptionId = subscription!=null?subscription:this.ViewData.subscriptionId
    if (this.subscriptionId && this.subscriptionId != 0 &&  this.deploymentRegionId && this.deploymentRegionId != 0) {
      try {
        this.instanceService
          .getMultiTenantPod(this.deploymentRegionId, this.subscriptionId)
          .subscribe((resp: any) => {
            if (resp != null && resp != undefined) {              
              this.multiTenantPods = resp.body;
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  }

  //function calling on subscription change.
  onSubscriptionChange(){
    this.frmApproveInstance.controls.podCode.reset();
    this.frmApproveInstance.controls.multiTenantPODId.reset();
    this.getMultiTenantPod();
  }

  //function calling on multi tenant pod change to update the podcode.
  updatePodCode(event:any){
    try{
      this.frmApproveInstance.controls.podCode.reset();
      if(this.multiTenantPods){
        let mtPod =this.multiTenantPods?.filter((pod:any)=>{
          return pod.id == event.value;
        });
        this.frmApproveInstance.controls.podCode.setValue(mtPod[0].podCode);
      }      
    } catch(error){
      console.log(error);

    }
  }

  //function to show the ici version based on instance version (i.e, n, n-1).
  updateIciVersion(){
    let podICIVersion:any=this.multiTenantPods.filter(
      (a:any)=>a.id==this.frmApproveInstance.controls['multiTenantPODId'].value)
      .map(function(pod:any){return pod.iciVersion});
    let compareVersion: any = '8.4.0.0';
    let selectedPodIciVersion = podICIVersion[0];
    let [major, minor] = selectedPodIciVersion.split('.');
    let currentPodIciVersion = selectedPodIciVersion.localeCompare(compareVersion, undefined, { numeric: true, sensitivity: 'base' });
    if(this.ViewData.instanceVersion == this.instanceEnum.instance_approval_previous_podVersion && currentPodIciVersion >= 0){
      let minorNumber = +minor;
      let majorNumber = +major;
      if(minorNumber>0){
        minor = (minorNumber -1).toString();
      } else{
        major = (majorNumber -1).toString();
        minor = '9';
      }
      this.frmApproveInstance.get('iciVersion')?.setValue(major+'.'+minor);
      this.inputiciVersionvalue = major+'.'+minor;
    } else if(this.ViewData.instanceVersion == this.instanceEnum.instance_approval_current_podVersion && currentPodIciVersion >= 0) {
      this.inputiciVersionvalue = major+'.'+minor;
    }
  }

  //function called on click of previous button.
  onPrevious(instanceId:any){
    this.saveAsDraft(instanceId, false);
    this.mystepper.previous();
  }

  //function called on click of next button.
  onNext(instanceId:any) {
    this.saveAsDraft(instanceId, false);
    this.mystepper.next();
  }

  validateDraft() {
      let infraForm = (this.frmApproveInstance.controls.cnamewrk.valid || this.frmApproveInstance.controls.cnamewrk.value == "" || this.frmApproveInstance.controls.cnamewrk.value == 'imt');
      let propertiesForm =(this.frmApproveInstance.controls.iciVersion.valid || this.frmApproveInstance.controls.iciVersion.value == "" || this.frmApproveInstance.controls.iciVersion.value == null);
      if ((this.mystepper?.selectedIndex == 0  && infraForm) || (this.mystepper?.selectedIndex == 1  && propertiesForm) || this.mystepper?.selectedIndex == 2) {
        return false
      } else {
        return true
      }
  }

  //function called on click of cancel button.
  onCancel(){
    this.location.back();
  }

  //function called on click of save as draft button.
  saveAsDraft(instanceID:any,navigate?:any){
    try{
      this.frmApproveInstance.controls['id'].setValue(instanceID);
      let JSON = this.frmApproveInstance.getRawValue();
      JSON.instanceICIVersion=this.inputiciVersionvalue;
      JSON.cnamewrk = JSON.cnamewrk == 'imt'? "": JSON.cnamewrk;
      JSON.expirationDate = this.datePipe.transform(
        JSON.expirationDate,
        'yyyy-MM-ddTHH:mm:ss'
      );
      JSON.comment = '';
      JSON.infraDBInstanceTypeId = this.frmApproveInstance.controls.instanceTypeId.value;
      this.instanceService.saveInstanceApprovalDetailsAsDraft(JSON).subscribe((resp: any) => {
        this.cnameError = false;
        if (resp.status == 200 && navigate) {
          let instance: any = this.isApproveInstance
            ? 'instance-requests'
            : 'instance-requests';
          this.router.navigate(['home/instanceoperations/' + instance]);
          this.notificationService.showNotification(
            'Instance request approval data saved as draft',
            'success',
            'Instance request draft saved'
          );
        }
      }, (error:any)=>{
        if(error){
          this.cnameError = true;
        }
      });
    }catch(error){
      console.log(error)
    }
    
  }

  //function called to validate the form values and if all checklist selected and enables the approve button.
  validateApprove(){
    let checklist =this.checkList.every((data:any)=>{
      return data.isSelected == true
    })
    if(checklist && this.frmApproveInstance.valid && this.frmApproveInstance.controls.podCode.value != '' && this.frmApproveInstance.controls.podCode.value != null && !this.cnameError){
      return false;
    } else {
      return true
    }
  }

}
