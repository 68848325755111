<mat-drawer-container class="idn-drawer-internal">
    <mat-drawer #drawerInfoView class="idn-drawer">
      <div class="header">
        <div class="heading">
          <mat-icon>groups</mat-icon>
          <h2>{{data.title}}</h2>
        </div>
        <button mat-icon-button (click)="toggledrawer(false)">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="body pt-1">
        <div class="idn-list-customer" *ngFor="let value of data.list">
          <div class="logo">
            <mat-icon>person_outline</mat-icon>
          </div>
          <div class="info" *ngIf="data.title != 'Email Recipient'">
            <h4>{{value.partnerName}}</h4>
            <p>{{value.emailId}}</p>
          </div>
          <div class="info" *ngIf="data.title == 'Email Recipient'">
            <h4>{{value.emailRecipient}}</h4>
            <p>on {{value.emailSentDateTime | date: dateFormatString}}</p>
          </div>
        </div>
      </div>
      <div class="pss-drawer-button-wrapper">
        <button mat-flat-button (click)="toggledrawer(false)">Close</button>
      </div>
    </mat-drawer>
  </mat-drawer-container>
