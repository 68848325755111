import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class CacheService {

  private cache = new Map<string, { data: any, timestamp: number }>();

  constructor() {}

  // Get cached data by the URL key
  get(url: string): any {
    const cachedData = this.cache.get(url);
    let cacheExpiry =  Number(localStorage.getItem('httpCacheExpiryTime')) * 60 * 1000; // Cache expiry time in milliseconds (e.g., 5 minutes)
    if (cachedData) {
      const currentTime = new Date().getTime();
      if (currentTime - cachedData.timestamp < cacheExpiry) {
        return cachedData.data; // Return cached data if not expired
      }
      this.cache.delete(url); // Delete expired cache
    }
    return null;
  }

  // Set cache data for the specific URL
  set(url: string, data: any): void {
    this.cache.set(url, { data, timestamp: new Date().getTime() });
  }

  // Clear cache (optional)
  clear(): void {
    this.cache.clear();
  }
}