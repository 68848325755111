export enum InstanceManagement {

    /* HEADERS */
    instance_header_create = "Extension Details",
    instance_header_view = "Extension Details",
    instance_header_edit = "Edit Role",
    instance_Filter_Search_label_searchInstance = "Search Instance",
    instance_Filter_label_status = "Status",
    instance_Filter_label_user_category = "User Category",
    instance_Filter_label_user_type = "User Type",
    instance_header_editInstance = "Edit Instance",
    instance_header_viewInstance = "Instance for :",
    provision_user_Filter_Search_label_searchInstance = "Search Instance User",

    /*EXTENSION LIST COLUMN  HEADER */
    instance_List_Header_type = "Type",
    instance_List_Header_partnerName = "Partner name",
    instance_List_Header_customerName = "Customer Name",
    instance_List_Header_cName = "cName",
    instance_List_Header_requiredByDate = "Required by Date",
    instance_List_Header_requestorDetails = "Requester details",
    instance_List_Header_status = "Status",
    instance_List_Header_actions = "Actions",
    instance_List_Header_requestInstance_btn = "Request Instance",
    instance_List_Header_pacakgeDeployment_btn = "Package Deployment",


    instance_dialog_confirm_approve_request_title = "Submit for Approval",
    instance_dialog_confirm_approve_request_body = "Are you sure you want to Request for instance approval?",
    instance_dialog_confirm_approve_request_confirm_btn = "Submit",
    instance_dialog_confirm_approve_request_cancel_btn = "Cancel",

    instance_error_required_date = "Please select present or future date.",
    instance_error_valid_date = "Please select present or future date.",

    instance_List_initiateDeployment_customDrawerp1_heading = "DEPLOYMENT FOR",
    instance_List_initiateDeployment_customDrawerp1_field_sku = "SKU",
    instance_List_initiateDeployment_customDrawerp1_field_extensionType = "Extension Type",
    instance_List_initiateDeployment_customDrawerp1_field_extensionName = "Extension Name",
    instance_List_initiateDeployment_customDrawerp1_field_solutionPackage = "Customer Extension Package",
    instance_List_initiateDeployment_customDrawerp1_field_submitForDeployment_btn = "Initiate Deployment",
    instance_List_initiateDeployment_customDrawerp1_field_cancel_btn = "Cancel",
    instance_List_initiateDeployment_header = "Deployment Initiation",
    instance_List_user_provision ="User Provision",

    instance_List_initiateDeployment_customDrawerTrackStatus_heading = "Track Instance Status",
    instance_List_initiateDeployment_customDrawerTrackStatus_instanceRqstCreated = "Instance Request Created",
    instance_List_initiateDeployment_customDrawerTrackStatus_instanceRequested = "Instance Requested",
    instance_List_initiateDeployment_customDrawerTrackStatus_instanceCreationScheduled = "Instance Creation Scheduled",
    instance_List_initiateDeployment_customDrawerTrackStatus_instanceCreationInProgress = "Instance Creation in-Progress",
    instance_List_initiateDeployment_customDrawerTrackStatus_instanceCreationFailed = "Instance Creation Failed",
    instance_List_initiateDeployment_customDrawerTrackStatus_instanceCreationRejected = "Instance Creation Rejected",
    instance_List_initiateDeployment_customDrawerTrackStatus_instanceCreated = "Instance Created",
    instance_List_initiateDeployment_customDrawerTrackStatus_field_Close_btn = "Close",
    instance_List_initiateDeployment_customDrawerTrackStatus_screenMessage = "Track the progress of your instance Request during the approval process.",

    instance_List_initiateDeployment_customDrawer_Header = "Initiate Deployment",
    instance_List_initiateDeployment_customDrawer_screenMessage = "Please verify the instance details and enter the required details to initiate the package deployment.",
    instance_List_initiateDeployment_customDrawer_Field_type = "Type",
    instance_List_initiateDeployment_customDrawer_Field_cName = "cName",
    instance_List_initiateDeployment_customDrawer_Field_customerName = "Customer Name",
    instance_List_initiateDeployment_customDrawer_Field_requiredByDate = "Required by Date",

    instance_List_initiateDeployment_customDrawer_Field_region = "Region",
    instance_List_initiateDeployment_customDrawer_Field_requestorDetails = "Requester Details",
    instance_List_initiateDeployment_customDrawer_Field_extensionType = "Extension Type",
    instance_List_initiateDeployment_customDrawer_Field_extensionName = "Extension Name",
    instance_List_initiateDeployment_customDrawer_Field_soultionPackage = "Customer Extension Package",
    instance_List_initiateDeployment_customDrawer_submitForDeployment_btn = "Submit for Deployment",

    /*EXTENSION LIST ACTION BUTTON LABEL*/
    instance_List_Action_Label_downloadDocuments = "Download Documents",
    instance_List_Action_Label_downloadLogs = "Download Instance Provisioning Log",
    instance_List_Action_Label_trackStatus_btn = "Track Status",
    instance_List_Action_Label_Edit_btn = "Edit",
    instance_List_Action_Label_ApproveReject_btn = "Approve/Reject",
    instance_List_Action_Label_submitForApproval_btn = "Submit for Approval",
    instance_List_Action_Label_initiatePackageDeployment_btn = "Initiate Deployment",
    instance_List_Action_Label_iciTools_btn = "Instance Tools",

    /*EXTENSION VIEW FIELD LABEL */
    instance_View_Header_title = "Instance for:",
    instance_View_Field_customerName = "Customer Name",
    instance_View_Field_partnerName="Partner Name",
    instance_View_Field_type = "Instance Type",
    instance_View_Field_requiredByDate = "Required by Date",
    instance_View_Field_cName = "CName",
    instance_approval_details = "Instance Approval Details",
    instance_View_Field_region = "Region",
    instance_View_Field_user = "CLM Admin",
    instance_View_Field_workerip = "Worker Network Interface IP",
    instance_View_Field_instancetag = "Environment Tag",
    instance_View_Field_extensionDescription = "Multi-Tenant Pod",
    instance_View_Field_referenceDocument = "Instance Documents",
    instance_View_Field_approvedBy = "Approved By",
    instance_View_Field_instanceVersion ='Instance Version',
    instance_View_Field_actions = "ICI Version",
    instance_View_Field_status = "Status",
    instance_View_Field_pod = "Multi Tenant Pod",
    instance_View_Field_abbr = "Cname Abbreviation",
    instance_View_Field_approverNote = "Approver Note/ Comments",
    instance_View_trackStatus_primary_btn = "Track Status",
    instance_View_requestForApproval_btn = "Request for approval",
    instance_View_cancel_btn = "Cancel",
    instance_View_edit_btn = "Edit",
    instance_View_requestDetails = "Requester details",
    instance_View_instanceDocuments = "Instance Documents",
    instance_view_Subscription = "Subscriptions",
    instance_view_trackStatus = "Track Status",
    instance_View_btn_submitForApproval = "Submit for Approval",
    instance_View_downloadAllFiles = "Download All Files",
    instance_View_initiatePackageDeployment = "Initiate Deployment",
    instance_View_trackInstanceStatus = "Track Instance Status",
    instance_View_CustomDrawer_screenHelpMessage = "Track the status of this Instance.",
    instance_VIew_instanceRequested = "Instance Requested",
    instance_View_instanceCreationScheduled = "Instance Creation Scheduled",
    instance_View_instanceCreationInProgress = "Instance Creation in-Progress",
    instance_View_instanceCreationFailed = "Instance Creation Failed",
    instance_View_instanceCreationRejected = "Instance Creation Rejected",
    instance_View_instanceCreated = "Instance Created",
    instance_View_Field_podcode ="PodCode",
    instance_View_no_data_found ="No data found",

    instance_View_initiateDeployment = "Initiate Deployment",

    instance_View_pt1_heading = "Please verify Instance details and provide required details to initiate Package Deployment.",
    instance_View_pt1_field_type = "Type",
    instance_View_pt1_field_cname = "Cname",

    instance_view_label_deploymentFor = "DEPLOYMENT FOR",
    instance_View_Field_sku = "SKU",
    instance_View_Field_extensionType = "Extension Type",
    instance_View_extensionName = "Extension Name",
    instance_View_solutionPackage = "Customer Extension Package",
    instance_View_submitForDeployment_btn = "Initiate Deployment",
    instance_approval_previous_podVersion = 'ExistingPodVersion-1',
    instance_approval_current_podVersion = 'ExistingPodVersion',
    instance_view_approval_previous_podVersion = 'N-1 (Previous)',
    instance_view_approval_current_podVersion = 'N (Current)',
    instance_label_sfdc_customerId = "SFDC Customer ID",
    instance_label_netsuiteId = "NetSuite ID",

    /*EXTENSION CREATE/EDIT FIELD LABEL */
    instance_Create_Field_customerName = "Customer Name",
    instance_Create_Field_partnerName ="Partner Name",
    instance_Create_Field_type = "Instance Type",
    instance_Create_Field_typeof_instance = "Type Of Instance",
    instance_Create_Field_environment = "Environment Type",
    instance_Create_Field_cName = "Cname",
    instance_Create_Field_User = "CLM Admin",
    instance_Create_Field_region = "Region",
    instance_Create_Field_requiredByDate = "Required by Date",
    instance_Create_Field_iciVersion = "Ici Version",
    instance_Create_Field_environments = "Environment Tag",
    instance_Create_Field_subscriptions = "subscriptions",
    instance_Create_Field_multiTenantPod = "Multi-Tenant Pod",
    instance_Create_Field_referenceDocument = "Supporting Documents",
    instance_Create_saveAsDraft_btn = "Save as Draft",
    instance_Create_sendForApproval_btn = "Submit for Approval",
    instance_Create_cancel_btn = "Cancel",
    instance_Create_browse_btn = "Browse",
    instance_Create_updateRequest_btn = "Update Request",

    /*EXTENSION/PACKAGE HINT MESSAGE LABEL */
    instance_create_hint_message_provide = "Provide required details for Extension",
    instance_create_hint_message_provideTags = "Enter tag and press enter,please keep on adding multiple tags",
    instance_create_hint_message_provideFile = "Upload multiple files of 50 MB each. Supported file types are pdf, doc,docx, xls, xlsx and txt",
    instance_view_hint_message_noRefDocument_label = "No Reference Documents",
    instance_view_hint_message_noRefDocument_para = "we could not find any relevant document uploaded by Admin for Extension Management",
    instance_create_hint_message_validCharacters = "Valid characters include a-z,0-9,- and character a-z is required soon after -.",

    /*INSTANCE ERROR MESSAGE*/
    instance_create_error_message_futureDate = "Please enter valid date.",
    instance_create_error_message_requiredField = "Field can not be blank.",
    instance_error_message_allowed_character = "Valid characters include a-z,0-9,- and character a-z is required soon after -.",
    /*INSTANCE CONFIRM DIALOGE*/
    instance_Create_Confirm_Dialouge_confirmRequestAprove = "Are you sure you want to request approval for this instance?",

    /* NOTIFICATION MESSAGE AND HEADING */
    instance_notify_notvalid_msg = "Please submit all required fields.",
    instance_notify_extensionNotCreated = "Extension Not Created",
    instance_notify_extensionNotAvailable_heading = "Extension not available",
    instance_notify_extensionNotAvailable_body = "Extensions are not available for selected extension type",
    instance_notify_solutionPackageNotAvail_heading = "Customer Extension Packages not available",
    instance_notify_solutionPackageNotAvail_body = "Customer Extension Packages are not available for the selected extension",
    instance_notify_cannotSendApproval_heading = "Cannot send for approval",
    instance_notify_cannotSendApproval_body = "Please select present or future date",
    instance_notify_instanceRequested_heading = "Instance requested",
    instance_notify_instanceRequested_body = "Instance approval request was submitted successfully",
    instance_notify_RequiredField_deploymentNotInitiated_heading = "Deployment not initiated",
    instance_notify_RequiredField_deploymentNotInitiated_body = "Please enter required field",
    instance_notify_deploymentInitiated_heading = "Deployment initiated",
    instance_notify_deploymentInitiated_body = "Instance deployment is initiated successfully",
    instance_notify_deploymentNotInitiated_heading = "Deployment not initiated",
    instance_notify_deploymentNotInitiated_body = "Instance deployment is not initiated",
    instance_notify_documentsDownloaded_heading = "Documents Downloaded",
    instance_notify_documentsDownloaded_body = "Instance request documents downloaded.",
    instance_notify_documentsNotDownloaded_heading = "Documents Not Downloaded",
    instance_notify_documentsNotDownloaded_body = "Failed to download instance request documents",
    instance_notify_skuNotFound_heading = "SKU not found.",
    instance_notify_skuNotFound_body = "There is no SKU available.",
    instance_notify_inValidFileType_heading = "Invalid file type",
    instance_notify_inValidFileType_body = "Please upload valid file type",
    instance_notify_fileSizeExceed_heading = "File size exceeded",
    instance_notify_fileSizeExceed_body = "File size is more than 50 MB",
    instance_notify_emptyFile_heading = "Empty File Selected",
    instance_notify_emptyFile_body = "Uploaded file is an empty file, please check and upload a valid file.",
    instance_notify_noFileChoosen_heading = "No File choosen",
    instance_notify_noFileChoosen_body = "Please choose file",
    instance_notify_duplicateFile_heading = "Duplicate File",
    instance_notify_fileAlreadyAdded_body = "File Already Added",
    instance_notify_instanceNotCreated_heading = "Instance Not Created",
    instance_notify_instanceNotCreated_body = "Please enter required field",
    instance_notify_instanceRequestNotCreated_heading = "Instance request not created",
    instance_notify_instanceRequestNotCreated_body = "Instance request exceeding the approved number of instance type for the customer",
    instance_notify_instanceRequestSubmitted_heading = "Instance creation request submitted",
    instance_notify_instanceRequestSubmitted_body = "Instance creation request submitted successfully",
    instance_notify_draftCreated_heading = "Instance created as draft",
    instance_notify_draftCreated_body = "Instance request created as a draft",
    instance_notify_uploadFailed_heading = "Upload failed",
    instance_notify_uploadFailed_body = "File upload failed",
    instance_notify_instanceReqstInDraft_heading = "Instance request created in draft",
    instance_notify_instanceReqstInDraft_body = "Instance request created as a draft mode successfully",
    instance_notify_cNameExist_heading = "Cname already exists",
    instance_notify_cNameExist_body = "CName already exists in the system.",
    instance_notify_notValid_heading = "Not valid",
    instance_notify_notValid_body = "Please enter required field",
    instance_notify_instanceRequestUpdated_heading = "Instance request updated",
    instance_notify_instanceRequestUpdated_body = "Instance request updated successfully",
    instance_notify_updationFailed_heading = "updation failed",
    instance_notify_updationFailed_body = "Instance request is failed to update",
    instance_notify_View_extensionNotFound_heeading = "Extension not found",
    instance_notify_View_extensionNotFound_body = "There are no extensions available for selected extension type",
    instance_notify_View_solutionPackageNotFound_heeading  ="Customer Extension Packages not found",
    instance_notify_View_solutionPackageNotFound_body = "There are no Customer Extension Packages available for selected extension",
    instance_notify_duplicate_heading = "Package already exists",
    instance_notify_duplicate_body = "Combination of extension and package you are trying to add for deployment already exists.",
    instance_notify_provisioning_log_failed_heading = "Unable to download Log",
    instance_notify_provisioning_log_failed_body = "Provisioning Log for this instance can not be downloaded due to some Issue.",
    instance_notify_provisioning_log_success_heading = "Log Downloaded",
    instance_notify_provisioning_log_success_body = "Provisioning Log for this instance downloaded successfully.",
    instance_notify_provisioning_log_failed_404_body = "Provisioning Log for this instance can not be downloaded because Log file is not available for download.",

    /* No Result Found */
    instance_no_result_msg = "No Instance Found!",

    /*ICI TOOLS VIEW FIELD LABEL */
    instance_View_no_action_header="Tool not selected",
    instance_View_no_action_body="Please select a tool to continue.",
    instance_view_ICITools = "Instance Tools",
    instance_view_popuptext_icitoolsdrawer = 'You have requested to download the logs. Please click Confirm to begin the process. The process will take a few moments. Please wait for a while during the process.',
    instance_view_popuptext = "You have requested to download the logs. Please click Confirm to begin the process. The process will take a few moments. Please visit Instance Tools History page to download the logs.",
    instance_view_application_log_start_datetime = "Start Date and Time (UTC)",
    instance_view_application_log_end_datetime = "End Date and Time (UTC)",
    instance_view_worker_task_log_date = "SELECT DATE",
    instance_view_application_log_search_string = "Search",
    instance_view_database_log_requested = "Confirm Database Log Generation ",
    instance_view_application_log_requested= "Confirm Application Log Generation",
    instance_view_worker_task_log_requested= "Confirm Worker Task Log Generation",
    instance_view_instance_backup_requested= "Confirm Instance Backup",
    instance_view_esign_troubleshooting_requested= "Confirm Esign Troubleshooting",
    instance_view_esign_send_envelope_requested= "Confirm Sending Envelope",
    instance_view_application_log_popuptext="To initiate the application log request for the selected date range, click",
    instance_view_application_log_popuptext1 = "The process might take a few minutes. You can view/download the log file from the Instance Tools History page.",
    instance_view_worker_task_log_popuptext = "To initiate the worker task log request for the selected date, click",
    instance_view_worker_task_log_popuptext1 = "The process might take a few minutes. You can download the log file from the Instance Tools History page.",
    instance_view_instance_backup_log_popuptext = "To initiate instance backup, click", 
    instance_view_instance_backup_log_popuptext1 = "The process might take a few minutes. Go to the Instance Tools History page to track the request.",
    instance_view_esign_troubleshooting_popuptext = "To run Esign Troubleshooting, click", 
    instance_view_esign_troubleshooting_popuptext1 = "The process might take a few minutes. You can navigate to the Instance Tools History page to track the request.",
    instance_view_esign_send_envelope_popuptext = "To send the envelope to the recipient email ID, click", 
    instance_view_database_log_popuptext= "To initiate the database log request for the selected date range and table, click",
    instance_view_database_log_popuptext1= "The process might take a few minutes. You can download the log file from the Instance Tools History page.",
    instance_view_confirm_role_restart = "Confirm Role Restart",
    instance_view_confirm_task_restart = "Confirm Task Restart",
    instance_view_confirm_cache_flush="Confirm Cache Flush",
    instance_view_confirm_WorkerTask_Log='Confirm Worker Task Logs',
    instance_view_application_log_ready="Application log is ready",
    instance_view_worker_task_log_ready="Worker Task log is ready",
    instance_view_info_note = "The instance tool actions apply solely to the current ICI instance.",
    instance_view_application_log_header="Application Logs",
    instance_view_database_log_header="Database Logs",
    instance_view_modified_config_keys_header="Config Key Details",
    instance_view_ici_worker_tasks_header="ICI Worker Tasks",
    instance_view_esign_troubleshooter_header="Esign Troubleshooting",
    instance_view_role_restart_header="Role Restart",
    instance_view_task_restart_header="Task Restart",
    instance_view_instance_backup_header="Instance Backup",
    instance_view_cache_flush_header="Cache Flush",
    instance_view_reset_services_header="Reset Services",
    instance_view_workertask_log_header = "Worker Task Logs",
    instance_view_history_button_text = "button.",
    instance_view_application_log_desc="Use application logs to trace events and actions occurring on ICI instances. To generate application logs, enter the applicable date range. The request is processed in the background. Track progress and view/download logs by clicking the ",
    instance_view_esign_troubleshoot_desc="Use the esign troubleshooting utility to track and debug issues associated with esign configuration on your ICI instance.",
    instance_view_database_log_desc ="Use database logs to trace database transactions running on an ICI instance. To generate database logs, enter the applicable date range and table name. The request is processed in the background. Track progress and download logs by clicking the ",
    instance_view_role_restart_desc ="The Add-In Role Restart allows you to selectively restart specific microservices within the ICI instance. To avoid impact on the logged-in users, we recommend performing this action during non-business hours. The request is processed in the background. Track the progress by clicking the",
    instance_view_task_restart_desc ="Task restart restarts the ICI task services. To avoid impact on logged in users, IDN recommends performing task restart in nonbusiness hours. The request is processed in the background. Track the progress by clicking the ",
    instance_view_cache_flush_desc ="Cache flush clears user data from the cache memory. To avoid impact on logged in users, IDN recommends performing cache flush in nonbusiness hours. Track the progress by clicking the ",
    instance_view_reset_services_desc ="Power Reset description",
    instance_view_workertask_log_desc="Use worker task logs to track task progress, provide detailed task information, and identify performance bottlenecks and failures. You can generate logs for any day in the past month. The request is processed in the background. Track progress and download logs by clicking the ",
    instance_view_instance_backup_desc1="To initiate backup, select from the available options and click",
    instance_view_instance_backup_desc2= "These backups will only be retained for a duration of 30 days. The Instance Backup tool will trigger an email notification which contains the backup URL. To restore from a backup, submit a support ticket to Icertis CloudOps and share the backup URL with them.",
    instance_view_database_log_ready="Database log is ready",
    instance_view_field_expiration_date="Expiration Date",
    instance_notify_view_roleRestart = "Role Restart",
    instance_notify_update_roleRestart = "Role Restart was successful",
    instance_notify_error_roleRestart = "Role Restart was Unsuccessful",
    instance_ici_role_restart_desc ="Restart roles and microservices for the ICI UI, APIs, and task nodes.",
    instance_addin_role_restart_desc ="Restart roles and microservices for individual add-ins for the instance.",
    instance_ici_role_restart_header = "ICI",
    instance_addin_role_restart_header = "Add-ins",
    instance_notify_view_taskRestart = "Task Restart",
    instance_notify_update_taskRestart = "Task Restart was successful",
    instance_notify_error_taskRestart = "Task Restart was Unsuccessful",
    instance_notify_view_cacheFlush = "Cache Flush",
    instance_view_database_log_start_datetime = "Start Date and Time (UTC)",
    instance_view_ICI_Description="The instance tool actions performed are applicable only to the current ICI instance.",
    instance_view_ici_log_description = "You can fetch the log information for 24 hours at a time",
    instance_view_database_log_end_datetime = "End Date And Time (UTC)",
    instance_select_database_log = "Select database table ",
    instance_select_esign_account = "Select Account ",
    instance_confirm_dialouge = "Confirm",
    instance_cancel_dialouge = "Cancel",
    instance_view_gif_troubleshooter_requested= "Confirm GIF Troubleshooting",
    instance_view_gif_troubleshooter_popuptext = "To run GIF Troubleshooting, click", 
    instance_view_gif_troubleshooter_popuptext1 = "The process might take a few minutes. You can navigate to the Instance Tools History page to track the request.",
    instance_view_gif_troubleshooter_header="GIF Troubleshooting",
    instance_view_addin_restart_header="Add-In Role Restart",
    instance_view_gif_troubleshoot_desc="Track and troubleshoot the Generic Integration Framework (GIF) configuration such as tenant config keys, connections, and masterdata used to integrate ICI with other external systems.",
    instance_gif_config_error_message = "There’s an error in the GIF configuration. To troubleshoot, go through the records listed in the tables below.",
    instance_gif_view_info_message = "Run the Publish Payload activity after you update the Client App Cross Reference or Client App Entity Mapping configurations.",
    instance_select_gif_integration_type = "Select Integration Type",
    instance_select_gif_task_category = "Select Task Category",
    instance_select_gif_data_flow = "Select Data Flow",
    instance_gif_Integration_type_messaging_based = "Messaging-Based",
    instance_gif_Integration_type_file_based = "File-Based",
    instance_gif_config_info_message = "Note : Please refer the export file for the complete set of data base file.",
    instance_gif_validation_info_message1 = "If an attribute in an ICI reference key doesn’t exist in the configured ICI entity, or if no reference record is found in the Client App Entity Mapping, it is highlighted in ",
    instance_gif_validation_info_message2 = ". Refer to the troubleshooting report for the complete set of database records.",
    instance_notify_view_databaselogs="Database Logs",
    instance_notify_view_WorkerTaskLogs="Worker Task Logs",
    instance_notify_view_hint="End time should not be less than Start Time",
    instance_error_InvalidStartDate="Start Date and Time is invalid",
    instance_error_InvalidEndDate="End Date and Time is invalid",
    instance_error_startdate_greater="Start Date and Time must be greater than",
    instance_error_enddate_greater="End Date and Time must be greater than",
    instance_error_enddate="End Date and Time must be greater than Start Date and Time", 
    instance_error_enddate_less="End Date and Time must be less than",
    instance_error_startdate_less="Start Date and Time must be less than",
    instance_error_enddate_range="End Date and Time must be within 24 hours from Start Date and Time",
    instance_error_workerTask_msg = 'Selected date must be between ',
    instance_date_error_msg = 'Start date must be between ',
    instance_tools_cancel_button = 'Cancel',
    instance_tools_application_log_short_desc = 'Trace events and actions occurring on the ICI instance.',
    instance_tools_database_log_short_desc = 'Trace database transactions running on the ICI instance.',
    instance_tools_worker_task_log_short_desc = 'Track information about event-based and scheduled-based tasks running on the ICI instance.',
    instance_tools_modified_config_keys_log_short_desc = 'Track all custom config keys deployed on this ICI instance.',
    instance_tools_esign_troublesooter_log_short_desc = 'Track and debug issues associated with esign configuration.',
    instance_tools_role_restart_short_desc = 'Role restart resets the Elasticsearch configuration and restarts the server to reflect updated data on the ICI instance.',
    instance_tools_cache_flush_short_desc = 'Clear frequently accessed data from the cache memory.',
    instance_tools_task_restart_short_desc = 'Stop and start the ICI task services.',
    instance_tools_instance_backup_short_desc = 'Initiate backup of ICI instances.',
    instance_tool_debugging_utilities = 'Debugging Utilities',
    instance_tool_heading = 'Instance Tools',
    //config-key
    instance_notify_invalidConfig_heading="Invalid File",
    instance_notify_invalidConfig_body ="Invalid Tenant Config File.",
    instance_notify_configfileSizeExceed_body = "File size is more than 2 MB",
    instance_notify_fileUploaded_heading="File uploaded",
    instance_notify_fileUploaded_body="TenantConfig file has been uploaded successfully",
    instance_notify_fileUploadFail_heading="File upload failed",
    instance_notify_fileUploadFail_body="TenantConfig file upload failed",
    instance_notify_downloadSuccess_heading="Download Successful",
    instance_notify_downloadSuccess_body="Config file downloaded successfully",
    instance_notify_downloadFail_heading="'Download Failed",
    instance_notify_downloadFail_body="Config file download failed",
    instance_notify_config_notfound_heading="TenantConfig data not found",
    instance_notify_config_notfound_heading_sample="There is no tenant config data available for this instance, Please download the SampleTenantConfig.json file and update/upload the configuration",

     /* Re-direct Dialog strings */
     redirect_dialog_heading = "Confirm Redirection",
     redirect_dialog_body = "The link will open in a new browser tab. Click <b>Yes</b> to confirm.",
     redirect_dialog_primary_btn = 'Yes',
     redirect_dialog_secondary_btn = 'No',

     instance_initiate_deployment_next_btn = "Next",
     instance_initiate_deployment_previous_btn ="Previous",
     instance_edit_config_name='Name',
     instance_edit_config_value="Value",
     instance_edit_config_valueType="Value Type",
     instance_edit_config_OverrideType="Override Type",
     instance_edit_config_DefaultValue="Default Value",
     instance_edit_config_IsEncrypted="Is Encrypted",
     instance_initiate_deployment_warning_header = "WARNING!",
     instance_initiate_deployment_wait_header= "WAIT!",
     instance_config_drawer_heading="Edit Config Key",
     instance_config_drawer_screen_message="Edit the Config Key to set the appropriate value as per the requirement.",
     instance_tools_history_screen_message="The following logs are available for up to {day} days.",
     instance_stepper_step_one_heading= "Select Packages",
     instance_stepper_step_one_message = "Choose the required packages for deployment",
     instance_stepper_step_two_heading= "Manage Config Keys",
     instance_stepper_step_two_message ="Edit the required config key values",
     instance_stepper_step_three_heading="Preview",
     instance_stepper_step_three_message = "Verify the details before confirming",
     instance_config_int32_error_message="Input is not a valid int32 number.",
     instance_config_int64_error_message="Input is not a valid int64 number.",
     instance_config_string_error_message="Input is not a valid string.",
     instance_config_json_error_message='Input is not a valid json.',
     instance_config_preview_table_heading="Config keys in the Deployment Request",
     
     //Approve Instance 
    instance_approve_confirmation = "Confirmation",
    instance_approve_popuptext = "You have entered the ICI version for the instance as {inputiciversion}, however the POD seems to be on a different version : {podiciversion}. Please confirm you want to proceed with the ICI version {inputiciversion}",
    instance_approve_iciversion_error = "Please add the ICI version which includes Major,Minor,Patch and Build numbers with first digit as non-zero. Eg:- 1.0.0.0",
    instance_approve_infrastructure_details = "Instance Infrastructure Details",
    instance_approve_infrastructure_details_hint = "Provide all the instance infrastructure details.",
    instance_approve_cname_error_message = "Valid characters are 'imt' at start, a-z,0-9 and no special characters of maxlength 9.",
    instance_approve_worker_networkIP_error_message = "You can enter 0-9,. and no special characters",
    instance_approve_instance_properties = "Instance Properties",
    instance_approve_instance_properties_hint ="Select all the relevant instance properties.",
    instance_approve_field_billing_type="Billing Type",
    instance_approve_checklist= "Check List",
    instance_approve_checklist_hint= "Update check list before Approval.",
    instance_approve_btn = "Approve",
    instance_reject_btn = "Reject",
    instance_cancel_btn = "Cancel",
    instance_next_btn = "Next",
    instance_previous_btn = "Previous",
    instance_draft_btn = "Save as Draft",
    instance_ici_workerTask_btn = "ICI WorkerTask",
    instance_more_action_btn = "More Action",
    instance_approve_reject_btn = "Approve/ Reject",

   

    instance_card_header_non_idn_user_instance = "Instance Details",
    instance_card_header_non_idn_user_instance_users ="Instance Users",
    instance_card_header_non_idn_user_info ='You can provision below users on this instance by clicking on the “Provision" button. You can see if the user is provisioned on this instance or not in the provisioning status column. Users need to be assigned to the customer in order to appear in the below list.',
    instance_non_idn_user_provision_success_header= "User provisioned",
    instance_non_idn_user_provision_success_body= "User has been provisioned successfully.",
    instance_non_idn_user_provision_failure_header= "User not provisioned",
    instance_non_idn_user_provision_failure_body= "User provisioning failed.",
    instance_card_header_non_idn_user_note= "Note: the limit for the number of users that can be provisioned on any instance is ",

    //ICI WorkerTask
    workerTask_card_header_instance = "Instance Details",
    workerTask_header_task = 'Task',
    workerTask_header_task_label = 'Select Task',
    workerTask_option_event_task_header = 'Event Task',
    workerTask_option_schedule_task_header = 'Scheduled Task',
    workertask_form_field_id = 'ID',
    workertask_form_field_name = 'Name',
    workertask_form_field_use_decryption ='Use Decryption',
    workertask_form_field_type = 'Task Type',
    workertask_form_field_description ='Description',
    workertask_form_field_cron_expression = 'CRON Expression',
    workertask_form_field_interest_configuration = 'Interest Table Configuration',
    workertask_interest_configuration_field_name = 'Name',
    workertask_interest_configuration_field_name_hover = 'Event name for task subscription.',
    workertask_interest_configuration_field_topic_name = 'Topic',
    workertask_interest_configuration_field_topic_name_hover = 'Azure Service Bus topic name to which the task must subscribe. Used for publish/subscribe messaging.',
    workertask_interest_configuration_field_subscription_name = 'Subscription',
    workertask_interest_configuration_field_subscription_name_hover = 'Azure Service Bus subscription name to receive messages from the topic.',
    workertask_interest_configuration_field_parallel_workers = 'Parallel Workers',
    workertask_interest_configuration_field_parallel_workers_hover = 'Indicates if the task uses parallel workers.',
    workertask_interest_configuration_field_filter = 'Filter',
    workertask_interest_configuration_field_filter_hover = 'Filter rule to determine which messages are received from the topic.',
    workertask_configuration_field_action = 'action',
    workertask_form_field_setting_configuration = 'Setting Table Configuration',
    workertask_setting_configuration_field_name = 'Name',
    workertask_setting_configuration_field_name_hover = 'Setting key name',
    workertask_setting_configuration_field_is_encrypted = 'Encrypted',
    workertask_setting_configuration_field_is_encrypted_hover = 'Indicates if the setting key is encrypted.',
    workertask_button_submit = 'Submit',
    workertask_button_update = 'Update',
    workertask_button_cancel = 'Cancel',
    workertask_message_valid_id = 'Valid characters: 4 to 6 digit numbers greater than 1000.',

    workertask_redirect_dialog_heading = "Confirm Deactivate",
    workertask_redirect_dialog_body = "Are you sure you want to deactivate the worker task entry?",
    workertask_redirect_dialog_heading_enable = "Confirm Activate",
    workertask_redirect_dialog_body_enable = "Are you sure you want to activate the worker task entry?",
    workertask_redirect_dialog_primary_btn = 'Yes',
    workertask_redirect_dialog_secondary_btn = 'No',

    workertask_notify_task_created_body = 'The worker task is created.',
    workertask_notify_task_created_heading = 'Task Created',
    workertask_notify_task_created_failed_body = 'The worker task could not be created at this time. Try again later or contact support if the issue persists.',
    workertask_notify_task_created_failed_heading = 'Task Creation Unsuccessful',
    workertask_notify_task_update_body = 'The worker task is updated.',
    workertask_notify_task_update_heading = 'Task Updated',
    workertask_notify_task_update_failed_body = 'The worker task update was unsuccessful. Try again later or contact support if the issue persists.',
    workertask_notify_task_update_failed_heading = 'Task Update Unsuccessful',
    workertask_notify_task_disable_body = 'The worker task is deactivated.',
    workertask_notify_task_disable_heading = 'Task Deactivated',
    workertask_notify_task_disable_failed_body = 'The worker task could not be deactivated at this time. Try again later or contact support if the issue persists.',
    workertask_notify_task_disable_failed_heading = 'Task Deactivation Unsuccessful',
    workertask_notify_task_enable_body = 'The worker task is activated.',
    workertask_notify_task_enable_heading = 'Task Activated',
    workertask_notify_task_enable_failed_body = 'The worker task could not be activated at this time. Try again later or contact support if the issue persists.',
    workertask_notify_task_enable_failed_heading = 'Task Activation Unsuccessful',

    workertask_list_field_task_id = 'Task Id',
    workertask_list_field_task_type = 'Task Type',
    workertask_list_field_task_name = 'Task Name',
    workertask_list_field_instance_name = 'Instance Name',
    workertask_list_field_partner_name = 'Partner Name',
    workertask_list_field_customer_name = 'Customer Name',
    workertask_list_field_requestor_details = 'Requester Details',
    workertask_list_field_status = 'Status',
    workertask_list_field_action = 'Action',
    workertask_message_valid_name = "Task names must start with a letter and can include letters, numbers, accented characters, and special characters (_, !, #, ^, ~, -, ., ').",
    workertask_list_field_running = 'Running',
    workertask_list_field_category = 'Category',
    workertask_list_field_description = 'Description',
    workertask_list_field_last_executed_on = 'Last executed on',

    instance_approval_checklist_question1 = "Have you verified the Worker IP is currently unallocated?",
    instance_approval_checklist_question2 = "Have you verified if you have followed the right naming standard for the CName abbreviation?",
    instance_approval_checklist_question3 = "Have you verified if the ICI version in the request is accurate and validated from POD correctly?",
    instance_approval_checklist_question4 = "Have you verified if the value for the Instance type is correct?",
    instance_approval_checklist_question5 = "Have you verified if the Billing Type of the instance is correct and as per agreements with customers?",
    instance_approval_checklist_question6 = "Have you verified if the Environment tag and Environment Type are in line with each other?",
    instance_approval_checklist_question7 = "Have you verified if the expiration date that you have entered is correct?",

    instance_constant_production = "Production",
    instance_notify_deployment_requested_body = "Instance deployment requested successfully",
    instance_notify_deployment_requested_heading = "Deployment Requested",
    instance_notify_deployment_request_failed_body = "Instance deployment request failed",
    instance_notify_deployment_request_failed_heading = "Deployment not requested",
    instance_constant_prod_tag = "PROD",
    instance_View_Field_user_provision_info = "To provision users on the ICI instance via IDN, the instance must be configured on the IDN Active Directory.",
    instance_backup_show_error_message = "A backup task is currently underway. Wait for its completion and then try again.",
    instance_esign_config_error_message = "There’s an error in the esign configuration. To troubleshoot, go through the records listed in the tables below.",
    instance_backup_status_success_tooltip ='To restore from backup, reach out to Icertis CloudOps and share the backup URL from the email notification.',
    instance_backup_status_failed_tooltip ='To troubleshoot, reach out to Icertis Support and share the workflow URL.',

    instance_list_clm_Admin_header='CLM Admin',
    instance_list_clm_Admin_failed_header = 'Failed',
    instance_tools_card_field_instance_name = 'Instance Name',
    instance_tools_card_field_version = "Version",
    instance_tools_card_field_multi_esign_configuration_enabled = "Multi Esign Configuration Enabled",
    instance_tools_card_field_multi_esign_configuration_enabled_info = "Indicates if the Core.Esign.MultiEsignProvidersConfiguration key is enabled for the ICI instance.",
    instance_tools_card_field_send_as_signature = "Send As Signature",
    instance_tools_card_field_send_as_signature_info = "Value of the “Core.Esign.Adobe.SendForSignatureAs” key which indicates the source from where the email is sent to sign the documents. “Account Owner” - Email is sent from Account Owner. “Sender” - Email is sent by Agreement Owner. “Custom” - Email is sent by specified email address.",
    instance_tools_card_field_logging_verbosity = "Logging Verbosity",
    instance_tools_card_field_logging_verbosity_info = "Value of the “LoggingVerbosity” key which indicates the threshold for capturing logs for debugging purposes.",
    esign_email_info = "Indicates the source from where the email will be sent to sign the documents. “Account Owner” - Email will be sent from Account Owner. “Sender” - Email will be sent by Agreement Owner. “Custom” - Email will be sent by specified email address.",
    instanceTools_CustomerName_label = 'Customer Name',
    instanceTools_Cname_label = 'cname',
    instanceTools_landingPage_heading = 'Instance Details',
    instanceTools_instanceType_label = 'Instance Type',
    instanceTools_iciversion_lable = 'ICI Version',
    instanceTools_access_denied = 'Debugging Utilities Access Denied.',
    instanceTools_reason_lbl= 'Reason',
    instanceTools_requestAccess_msg = 'You can request access again. To proceed, click',
    instanceTools_RequestAccess_btn = 'Request Access',
    instanceTools_RequestAccess_timeUp = 'Access Request Timed Out',
    instanceTools_restoreAccess_msg = 'To restore access, click ',
    instanceTools_requestAccess_message = 'Request time-limited access to Debugging Utilities for the production instance.',
    instanceToos_selectHours_lbl = 'Select Hours',
    instanceTools_RequestAccessPending_msg = ' Approval pending for your request.',
    instanceTools_requestAcess_requestedOn = 'Requested on',
    instanceTools_requestAccess_requestedDuration = 'Requested Duration',
    instanceTools_requestAccess_timeLeft = 'TIME LEFT: ',
    instanceTools_confirm_btn = 'Confirm',
    instanceTools_cancel_btn  = 'Cancel',
    instanceTools_history_download_started_header = 'Download Started',
    instanceTools_history_download_started_body = 'The download has started and will continue in the background.',
    instance_tools_hangfireDashboard_header = 'Hangfire Dashboard',
    instance_tools_hangfireDashboard_body = 'Hangfire Dashboard data is not available at this point of time, please try later.',
    instance_tools_debugging_utilities_success_body = 'Access to Debugging Utilities is granted.',
    request_success_header = 'Request Approved',
    instance_tools_hangfire_success_body = 'Access to Hangfire Dashboard is granted.',
    instance_tools_debugging_utilities_error_body = 'Access to Debugging Utilities is declined.',
    request_error_header = 'Request Declined',
    instance_tools_hangfire_error_body = 'Access to Hangfire Dashboard is declined.',
    instance_tools_debugging_utilities_failedtodecline_body = 'Failed to decline access for Debugging Utilities.',
    request_failed_header = 'Request Failed',
    instance_tools_hangfire_failedtodecline_body = 'Failed to decline access for Hangfire Dashboard.',
    instance_tools_hangfire_info_msg = ' Access is permanent for non-production instances and time-limited for production instances.',
    instanceTools_history_view_logs_header = 'Processing Request',
    instanceTools_history_view_logs_body = 'The page is loading. Hold on.',
    instanceTools_worker_task_useDecryption_description = 'Use decryption for encrypted values.',

    instance_health_error_tooltip_message = 'Connection issue. Try again later.',

}  

