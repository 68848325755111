import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { Sort } from '@angular/material/sort';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { InstanceService } from 'src/app/core/services/instance-management/instance.service';
import { SharedComponent } from 'src/app/shared/enum/sharedComponentEnum';
import { IdnSearchBoxComponent } from 'src/app/shared/idn-sharedcomponents/idn-search-box/idn-search-box.component';
import { NotificationService } from 'src/app/shared/notification/notification.service';

@Component({
  selector: 'app-approval-list',
  templateUrl: './approval-list.component.html',
  styleUrls: ['./approval-list.component.scss']
})
export class ApprovalListComponent implements OnInit {
  breadCrumbItems:any = [];
  pageNumber: number = 1;
  pageSize: number = 10;
  currentPage: number = 0;
  pageLength: number = 0;
  viewApprovalListData:any;
  displayedColumnsviewApprovalListData: any = ['requesttype','partnername','customername','cname', 'createdby','status','action'];
  dataSourceViewApprovalListData:any;
  dataSourceValuesviewApprovalListData: any = {
    requesttype: {
      label: 'Request Type',
      data: 'featureCategory',
    },
    partnername: {
      label: 'Partner Name',
      data: 'partnerName',
    },
    customername:{
      label: 'Customer Name',
      data: 'customerName'
    },
    cname:{
      label: 'Request For',
      data: 'entityName'
    },
    createdby :{
      label: 'Requested By',
      data: 'requestedBy'
    },
    status :{
      label: 'Status',
      data: 'status'
    },
    action :{
      label: 'Action',
      data: 'action'
    }
  };
  searchText: any;
  selectAll = 'selectAll';
  status:any;
  reqType:any;
  requestStatus:any = [];
  @ViewChild('requesttype') requestType!: MatSelect;
  @ViewChild('requeststatus') requestStatusId!: MatSelect;
  @ViewChild(IdnSearchBoxComponent, { static: false })
    childComponent!: IdnSearchBoxComponent;
  
  statusList:any = [{name:'Pending', id:'1'},
    {name:'Approved', id:'2'},
    {name:'Rejected', id:'3'},
  ]
  requestStatusEnum: any = {
    debuggingUtility: 1,
    hangfire: 3
  }
  public sharedEnum = SharedComponent;
  sortBy :any;
  approveHangfire: boolean = false;
  approveDebuggingUtility: boolean = true;
  referenceName: any = "approvalList";
  isApproveRejectInstance: boolean = false;
  isApproveRejectDeployment: boolean = false;
  isApproveRejectCertification: boolean = false;
  count: any = 0;

  constructor(
    private router: Router,
    private titleService: Title,
    private instanceService:InstanceService,
    private notificationService:NotificationService,
    private permissionService: PermissionsService,
  ){}
  ngOnInit(): void {
    this.titleService.setTitle('Actionables | Icertis Developer Network');
    this.breadCrumbItems = [
      { label: "Home", path: "actionables"},
      { label: "My Tasks", path: "actionables/my-approvals"},
    ];
    this.approveHangfire = this.permissionService.getPermission('ApprovalAccessToHangfire');
    this.approveDebuggingUtility = this.permissionService.getPermission('ApprovalAccessToDebuggingUtilities-Prod');
    this.isApproveRejectInstance = this.permissionService.getPermission('ApproveInstance') || this.permissionService.getPermission('RejectInstance');
    this.isApproveRejectDeployment = this.permissionService.getPermission('ApproveDeployment') || this.permissionService.getPermission('RejectDeployment');
    this.isApproveRejectCertification = this.permissionService.getPermission("ApproveCertification") || this.permissionService.getPermission("RejectCertification");
    this.statusList = this.statusList?.sort((a:any, b:any) =>{ return a.name.localeCompare(b.name);});
    this.getPendingCount();
    this.getRequestTypeFilterList().then((data:any)=>{
      if(data?.length== 1){
        this.reqType = data[0].id;
      }
    });
    this.getFeatureAccessRequestsList();
  }

  getFeatureAccessRequestsList(){
    this.sortBy = this.sortBy ? this.sortBy : '';
    this.instanceService.getFeatureAccessRequestsList(this.searchText,this.reqType,this.status,this.pageNumber, this.pageSize, this.sortBy).subscribe((resp:any)=>{
      if(resp){
        this.dataSourceViewApprovalListData = {
          matchingCount: resp?.body?.content?.matchingCount,
          records: resp?.body?.content?.records
        }
        this.viewApprovalListData = {
          reference: 'ApprovalListTable',
          displayedColumns: this.displayedColumnsviewApprovalListData,
          dataSourceValues: this.dataSourceValuesviewApprovalListData,
          dataSource: this.dataSourceViewApprovalListData,
          pagination: {
            required: true,
            currentPage: this.currentPage,
            pageLength: this.pageLength,
            pageNumber : this.pageNumber,
            pageSize : this.pageSize
         },
        };
      }
      else{
        if(resp?.body?.isSuccessful == false){
          this.notificationService.showNotification(
            'Failed to fetch the Records',
            'warning',
            'Failed to Load Records'
          );
        }
      }
    })
  }

  async getRequestTypeFilterList(){
    return new Promise((resolve) => {
      if(this.approveDebuggingUtility){
        this.requestStatus = [{name:'Debugging Utilities', id:'1'}];
      }
      if(this.approveHangfire){
        this.requestStatus = [...this.requestStatus,{name:'Hangfire Access', id:'3'}];
      }
      if(this.isApproveRejectInstance){
        this.requestStatus = [...this.requestStatus,{name:'Instance Request', id:'4'}];
      }
      if(this.isApproveRejectCertification){
        this.requestStatus = [...this.requestStatus,{name:'Certification Request', id:'5'}];
      }
      if(this.isApproveRejectDeployment){
        this.requestStatus = [...this.requestStatus,{name:'Deployment Request', id:'6'}];
      }
      this.requestStatus = this.requestStatus?.sort((a:any, b:any) =>{ return a.name.localeCompare(b.name);});
      resolve(this.requestStatus);
    })   
  }

  pageChanged(data:any) {
    let event = data?.event;
    this.pageSize = event?.pageSize;
    this.currentPage = event?.pageIndex;
    this.pageNumber = event?.pageIndex + 1;
    let filters = localStorage.getItem('viewApprovalListFilter');
    if (filters != null) {
      let Json = {
        'search': this.searchText ? this.searchText : '',
        'pageSize': this.pageSize ? this.pageSize : '',
        'pageNumber': this.pageNumber ? this.pageNumber : '',
        'currentPage': this.currentPage ? this.currentPage : '',
      };
      localStorage.setItem('viewApprovalListFilter', JSON.stringify(Json))
    }
    this.getFeatureAccessRequestsList();
  }

  onSearchData(searchData:any){
    this.searchText = searchData ? searchData : '';
    this.getFeatureAccessRequestsList();
  }
  ViewRequestDetails(event:any){
    let requestId = event.data.id;
    localStorage.setItem('debuggingUtilitiesAccessRequestId',requestId);
    let navLink = 'home/actionables/my-approvals/view-approvals';
    this.router.navigate([navLink]);
  }

  doFilter(){
    let filters = localStorage.getItem('filterApprovalRequest');
    if (filters != null) {
      let Json = {
        filter: this.searchText,
        status:
          this.requestStatusId.value == undefined ||
            this.requestStatusId.value == this.selectAll
            ? ''
            : this.requestStatusId.value,
        pagesize: this.pageSize,
        pageNumber: this.pageNumber,
        currentPage: this.currentPage,
        requestType :  this.requestType?.value == undefined ||
        this.requestType?.value == this.selectAll
        ? ''
        : this.requestType?.value,
      };
      localStorage.setItem('filterApprovalRequest', JSON.stringify(Json));
    } else {
      this.searchText = this.searchText;
      this.status =
      this.requestStatusId.value == undefined || this.requestStatusId.value == this.selectAll
          ? ''
          : Number(this.requestStatusId?.value);
      this.reqType =  this.requestType?.value == undefined ||
        this.requestType?.value === this.selectAll
        ? ''
        : Number(this.requestType?.value);
    }
    this.getFeatureAccessRequestsList();
  }

  announceSortChange(data: any) {
    let sortState: Sort = data?.event;
    if (sortState?.direction == 'desc') {
      this.sortBy = sortState?.active + '_' + sortState?.direction;
    } else {
      this.sortBy = sortState?.active;
    }
    this.getFeatureAccessRequestsList();
  }

  clearFilter(){
    this.searchText = '';
    this.childComponent.onClear();
    this.status = '';
    if(this.requestStatusId){
      this.requestStatusId.value = this.status;
    }
    this.reqType = '';
    if(this.requestType){
      this.requestType.value = this.reqType;
    }
    this.getFeatureAccessRequestsList();
  }

  getPendingCount(){
    this.instanceService.getToolsAccessRequestApprovalPendingCount().subscribe((res:any)=>{
      if(res){
        this.count = res.body.content;
      }
    })
  }

}
