import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private http: HttpClient) {}
  baseApiUrl: string = environment.appGatewayUrl;
  headers: any = environment.headers;
  public extSubject = new BehaviorSubject<any>('');

  public getCustomerList(filterSearchValue: string, sortingColumn: string, pageNumber: number, pageSize: number,status:any,customerStatus?:any, partnerName?:any, customerCreateDateFrom?:any,customerCreateDateTo?:any, regionId?:any, skuId?:any, customerInstanceTypes?:any ) {
    return this.http.get(this.baseApiUrl + 'api/Customer/List',  { params: {'filter': filterSearchValue, 'sortBy': sortingColumn,
    'pageNumber': pageNumber, 'pageSize': pageSize,'status': status, 'customerStatus':customerStatus, 'partner':partnerName, 'customerCreateDateFrom':customerCreateDateFrom, 'customerCreateDateTo':customerCreateDateTo,'regionIds':regionId,'customerICIComponentIds':skuId,'customerInstanceTypes':customerInstanceTypes}, headers: this.headers });
  }

  public postDataUrl(url: string, paraData?: any, paraBody?: any): Observable<any> {
    return this.http.post(this.baseApiUrl + url, paraBody, { headers: this.headers, params: paraData, observe: 'response' });
  }

  public putDataUrl(url: string, paraData?: any, paraBody?: any): Observable<any> {
    return this.http.put(this.baseApiUrl + url, paraBody, { headers: this.headers, params: paraData, observe: 'response' });
  }

  public getCustomerByID(id: any) {
    return this.http.get(this.baseApiUrl + 'api/Customer/' + id, { headers: this.headers })
  }

  public checkDuplicateCustomer(customerName: any) {
    return this.http.get(this.baseApiUrl + 'api/Customer/CheckIfCustomerNameExists', { params: { 'name': customerName }, headers: this.headers })
  }

  public changeStatus(id: number, status: any): Observable<any> {
    return this.http.post(this.baseApiUrl + 'api/Customer/' + id + '/'+ status,{}, { observe: 'response' })
  }

  public getInstanceTypes(){
    return this.http.get(this.baseApiUrl + 'api/Customer/InstanceTypes' , { headers: this.headers })
  }

  public getRegions(){
    return this.http.get(this.baseApiUrl + 'api/Customer/Regions' , { headers: this.headers })
  }

  //get the reference data for customer status
  public getInfraDBCustomerLiveStatuses(){
    return this.http.get(this.baseApiUrl + 'api/Customer/InfraDBCustomerLiveStatuses' , { headers: this.headers })
  }

  public getSKU(partnerId:any){
    return this.http.get(this.baseApiUrl + 'api/customer/SKU' , {params: { 'partnerId': partnerId }, headers: this.headers })
  }

  public customerApprove(data:any): Observable<any>{
    return this.http.put(this.baseApiUrl + 'api/Customer/CustomerApprove' ,data, { headers: this.headers, observe: 'response' })
  }

  public customerReject(data:any): Observable<any>{
    return this.http.put(this.baseApiUrl + 'api/Customer/CustomerReject' , data,{ headers: this.headers, observe: 'response' })
  }

  public submitForApproval(data:any): Observable<any>{
    return this.http.put(this.baseApiUrl + 'api/Customer/SubmitForApproval' ,data, { headers: this.headers, observe: 'response' })
  }

  public getBlobUploadConnection(id: any) {
    return this.http.get(this.baseApiUrl + 'api/Customer/GetBlobUploadConnection', { params: { 'id': id }, headers: this.headers })
  }

  public getBlobDownloadConnection(id: any, filename: any) {
    return this.http.get(this.baseApiUrl + 'api/Customer/GetBlobDownloadConnection', { params: { 'id': id, 'filename': filename }, headers: this.headers })
  }

  public getPartnerList() {
    return this.http.get(this.baseApiUrl + 'api/Customer/Partners', { headers: this.headers })
  }

  public getCustomerSnapshot(){
    return this.http.get(this.baseApiUrl + 'api/Customer/GetCustomerSnapshot', { headers: this.headers })
  }

  public GetExportedFileHistory(filterSearchValue?: any, sortingColumn?: any, pageNumber?: any, pageSize?: any){
    return this.http.get(this.baseApiUrl + 'api/Customer/GetExportedFileHistory',  { params: {'filter': filterSearchValue, 'sortBy': sortingColumn,
    'pageNumber': pageNumber, 'pageSize': pageSize}, headers: this.headers });
  }

  public CustomerExport(filterParams:any): Observable<any> {
    return this.http.post(this.baseApiUrl + 'api/Customer/CustomerExport',filterParams,{ headers: this.headers , observe: 'response' })
  }

  public GetExportedFileBlobDownloadConnection(id: any, filename: any) {
    return this.http.get(this.baseApiUrl + 'api/Customer/GetExportedFileBlobDownloadConnection', { params: { 'uniqueIdentifier': id, 'filename': filename }, headers: this.headers })
  }
  public getAssociatedCLMPartnersFromInstance(id: any) {
    return this.http.get(this.baseApiUrl + 'api/InstanceLookUp/GetAssociatedCLMPartnersFromInstance', { params: { 'customerId': id}, headers: this.headers })
  }

  public async getCustomerDeploymentSnapshot(customerId: any, instanceName:any='',loggedInUserPartnerAdmin:any='') {
    if(loggedInUserPartnerAdmin){
      return this.http.get(this.baseApiUrl + 'api/Customer/GetCustomerDeploymentSnapshot', { params: { 'customerId': customerId, 'iciTenantCName':instanceName, 'partnerId':loggedInUserPartnerAdmin }, headers: this.headers })
    } else {
      return this.http.get(this.baseApiUrl + 'api/Customer/GetCustomerDeploymentSnapshot', { params: { 'customerId': customerId, 'iciTenantCName':instanceName}, headers: this.headers })
    }
  }

}
