import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { InstanceService } from 'src/app/core/services/instance-management/instance.service';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';
import { InstanceManagement } from 'src/app/shared/enum/instance-management';
import { Location } from '@angular/common';
import { FormControl } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Sort } from '@angular/material/sort';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { AzureBlobStorageService } from 'src/app/core/services/azure-blob-storage/azure-blob-storage.service';
import { LoaderService } from 'src/app/core/interceptors/loader/loader.service';

const EXPORT_ICON = `<svg xmlns="http://www.w3.org/2000/svg" width="138.972" height="146.126" viewBox="0 0 138.972 146.126">
<g id="Group_815" data-name="Group 815" transform="translate(-179.737 0)">
  <g id="Group_28" data-name="Group 28" transform="translate(179.737 0)">
    <path id="Path_410" data-name="Path 410" d="M625.94,195.658a3.224,3.224,0,0,0-2.947-1.972H564.151a3.191,3.191,0,0,0-3.189,3.189v74.439a3.191,3.191,0,0,0,3.189,3.19h58.842a3.194,3.194,0,0,0,3.19-3.189V196.877A3.131,3.131,0,0,0,625.94,195.658Zm-.466,75.655a2.484,2.484,0,0,1-2.48,2.48H564.151a2.48,2.48,0,0,1-2.48-2.48h0V196.877a2.484,2.484,0,0,1,2.48-2.48h58.842a2.494,2.494,0,0,1,2.3,1.563,2.75,2.75,0,0,1,.093.28,2.459,2.459,0,0,1,.085.64Z" transform="translate(-560.961 -193.686)" fill="#ccc"/>
    <path id="Path_411" data-name="Path 411" d="M642.355,223.867H624.631a1.418,1.418,0,1,1,0-2.836h17.723a1.418,1.418,0,1,1,0,2.836Z" transform="translate(-589.657 -206.291)" fill="#ccc"/>
    <path id="Path_412" data-name="Path 412" d="M642.355,237.677H624.631a1.418,1.418,0,1,1,0-2.836h17.723a1.418,1.418,0,1,1,0,2.836Z" transform="translate(-589.657 -212.657)" fill="#ccc"/>
    <path id="Path_413" data-name="Path 413" d="M596.536,232.166H583.581a1.6,1.6,0,0,1-1.6-1.6V215.287a1.6,1.6,0,0,1,1.595-1.6h12.955a1.6,1.6,0,0,1,1.595,1.6v15.284A1.6,1.6,0,0,1,596.536,232.166Z" transform="translate(-570.653 -202.908)" fill="#ccc"/>
    <path id="Path_414" data-name="Path 414" d="M623.03,265.953H582.975a1.418,1.418,0,1,1,0-2.836H623.03a1.418,1.418,0,0,1,0,2.836Z" transform="translate(-570.455 -225.691)" fill="#ccc"/>
    <path id="Path_415" data-name="Path 415" d="M623.03,279.767H582.975a1.418,1.418,0,1,1,0-2.836H623.03a1.418,1.418,0,0,1,0,2.836Z" transform="translate(-570.455 -232.059)" fill="#ccc"/>
    <path id="Path_416" data-name="Path 416" d="M623.03,293.572H582.975a1.418,1.418,0,1,1,0-2.836H623.03a1.418,1.418,0,0,1,0,2.836Z" transform="translate(-570.455 -238.422)" fill="#ccc"/>
    <path id="Path_417" data-name="Path 417" d="M623.03,307.385H582.975a1.418,1.418,0,1,1,0-2.836H623.03a1.418,1.418,0,0,1,0,2.836Z" transform="translate(-570.455 -244.79)" fill="#ccc"/>
    <path id="Path_418" data-name="Path 418" d="M623.03,321.191H582.975a1.418,1.418,0,1,1,0-2.836H623.03a1.418,1.418,0,0,1,0,2.836Z" transform="translate(-570.455 -251.154)" fill="#ccc"/>
  </g>
  <path id="Path_336" data-name="Path 336" d="M676.818,276.74a4.1,4.1,0,0,0-3.746-2.507H598.294a4.056,4.056,0,0,0-4.054,4.054v94.6a4.056,4.056,0,0,0,4.054,4.054h74.781a4.059,4.059,0,0,0,4.054-4.054v-94.6a3.981,3.981,0,0,0-.307-1.547Z" transform="translate(-396.565 -230.815)" fill="#e6e6e6"/>
  <path id="Path_478" data-name="Path 478" d="M674.842,375.586H604.958c-1.007,0-1.8-1.1-1.8-2.508V285.322c0-1.407.79-2.508,1.8-2.508h69.884c1.008,0,1.8,1.1,1.8,2.508v87.755C676.637,374.484,675.848,375.586,674.842,375.586Z" transform="translate(-400.678 -234.77)" fill="#fff"/>
  <path id="Path_336-2" data-name="Path 336" d="M676.818,276.74a4.1,4.1,0,0,0-3.746-2.507H598.294a4.056,4.056,0,0,0-4.054,4.054v94.6a4.056,4.056,0,0,0,4.054,4.054h74.781a4.059,4.059,0,0,0,4.054-4.054v-94.6a3.981,3.981,0,0,0-.307-1.547Zm-.593,96.148a3.157,3.157,0,0,1-3.153,3.153H598.294a3.154,3.154,0,0,1-3.153-3.152v-94.6a3.158,3.158,0,0,1,3.153-3.153h74.781A3.169,3.169,0,0,1,676,277.117a3.489,3.489,0,0,1,.119.356,3.125,3.125,0,0,1,.108.814Z" transform="translate(-396.565 -230.815)" fill="#3f3d56"/>
  <path id="Path_337" data-name="Path 337" d="M697.682,312.586H675.158a1.8,1.8,0,0,1,0-3.6h22.524a1.8,1.8,0,0,1,0,3.6Z" transform="translate(-433.034 -246.833)" fill="#3f3d56"/>
  <path id="Path_338" data-name="Path 338" d="M697.682,330.136H675.158a1.8,1.8,0,0,1,0-3.6h22.524a1.8,1.8,0,0,1,0,3.6Z" transform="translate(-433.034 -254.923)" fill="#3f3d56"/>
  <path id="Path_339" data-name="Path 339" d="M639.449,323.132H622.988a2.03,2.03,0,0,1-2.027-2.027V301.68a2.029,2.029,0,0,1,2.027-2.027h16.461a2.029,2.029,0,0,1,2.027,2.027v19.425a2.03,2.03,0,0,1-2.027,2.027Z" transform="translate(-408.882 -242.532)" fill="#6c63ff"/>
  <path id="Path_340" data-name="Path 340" d="M673.123,366.072h-50.9a1.8,1.8,0,0,1,0-3.6h50.9a1.8,1.8,0,0,1,0,3.6Z" transform="translate(-408.631 -271.488)" fill="#e6e6e6"/>
  <path id="Path_341" data-name="Path 341" d="M673.118,383.622h-50.9a1.8,1.8,0,1,1-.01-3.6h50.915a1.8,1.8,0,1,1,0,3.6Z" transform="translate(-408.627 -279.578)" fill="#e6e6e6"/>
  <path id="Path_342" data-name="Path 342" d="M673.123,401.172h-50.9a1.8,1.8,0,0,1,0-3.6h50.9a1.8,1.8,0,0,1,.01,3.6Z" transform="translate(-408.631 -287.667)" fill="#e6e6e6"/>
  <path id="Path_343" data-name="Path 343" d="M673.118,418.722h-50.9a1.8,1.8,0,1,1-.01-3.6h50.915a1.8,1.8,0,1,1,0,3.6Z" transform="translate(-408.627 -295.757)" fill="#e6e6e6"/>
  <path id="Path_344" data-name="Path 344" d="M673.118,436.272h-50.9a1.8,1.8,0,1,1-.01-3.6h50.915a1.8,1.8,0,1,1,0,3.6Z" transform="translate(-408.627 -303.847)" fill="#e6e6e6"/>
  <circle id="Ellipse_44" data-name="Ellipse 44" cx="29.108" cy="29.108" r="29.108" transform="translate(260.492 7.346)" fill="#6c63ff"/>
  <path id="Path_692" data-name="Path 692" d="M762.907,249.373V233.314h-8.27v16.059h-3.861l4,6.924,4,6.925,4-6.925,4-6.925Z" transform="translate(-468.722 -211.953)" fill="#fff"/>
</g>
</svg>`

const TASKHISTORYMODEL = "TaskHistoryModel";
const SQLFAILEDMODEL = "SqlFailedModel";
const INFRAMODEL = "InfraModel";
const CONFIGVALIDATIONMODEL = "ConfigValidationModel";

@Component({
  selector: 'app-view-esign',
  templateUrl: './view-esign.component.html',
  styleUrls: ['./view-esign.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class ViewEsignComponent implements OnInit {
  @ViewChild('stepper', { static: false }) mystepper!: MatStepper;
  ViewData: any;
  instanceEsignData: any = {
    title: 'Instance Details',
    records: [],
  };
  breadCrumbItems: any;
  instanceId: any;
  currentPageTrackStatus: any = 0;
  pageNumberTrackStatus: any = 1;
  pageSizeTrackStatus: any = 0;
  pageLengthTrackStatus: any = 0;
  currentPageConfigKeyValidation: any = 0;
  pageNumberConfigKeyValidation: any = 1;
  pageSizeConfigKeyValidation: any = 5;
  pageLengthConfigKeyValidation: any = 0;
  currentPageFailedESign: any = 0;
  pageNumberFailedESign: any = 1;
  pageSizeFailedESign: any = 5;
  pageLengthFailedESign: any = 0;
  currentPageSQLFailed: any = 0;
  pageNumberSQLFailed: any = 1;
  pageSizeSQLFailed: any = 5;
  pageLengthSQLFailed: any = 0;
  public instanceEnum = InstanceManagement;
  firstStepControl = new FormControl('');
  secondStepControl = new FormControl('');
  thirdStepControl = new FormControl('');
  fourthStepControl = new FormControl('');
  displayedColumnsTrackStatus: any = ['area', 'comments'];
  displayedColumnsValidationtatus: any = [
    'sendForSignature',
    'sender',
    'recipient',
    'result',
    'message',
  ];
  dataSourceValuesValidationStatus: any = {
    sendForSignature: {
      label: 'Send For Signature',
      data: 'sendForSignature',
    },
    sender: {
      label: 'Sender',
      data: 'sender',
    },
    recipient: {
      label: 'Recipient',
      data: 'recipient',
    },
    result: {
      label: 'result',
      data: 'result',
    },
    message: {
      label: 'message',
      data: 'message',
    },
  };
  signatureKey: any;
  recipientEmail: any = '';
  userEmail: any = '';
  signaturekeyData: any = [
    {
      id: 1,
      name: 'Sender',
    },
    {
      id: 2,
      name: 'AccountOwner',
    },
    {
      id: 3,
      name: 'Custom',
    },
  ];
  displayedColumnsConfigKeyValidation: any = [
    'area',
    'validation_results',
    'comments',
  ];
  displayedColumnsFailedESign: any = [
    'Id',
    'CTName',
    'EntityInstanceId',
    'AgreementCode',
    'EnvelopeId',
    'CreatedDate',
    'SignatoryTeamDetails',
  ];
  displayedColumnsSQLFailed: any = [
    'CTName',
    'EntityInstanceId',
    'AgreementCode',
    'EnvelopeId',
    'CreatedDate',
    'SPName',
    'ExceptionOrDesc',
    'StartTime',
    'EndTime',
    'SignatoryTeamDetails',
  ];
  dataSourceValuesSQLFailed: any = {
    CTName: {
      label: 'Contract Type Name',
      data: 'CTName',
    },
    EntityInstanceId: {
      label: 'Entity Instance Id',
      data: 'EntityInstanceId',
    },
    AgreementCode: { label: 'Agreement Code', data: 'AgreementCode' },
    EnvelopeId: { label: 'Envelop Id', data: 'EnvelopeId' },
    CreatedDate: { label: 'created date', data: 'CreatedDate' },
    SPName: { label: 'Stored Procedure Name', data: 'SPName' },
    ExceptionOrDesc: { label: 'Exception', data: 'ExceptionOrDesc' },
    StartTime: { label: 'Start Time', data: 'StartTime' },
    EndTime: { label: 'End Time', data: 'EndTime' },
    SignatoryTeamDetails: {
      label: 'signatory team detail',
      data: 'SignatoryTeamDetails',
    },
  };
  dataSourceValuesFailedESign: any = {
    Id: { label: 'Id', data: 'Id' },
    CTName: {
      label: 'Contract Type Name',
      data: 'CTName',
    },
    EntityInstanceId: {
      label: 'Entity InstanceID',
      data: 'EntityInstanceId',
    },
    AgreementCode: { label: 'Agreement Code', data: 'AgreementCode' },
    EnvelopeId: { label: 'Envelop Id', data: 'EnvelopeId' },
    CreatedDate: { label: 'created date', data: 'CreatedDate' },
    SignatoryTeamDetails: {
      label: 'signatory team detail',
      data: 'SignatoryTeamDetails',
    },
  };
  dataSourceValuesTrackStatus: any = {
    area: {
      label: 'Status Area',
      data: 'Area',
    },
    comments: {
      label: 'comments',
      data: 'Comments',
    },
  };
  dataSourceValuesConfigKeyvalidation: any = {
    area: {
      label: 'Status Area',
      data: 'Area',
    },
    validation_results: {
      label: 'Validation Result',
      data: 'Result',
    },
    comments: {
      label: 'comments',
      data: 'Comments',
    },
  };
  dataSourceTrackStatus: any = {
    records: [],
  };
  dataSourceConfigKeyValidation: any = {
    matchingCount: 0,
    records: [],
  };
  dataSourceFailedESign: any = {
    matchingCount: 0,
    records: [],
  };
  dataSourceSQLFailed: any = {
    matchingCount: 0,
    records: [],
  };
  dataSourceValidationStatus: any = {
    records: [],
  };
  tableTrackStatus: any;
  tableConfigKeyValidation: any;
  tableFailedESign: any;
  tableSQLFailed: any;
  providerName: any;
  configError: any = false;
  sortBy: any = '';
  failedEsignSort: any;
  eSignProviderData: any;
  sendForSignature: any;
  validationStatusTable: any;
  emptyFieldRegex = /<(.|\n)*?>/g;
  EsignDetails: any;
  SQLFailedSort: any;
  historyPeriod: any;
  esignFileName: any;

  constructor(
    private instanceService: InstanceService,
    private dialogService: DialogService,
    private router: Router,
    private location: Location,
    sanitizer: DomSanitizer,
    iconRegistry: MatIconRegistry,
    private permissionService: PermissionsService,
    private notificationService: NotificationService,
    private blobService: AzureBlobStorageService,    
    private loader: LoaderService,
    private cdref: ChangeDetectorRef
  ) {
    iconRegistry.addSvgIconLiteral(
      'export_icon',
      sanitizer.bypassSecurityTrustHtml(EXPORT_ICON)
    );
  }
  emailPattern: any = new RegExp(
    '^([\\w-]+(?:\\.[\\w-]+)*)@((?:[\\w-]+\\.)*\\w[\\w-]{0,66})\\.([A-Za-z]{2,6}(?:\\.[A-Za-z]{2,6})?)$'
  );

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: 'Home', path: 'resources' },
      { label: 'Instance Operations', path: 'instanceoperations/instance-requests' },
      { label: 'Instance Requests', path: 'instanceoperations/instance-requests' },
      { label: 'Instance Tools',path: 'instanceoperations/instance-requests/instance-tools-landing'},
      { label: 'Instance Tools History', path: 'instanceoperations/instance-requests/history' },
      { label: 'Esign Troubleshooting', path: 'history/view-esign' },
    ];
    this.instanceId = localStorage.getItem('instanceID');
    let localData:any = localStorage.getItem('esignProviderLocalData');
    if(localData){
      this.providerName = JSON.parse(localData).providerName;
      this.esignFileName = JSON.parse(localData).fileName;
    }
   
    this.instanceEsignData.records = [
      {
        label: this.instanceEnum.instance_tools_card_field_instance_name,
        data: '',
        id: 'instName',
      },
      {
        label: this.instanceEnum.instance_tools_card_field_version,
        data: '',
        id: 'version',
      },
      {
        label:
          this.instanceEnum
            .instance_tools_card_field_multi_esign_configuration_enabled,
        data: '',
        id: 'config',
        info: this.instanceEnum
          .instance_tools_card_field_multi_esign_configuration_enabled_info,
      },
      {
        label: this.instanceEnum.instance_tools_card_field_send_as_signature,
        data: '',
        id: 'signUsed',
        info: this.instanceEnum
          .instance_tools_card_field_send_as_signature_info,
      },
      {
        label: this.instanceEnum.instance_tools_card_field_logging_verbosity,
        data: '',
        id: 'verbosity',
        info: this.instanceEnum
          .instance_tools_card_field_logging_verbosity_info,
      },
    ];
    this.downloadLogs();
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  downloadLogs() {
    let element = {
      fileName:this.esignFileName
    };
    try {
      this.instanceService
        .getBlobDownloadConnection(this.instanceId, element.fileName)
        .subscribe((resp: any) => {
          this.loader.show();
          if (resp != null && resp != undefined) {
            this.blobService.downloadImageExt(
              resp.sasUrl,
              resp.container,
              resp.blob,
              async (response: any) => {
                if (response._response.status == 200) {
                  response.blobBody.then(async (onres: any) => {
                    const blobContents = await this.blobToString(onres);
                    const data = JSON.parse(blobContents);
                    this.historyPeriod = data?.NumberOfDaysHistory;
                    this.ViewData = data?.ProviderDetail;
                    this.instanceandProviderDetail(data?.ProviderDetail);
                    this.esignDetails(data?.EsignDetail);
                    this.loader.hide();
                  });
                }
              }
            );
          } else{
            this.loader.hide();
          }
        });
    } catch (err) {
      console.log(err);
    }
  }

  blobToString(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsText(blob);
    });
  }

  instanceandProviderDetail(providerData: any) {
    try {
      let content = providerData;
      this.instanceEsignData.records = [
        {
          label: this.instanceEnum.instance_tools_card_field_instance_name,
          data: content?.InstanceName,
          id: 'instName',
        },
        {
          label: this.instanceEnum.instance_tools_card_field_version,
          data: content?.ICIVersion,
          id: 'version',
        },
        {
          label:
            this.instanceEnum
              .instance_tools_card_field_multi_esign_configuration_enabled,
          data: content?.IsMultiEsignKeyUsed,
          id: 'config',
          info: this.instanceEnum
            .instance_tools_card_field_multi_esign_configuration_enabled_info,
        },
        {
          label: this.instanceEnum.instance_tools_card_field_send_as_signature,
          data: content?.SendForSignature,
          id: 'signUsed',
          info: this.instanceEnum
            .instance_tools_card_field_send_as_signature_info,
        },
        {
          label: this.instanceEnum.instance_tools_card_field_logging_verbosity,
          data: content?.LoggingVerbosity,
          id: 'verbosity',
          info: this.instanceEnum
            .instance_tools_card_field_logging_verbosity_info,
        },
      ];
      content?.ProviderDetails?.EsignProviders?.forEach((data: any) => {
        if (data.Name == this.providerName) {
          this.eSignProviderData = data;
        }
      });
    } catch (error) {}
  }


  onNext() {
    this.mystepper.next();
  }

  onPrevious() {
    this.mystepper.previous();
  }

  onCancel() {
    this.location.back();
  }

  signatureKeyChange(event: any) {
    this.signaturekeyData?.forEach((data: any) => {
      if (data.id == event.value) {
        this.sendForSignature = data.name;
      }
    });
  }

  sendEnvelope() {
    try {
      let senderEmail = this.permissionService.getuserEmail();
      let paramData = {
        eSignProviderData: this.eSignProviderData,
        soboEmailAddress:
          this.sendForSignature == 'Custom' ? this.userEmail : senderEmail,
        recipient: this.recipientEmail,
        providerName: this.providerName,
        sendForSignatureAs:
          this.sendForSignature == 'Custom' ? 'Sender' : this.sendForSignature,
        iciVersion: this.ViewData.ICIVersion,
      };
      this.dialogService
        .confirmDialog({
          title: this.instanceEnum.instance_view_esign_send_envelope_requested,
          module: 'esign_troubleshooting',
          message:
            this.instanceEnum.instance_view_esign_send_envelope_popuptext +
            '<b> Confirm.</b>',
          confirmText: 'Confirm',
          cancelText: 'Cancel',
        })
        .subscribe((res: any) => {
          if (res) {
            this.instanceService
              .sendEnvelope(paramData)
              .subscribe((resp: any) => {
                let result = resp.body;
                if (result.isSuccess) {
                  this.tableTrackStatus = {
                    reference: 'tableTrackStatus',
                    displayedColumns: this.displayedColumnsTrackStatus,
                    dataSourceValues: this.dataSourceValuesTrackStatus,
                    dataSource: this.dataSourceTrackStatus,
                  };
                  this.dataSourceValidationStatus.records = [result.data];
                  this.validationStatusTable = {
                    reference: 'validationStatus',
                    displayedColumns: this.displayedColumnsValidationtatus,
                    dataSourceValues: this.dataSourceValuesValidationStatus,
                    dataSource: this.dataSourceValidationStatus,
                  };
                } else {
                  this.notificationService.showNotification(
                    result.responseMessage,
                    'warning',
                    'Send Envelope'
                  );
                }
              });
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  validateSendEnvelopeData() {
    if (this.signatureKey == 3 && this.userEmail && this.recipientEmail) {
      return !(
        this.validateEmail(this.userEmail) &&
        this.validateEmail(this.recipientEmail)
      );
    } else if (this.signatureKey == 1 || this.signatureKey == 2) {
      return !this.validateEmail(this.recipientEmail);
    } else {
      return true;
    }
  }

  validateEmail(email: any) {
    let result = email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    return result == null ? false : true;
  }

  validateUserEmail(email: any) {
    if (email?.replace(this.emptyFieldRegex, '').trim().length !== 0) {
      return !this.validateEmail(email);
    } else {
      return false;
    }
  }

  exportToExcel() {
    try {
      this.instanceService
        .esignExportExcel(
          this.instanceId,
          this.providerName,
          this.ViewData.ICIVersion
        )
        .subscribe((resp: any) => {
        });
      this.location.back();
    } catch (error) {
      console.log(error);
    }
  }

  esignDetails(eSignData: any) {
    try {
      this.EsignDetails = eSignData;
      this.getInfraDetail();
      this.getConfigKeyValidationDetails(0, 5);
      this.getTrackHistoryDetails(this.currentPageFailedESign, this.pageSizeFailedESign, this.failedEsignSort);
      this.getSPFailedDetails(this.currentPageSQLFailed, this.pageSizeSQLFailed, this.SQLFailedSort);
    } catch (error) {
      console.log(error);
    }
  }

  pageChanged(data: any) {
    let event = data.event;
    if (data.reference == 'tableConfigKeyValidation') {
      this.pageSizeConfigKeyValidation = event.pageSize;
      this.currentPageConfigKeyValidation = event.pageIndex;
      this.pageNumberConfigKeyValidation = event.pageIndex + 1;
      this.getConfigKeyValidationDetails(
        this.currentPageConfigKeyValidation,
        this.pageSizeConfigKeyValidation
      );
    } else if (data.reference == 'tableFailedESign') {
      this.pageSizeFailedESign = event.pageSize;
      this.currentPageFailedESign = event.pageIndex;
      this.pageNumberFailedESign = event.pageIndex + 1;
      this.getTrackHistoryDetails(
        this.currentPageFailedESign,
        this.pageSizeFailedESign,
        this.failedEsignSort
      );
    } else if (data.reference == 'tableSQLFailed') {
      this.pageSizeSQLFailed = event.pageSize;
      this.currentPageSQLFailed = event.pageIndex;
      this.pageNumberSQLFailed = event.pageIndex + 1;
      this.getSPFailedDetails(
        this.currentPageSQLFailed,
        this.pageSizeSQLFailed,
        this.SQLFailedSort
      );
    }
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(data: any) {
    let sortState: Sort = data?.event;
    if (sortState.direction == 'desc') {
      this.sortBy = sortState.active + '_' + sortState.direction;
    } else {
      this.sortBy = sortState.active;
    }
    if (data.reference == 'tableFailedESign') {
      this.failedEsignSort = this.sortBy;
      this.getTrackHistoryDetails(
        this.currentPageFailedESign,
        this.pageSizeFailedESign,
        this.failedEsignSort
      );
    } else if (data.reference == 'tableSQLFailed') {
      this.SQLFailedSort = this.sortBy;
      this.getSPFailedDetails(
        this.currentPageSQLFailed,
        this.pageSizeSQLFailed,
        this.SQLFailedSort
      );
    }
  }

  getInfraDetail() {
    this.dataSourceTrackStatus.records = this.EsignDetails?.InfraDetail;
    this.tableTrackStatus = {
      reference: 'tableTrackStatus',
      displayedColumns: this.displayedColumnsTrackStatus,
      dataSourceValues: this.dataSourceValuesTrackStatus,
      dataSource: this.dataSourceTrackStatus,
    };
    this.configError = this.EsignDetails?.IsValidateModelResultFail;
  }

  getConfigKeyValidationDetails(pageIndex: any, pageSize: any) {
    let startIndex = pageIndex * pageSize;
    let endIndex = startIndex + pageSize;
    this.dataSourceConfigKeyValidation = {
      matchingCount: this.EsignDetails?.ConfigurationValidationDetail?.length,
      records: this.EsignDetails?.ConfigurationValidationDetail.slice(
        startIndex,
        endIndex
      ),
    };

    this.tableConfigKeyValidation = {
      reference: 'tableConfigKeyValidation',
      displayedColumns: this.displayedColumnsConfigKeyValidation,
      dataSourceValues: this.dataSourceValuesConfigKeyvalidation,
      dataSource: this.dataSourceConfigKeyValidation,
      pagination: {
        required: true,
        currentPage: this.currentPageConfigKeyValidation,
        pageLength: this.pageLengthConfigKeyValidation,
        pageSize: this.pageSizeConfigKeyValidation,
        pageNumber: this.pageNumberConfigKeyValidation,
      },
    };
    this.configError = this.EsignDetails?.IsValidateModelResultFail;
  }

  getTrackHistoryDetails(pageIndex: any, pageSize: any, sortBy: any) {
    let startIndex = pageIndex * pageSize;
    let endIndex = startIndex + pageSize;
    if (sortBy) {
      this.sortData(sortBy, TASKHISTORYMODEL);
    }
    this.dataSourceFailedESign = {
      matchingCount: this.EsignDetails?.TaskHistory?.length,
      records: this.EsignDetails?.TaskHistory.slice(startIndex, endIndex),
    };
    this.tableFailedESign = {
      reference: 'tableFailedESign',
      displayedColumns: this.displayedColumnsFailedESign,
      dataSourceValues: this.dataSourceValuesFailedESign,
      dataSource: this.dataSourceFailedESign,
      pagination: {
        required: true,
        currentPage: this.currentPageFailedESign,
        pageLength: this.pageLengthFailedESign,
      },
    };
  }

  getSPFailedDetails(pageIndex: any, pageSize: any, sortBy: string) {
    let startIndex = pageIndex * pageSize;
    let endIndex = startIndex + pageSize;
    if (sortBy) {
      this.sortData(sortBy, SQLFAILEDMODEL);
    }
    this.dataSourceSQLFailed = {
      matchingCount: this.EsignDetails?.SpFailed.length,
      records: this.EsignDetails?.SpFailed.slice(startIndex, endIndex),
    };
    this.tableSQLFailed = {
      reference: 'tableSQLFailed',
      displayedColumns: this.displayedColumnsSQLFailed,
      dataSourceValues: this.dataSourceValuesSQLFailed,
      dataSource: this.dataSourceSQLFailed,
      pagination: {
        required: true,
        currentPage: this.currentPageSQLFailed,
        pageLength: this.pageLengthSQLFailed,
      },
    };
  }

  sortData(sortBy:any, dataTypeModel:any){
    let typeModel:any;
    if(dataTypeModel== SQLFAILEDMODEL){
      typeModel = 'SpFailed';
    } else if(dataTypeModel== TASKHISTORYMODEL){
      typeModel ='TaskHistory';
    }
    if(sortBy.includes('CreatedDate') || sortBy.includes('StartDate') || sortBy.includes('EndDate')){
      if (sortBy.includes('_desc')) {
        let sortData = sortBy.split('_')[0];
        this.EsignDetails[typeModel]?.sort((a: any, b: any) => {
          return new Date(b[sortData]).getTime() - (new Date(a[sortData]).getTime());
        });
      } else {
        let sortData = sortBy;
        this.EsignDetails[typeModel]?.sort((a: any, b: any) => {
          return new Date(a[sortData]).getTime() -(new Date(b[sortData]).getTime());
        });
      }
    } else{
      if (sortBy.includes('_desc')) {
        let sortData = sortBy.split('_')[0];
        this.EsignDetails[typeModel]?.sort((a: any, b: any) => {
          return b[sortData].localeCompare(a[sortData]);
        });
      } else {
        let sortData = sortBy;
        this.EsignDetails[typeModel]?.sort((a: any, b: any) => {
          return a[sortData].localeCompare(b[sortData]);
        });
      }
    }
    
  }
}
