import { LiveAnnouncer } from '@angular/cdk/a11y';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AzureBlobStorageService } from 'src/app/core/services/azure-blob-storage/azure-blob-storage.service';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { InstanceService } from 'src/app/core/services/instance-management/instance.service';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';
import { Deployment } from 'src/app/shared/enum/deployment';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { USER_CATEGORY_ID } from 'src/app/shared/constants/idn-constants';

const HELP_ICON = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="49" height="48" viewBox="0 0 49 48" >
<defs>
  <filter id="Ellipse_40" x="0" y="0" width="79" height="78" filterUnits="userSpaceOnUse">
    <feOffset dy="8" input="SourceAlpha"/>
    <feGaussianBlur stdDeviation="5" result="blur"/>
    <feFlood flood-opacity="0"/>
    <feComposite operator="in" in2="blur"/>
    <feComposite in="SourceGraphic"/>
  </filter>
</defs>
<g id="Component_308_1" data-name="Component 308 - 1" transform="translate(0 0)">
  <g transform="matrix(1, 0, 0, 1, -15, -7)" filter="url(#Ellipse_40)">
    <ellipse id="Ellipse_40-2" data-name="Ellipse 40" cx="24.5" cy="24" rx="24.5" ry="24" transform="translate(15 7)" />
  </g>
  <path id="help_FILL0_wght400_GRAD0_opsz48" d="M92.576,195.705a1.169,1.169,0,1,0-.84-.342A1.143,1.143,0,0,0,92.576,195.705Zm-1.09-4.545h1.837a5.1,5.1,0,0,1,.2-1.479,3.665,3.665,0,0,1,1.261-1.541,5.447,5.447,0,0,0,1.37-1.588,3.655,3.655,0,0,0,.4-1.712,3.437,3.437,0,0,0-1.074-2.646,4.022,4.022,0,0,0-2.848-1,4.812,4.812,0,0,0-2.693.763,4.356,4.356,0,0,0-1.7,2.1l1.65.623a2.9,2.9,0,0,1,1.027-1.354,2.738,2.738,0,0,1,1.619-.482,2.5,2.5,0,0,1,1.712.576,1.881,1.881,0,0,1,.654,1.479,2.289,2.289,0,0,1-.4,1.292,6.2,6.2,0,0,1-1.183,1.261,6.331,6.331,0,0,0-1.385,1.6A4.238,4.238,0,0,0,91.487,191.16Zm.965,9.744a12.048,12.048,0,0,1-4.825-.981,12.545,12.545,0,0,1-6.646-6.646,12.438,12.438,0,0,1,0-9.681,12.444,12.444,0,0,1,2.677-3.953,12.764,12.764,0,0,1,3.969-2.662,12.438,12.438,0,0,1,9.681,0,12.432,12.432,0,0,1,6.615,6.615,12.438,12.438,0,0,1,0,9.681,12.764,12.764,0,0,1-2.662,3.969,12.444,12.444,0,0,1-3.953,2.677A12.125,12.125,0,0,1,92.452,200.9Zm0-1.868a10.575,10.575,0,1,0-7.487-3.1A10.185,10.185,0,0,0,92.452,199.036ZM92.452,188.452Z" transform="translate(-66.869 -164.53)" fill="#fff"/>
</g>
</svg>`

const DATE_TIME_FORMAT ='YYYY-MM-ddTHH:mm:ss';
@Component({
  selector: 'app-deployment',
  templateUrl: './deployment.component.html',
  styleUrls: ['./deployment.component.scss'],
})
export class DeploymentComponent implements OnInit {
  public deploymentEnum = Deployment;
  isapprovalDrawer: boolean = false;
  isDeploymentDrawer: boolean = false;
  isTrackStatusDrawer: boolean = false;
  displayedColumns: string[] = [
    'customerName',
    'cnameToEnter',
    'requiredBy',
    'deploymentType',
    'status',
    'action',
  ];
  @ViewChild('search_input') inputName: any;
  @ViewChild('deploymentStatus') deploymentStatus!: MatSelect;
  selectAll = 'selectAll';
  @ViewChild(MatPaginator, { static: true })
  paginator!: MatPaginator;
  extensionType: any = [];
  extensionName: any = [];
  solutionPackages: any = [];
  dataSource: any;
  breadCrumbItems: any;
  ViewData: any = [];
  currentInstanceStatusID: any;
  StatusClasses: any = [];
  sort: any;
  @ViewChild('empTbSort') empTbSort = new MatSort();
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  presentPage = 1;
  deploymentStatusChanges: any = [];
  isViewDeployment: boolean = false;
  pageNumber = 1;
  dateError: boolean = false;
  todayDate: any = new Date();
  sortBy: string = '';
  filter: string = '';
  status: string = '';
  pageLength: any = 0;
  noContent: Boolean = false;
  maxDate: any = new Date();
  isRejectDeployment: boolean = false;
  isApproveDeployment: boolean = false;
  statusList:any = [{name:'Deployment Requested', id:'1'},
   {name:'Deployment In Progress', id:'2'},
   {name:'Deployment Request Rejected', id:'3'},
   {name:'Deployment Completed', id:'4'},
   {name:'Deployment Failed', id:'5'},
   {name:'Deployment Request Pending', id:'9'}]
  userCategory: any;
  isCustomerUser: any;
  constructor(
    private titleService: Title,
    private router: Router,
    private _liveAnnouncer: LiveAnnouncer,
    private blobService: AzureBlobStorageService,
    private instanceService: InstanceService,
    private permissionService: PermissionsService,
    private datePipe: DatePipe,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIconLiteral(
      'HELP_ICON',
      sanitizer.bypassSecurityTrustHtml(HELP_ICON)
    );
  }

  deploymetDates: FormGroup = new FormGroup({
    start: new FormControl(''),
    end: new FormControl(''),
  });

  ngOnInit(): void {
    localStorage.removeItem('deploymentId');
    this.isViewDeployment =
      this.permissionService.getPermission('ViewDeployment');
    this.titleService.setTitle('Deployment Requests | IDN');
    this.isApproveDeployment = this.permissionService.getPermission('ApproveDeployment');
    this.isRejectDeployment = this.permissionService.getPermission('RejectDeployment');
    this.statusList = this.statusList?.sort((a:any, b:any) =>{ return a.name.localeCompare(b.name);});
    this.userCategory=this.permissionService.getUsercategoryId();
    if(this.userCategory == USER_CATEGORY_ID.Customer){
      this.isCustomerUser = true;
      this.displayedColumns = [        
        'cnameToEnter',
        'requiredBy',
        'deploymentType',
        'status',
        'action',
      ];
    }
    else{
      this.isCustomerUser  = false;
    }
    
    this.breadCrumbItems = [
      { label: 'Home', path: 'resources' },
      { label: 'Instance Operations', path: 'instanceoperations/deployment' },
      { label: 'Deployment Requests', path: 'instanceoperations/deployment' },
      { label: 'List', path: 'instanceoperations/deployment' },
    ];
  }

  ngAfterViewInit() {
    this.getAllDeployements();
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    if (sortState.direction == 'desc') {
      this.sortBy = sortState.active + '_' + sortState.direction;
    } else {
      this.sortBy = sortState.active;
    }
    this.getAllDeployements();
  }

  public doFilter = () => {
    let filters = localStorage.getItem('FilterRecordDep');
    if (filters != null) {
      let Json = {
        search: this.inputName.nativeElement.value,
        status:
          this.deploymentStatus.value == undefined ||
          this.deploymentStatus.value == this.selectAll
            ? ''
            : this.deploymentStatus.value,
        pagesize: this.pageSize,
        pageNumber: this.pageNumber,
        currentPage: this.currentPage,
        startDate:
          this.deploymetDates.controls['start'].value != ''
            ? this.formatDate(
                new Date(this.deploymetDates.controls['start'].value)
              )
            : '',
        endDate:
          this.deploymetDates.controls['end'].value != ''
            ? this.formatDate(
                new Date(this.deploymetDates.controls['end'].value)
              )
            : '',
      };
      localStorage.setItem('FilterRecordDep', JSON.stringify(Json));
    } else {
      this.filter = this.inputName.nativeElement.value;
      this.status =
        this.deploymentStatus.value == undefined ||
        this.deploymentStatus.value == this.selectAll
          ? ''
          : this.deploymentStatus.value;
    }

    this.getAllDeployements();
  };

  ClearInput() {
    this.inputName.nativeElement.value = '';
    this.doFilter();
  }

  clearFilter() {
    localStorage.removeItem('FilterRecordDep');
    this.inputName.nativeElement.value = '';
    this.deploymentStatus.value = '';
    this.pageNumber = 1;
    this.pageSize = 10;
    this.currentPage = 0;
    this.deploymetDates.reset();
    this.doFilter();
    this.deploymetDates.reset();
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.pageNumber = event.pageIndex + 1;
    let filters = localStorage.getItem('FilterRecordDep');
    if (filters != null) {
      let Json = {
        search: this.inputName.nativeElement.value,
        status:
          this.deploymentStatus.value == undefined ||
          this.deploymentStatus.value == this.selectAll
            ? ''
            : this.deploymentStatus.value,
        pagesize: this.pageSize,
        pageNumber: this.pageNumber,
        currentPage: this.currentPage,
        startDate:
          this.deploymetDates.controls['start'].value != '' &&
          this.deploymetDates.controls['start'].value != null
            ? this.formatDate(
                new Date(this.deploymetDates.controls['start'].value)
              )
            : '',
        endDate:
          this.deploymetDates.controls['end'].value != '' &&
          this.deploymetDates.controls['end'].value != null
            ? this.formatDate(
                new Date(this.deploymetDates.controls['end'].value)
              )
            : '',
      };
      localStorage.setItem('FilterRecordDep', JSON.stringify(Json));
    }
    this.getAllDeployements();
  }

  //get list of deployments
  getAllDeployements() {
    try {
      let filterResult: any = [];
      let filterValue: any = localStorage.getItem('FilterRecordDep');
      let startDeploymentDate: any;
      let endDeploymentDate: any;
      if (
        filterValue != undefined &&
        filterValue != null &&
        filterValue != ''
      ) {
        this.filter = JSON.parse(filterValue).search;
        this.inputName.nativeElement.value = this.filter;
        this.status =
          JSON.parse(filterValue).status != undefined &&
          JSON.parse(filterValue).status != this.selectAll
            ? JSON.parse(filterValue).status
            : '';
        this.deploymentStatus.value = this.status;
        this.pageSize = JSON.parse(filterValue).pagesize;
        this.pageNumber = JSON.parse(filterValue).pageNumber;
        this.currentPage = JSON.parse(filterValue).currentPage;
        startDeploymentDate =
          JSON.parse(filterValue).startDate != '' &&
          JSON.parse(filterValue).startDate != null
            ? this.formatDate(new Date(JSON.parse(filterValue).startDate))
            : '';
        endDeploymentDate =
          JSON.parse(filterValue).endDate != '' &&
          JSON.parse(filterValue).endDate != null
            ? this.formatDate(new Date(JSON.parse(filterValue).endDate))
            : '';
        this.deploymetDates.setValue({
          start: JSON.parse(filterValue).startDate,
          end: JSON.parse(filterValue).endDate,
        });
      } else {
        startDeploymentDate =
          this.deploymetDates.controls['start'].value != '' &&
          this.deploymetDates.controls['start'].value != null
            ? this.formatDate(
                new Date(this.deploymetDates.controls['start'].value)
              )
            : '';
        endDeploymentDate =
          this.deploymetDates.controls['end'].value != '' &&
          this.deploymetDates.controls['end'].value != null
            ? this.formatDate(
                new Date(this.deploymetDates.controls['end'].value)
              )
            : '';
      }
      this.instanceService
        .getAllDeployements(
          this.filter,
          this.sortBy,
          this.pageNumber,
          this.pageSize,
          this.status,
          startDeploymentDate,
          endDeploymentDate
        )
        .subscribe((resp: any) => {
          if (resp != null && resp != undefined) {
            this.noContent = false;
            filterResult = resp;
          } else {
            filterResult = [];
            this.noContent = true;
          }
          this.dataSource = new MatTableDataSource(filterResult.records);
          this.paginator.pageIndex = this.currentPage;
          this.paginator.length = filterResult.matchingCount;
        });
    } catch (error) {
      console.log(error);
    }
  }

  navigateItem(navLink: any, deploymentId: any): void {
    let Json = {
      search: this.filter,
      status: this.status,
      pagesize: this.pageSize,
      pageNumber: this.pageNumber,
      currentPage: this.currentPage,
      startDate:
        this.deploymetDates.controls['start'].value != '' &&
        this.deploymetDates.controls['start'].value != null
          ? this.formatDate(
              new Date(this.deploymetDates.controls['start'].value)
            )
          : '',
      endDate:
        this.deploymetDates.controls['end'].value != '' &&
        this.deploymetDates.controls['end'].value != null
          ? this.formatDate(new Date(this.deploymetDates.controls['end'].value))
          : '',
    };
    localStorage.setItem('FilterRecordDep', JSON.stringify(Json));
    if (navLink == 'home') {
      navLink = 'home';
    } else {
      navLink = 'home/instanceoperations/' + navLink;
    }
    localStorage.setItem('deploymentId', deploymentId);
    this.router.navigate([navLink]);
  }

  toggleTrackStatus(statusID: any, deploymentStatusChanges: any) {
    this.currentInstanceStatusID = statusID;
    this.deploymentStatusChanges = deploymentStatusChanges.filter(
      (obj: any, pos: any, arr: any) => {
        return (
          arr.map((mapObj: any) => mapObj.statusId).indexOf(obj.statusId) == pos
        );
      }
    );
    this.deploymentStatusChanges = this.deploymentStatusChanges.map(
      (status: any) => {
        if (
          status.statusId == 5 ||
          status.statusId == 3 ||
          status.statusId == 7
        ) {
          return { icon: 'dangerous', ...status };
        } else {
          return { icon: 'check_circle', ...status };
        }
      }
    );
    this.deploymentStatusChanges.forEach((status: any, index: any) => {
      if (this.deploymentStatusChanges.length == index + 1) {
        if (status.statusId == 9) {
          this.StatusClasses.push('completed');
          this.deploymentStatusChanges = [
            ...this.deploymentStatusChanges,
            {
              icon: 'check_circle',
              statusDisplayName:
                this.deploymentEnum.deployment_status_deployment_requested
            },
            {
              icon: 'check_circle',
              statusDisplayName:
                this.deploymentEnum.deployment_status_package_bundling_started,
            },
            {
              icon: 'check_circle',
              statusDisplayName:
                this.deploymentEnum
                  .deployment_status_package_bundling_completed,
            },
            {
              icon: 'check_circle',
              statusDisplayName:
                this.deploymentEnum.deployment_status_deployment_in_progress,
            },
            {
              icon: 'check_circle',
              statusDisplayName:
                this.deploymentEnum.deployment_status_deployment_completed,
            },
          ];
        } else if (status.statusId == 1) {
          this.StatusClasses.push('completed');
          this.deploymentStatusChanges = [
            ...this.deploymentStatusChanges,
            {
              icon: 'check_circle',
              statusDisplayName:
                this.deploymentEnum.deployment_status_package_bundling_started,
            },
            {
              icon: 'check_circle',
              statusDisplayName:
                this.deploymentEnum
                  .deployment_status_package_bundling_completed,
            },
            {
              icon: 'check_circle',
              statusDisplayName:
                this.deploymentEnum.deployment_status_deployment_in_progress,
            },
            {
              icon: 'check_circle',
              statusDisplayName:
                this.deploymentEnum.deployment_status_deployment_completed,
            },
          ];
        } else if (status.statusId == 6) {
          this.StatusClasses.push('completed');
          this.deploymentStatusChanges = [
            ...this.deploymentStatusChanges,
            {
              icon: 'check_circle',
              statusDisplayName:
                this.deploymentEnum
                  .deployment_status_package_bundling_completed,
            },
            {
              icon: 'check_circle',
              statusDisplayName:
                this.deploymentEnum.deployment_status_deployment_in_progress,
            },
            {
              icon: 'check_circle',
              statusDisplayName:
                this.deploymentEnum.deployment_status_deployment_completed,
            },
          ];
        } else if (status.statusId == 8) {
          this.StatusClasses.push('completed');
          this.deploymentStatusChanges = [
            ...this.deploymentStatusChanges,
            {
              icon: 'check_circle',
              statusDisplayName:
                this.deploymentEnum.deployment_status_deployment_in_progress,
            },
            {
              icon: 'check_circle',
              statusDisplayName:
                this.deploymentEnum.deployment_status_deployment_completed,
            },
          ];
        } else if (status.statusId == 2) {
          this.StatusClasses.push('completed');
          this.deploymentStatusChanges = [
            ...this.deploymentStatusChanges,
            {
              icon: 'check_circle',
              statusDisplayName:
                this.deploymentEnum.deployment_status_deployment_completed,
            },
          ];
        }
        if (
          status.statusId == 5 ||
          status.statusId == 3 ||
          status.statusId == 7
        ) {
          this.StatusClasses.push('rejected');
        } else if (status.statusId == 4) {
          this.StatusClasses.push('completed');
        } else {
          this.StatusClasses.push('progress');
        }
      } else if (
        status.statusId == 1 ||
        status.statusId == 4 ||
        status.statusId == 2 ||
        status.statusId == 6 ||
        status.statusId == 8 ||
        status.statusId == 9
      ) {
        this.StatusClasses.push('completed');
      } else if (
        status.statusId == 3 ||
        status.statusId == 5 ||
        status.statusId == 7
      ) {
        this.StatusClasses.push('rejected');
      } else {
        this.StatusClasses.push('noClass');
      }
    });
    this.isTrackStatusDrawer = !this.isTrackStatusDrawer;
  }

  closeTrackStatus() {
    this.isTrackStatusDrawer = !this.isTrackStatusDrawer;
    this.StatusClasses = [];
  }

  formatDate(date: any) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  // Download Instance Provisioning Log
  downloadDeploymentLog = async (id: number, deployment: string) => {
    const datepipe: DatePipe = new DatePipe('en-US');
    try {
      let FileSaver = require('file-saver');
      let dateTime = new Date();
      let fileNameTimeStamp = datepipe.transform(
        dateTime,
        'dd_MMMM_YYYY_HH_mm_ss'
      );
      let fileName =
        'Deployment_' + deployment + '_' + id + '_log_' + fileNameTimeStamp;
      this.instanceService
        .GetDeploymentLogDownloadConnection(id)
        .subscribe((resp: any) => {
          if (resp != null && resp != undefined) {
            this.blobService.downloadImageExt(
              resp.sasUrl,
              resp.container,
              resp.blob,
              (response: any) => {
                if (
                  response._response != undefined &&
                  response._response.status == 200
                ) {
                  response.blobBody.then((onresponse: any) => {
                    FileSaver.saveAs(onresponse, fileName);
                    this.notificationService.showNotification(
                      this.deploymentEnum
                        .deployment_notify_deployment_log_success_body,
                      'success',
                      this.deploymentEnum
                        .deployment_notify_deployment_log_success_heading
                    );
                  });
                } else {
                  if (response.status == 404) {
                    this.notificationService.showNotification(
                      this.deploymentEnum
                        .deployment_notify_deployment_log_failed_404_body,
                      'warning',
                      this.deploymentEnum
                        .deployment_notify_deployment_log_failed_heading
                    );
                  } else {
                    this.notificationService.showNotification(
                      this.deploymentEnum
                        .deployment_notify_deployment_log_failed_body,
                      'warning',
                      this.deploymentEnum
                        .deployment_notify_deployment_log_failed_heading
                    );
                  }
                }
              }
            );
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  refresh(event:any) {
    event.preventDefault();
    event.stopPropagation();
    this.getAllDeployements();
  }

  redirectToHelp() {
    this.dialogService
      .confirmDialog({
        title: this.deploymentEnum.redirect_dialog_heading,
        module: 'resources',
        message: this.deploymentEnum.redirect_dialog_body,
        confirmText: this.deploymentEnum.redirect_dialog_primary_btn,
        cancelText: this.deploymentEnum.redirect_dialog_secondary_btn,
      })
      .subscribe((res: any) => {
        if (res) {
          window.open(
            'https://developer-help.icertis.com/docs/managing-extensions-and-deploying-packages/#deploy-package',
            '_blank'
          );
        }
      });
  }

  approveReject(deploymentId: any, deploymentDate: any) {
    this.dialogService
      .confirmDialogComments({
        title: 'Confirm',
        module: 'approve-deployment',
        message: deploymentDate,
        confirmText: 'Confirm',
        cancelText: 'Cancel',
      })
      .subscribe((res: any) => {
        if (res) {
          let data = {
            id:deploymentId,
            requestedDate: this.transformDate(res.requiredBy),
            comment: res.comments,
            reviewerId: this.permissionService.getUniqueUserid()
          }
          if(res.event == this.deploymentEnum.deployment_constant_approve){
            this.instanceService.approveDeploymentRequest(data).subscribe((res)=>{
              if (res.status == 200) {
                this.getAllDeployements();
                if(this.isApproveDeployment || this.isRejectDeployment){
                  this.instanceService.getUpdatedCount(true);
                }
                this.notificationService.showNotification(
                  this.deploymentEnum.deployment_notify_deployment_request_approved_body,
                  'success',
                  this.deploymentEnum.deployment_notify_deployment_request_approved_heading
                );                
              } else {
                this.notificationService.showNotification(
                  this.deploymentEnum.deployment_notify_deployment_request_approval_failed_body,
                  'error',
                  this.deploymentEnum.deployment_notify_deployment_request_approval_failed_heading,
                );
              }
            })
          } else if(res.event == this.deploymentEnum.deployment_constant_reject){
            this.instanceService.rejectDeploymentRequest(data).subscribe((res)=>{
              if (res.status == 200) {
                this.getAllDeployements();
                this.notificationService.showNotification(
                  this.deploymentEnum.deployment_notify_deployment_request_rejected_body,
                  'success',
                  this.deploymentEnum.deployment_notify_deployment_request_rejected_heading
                );
              } else {
                this.notificationService.showNotification(
                  this.deploymentEnum.deployment_notify_deployment_request_rejection_failed_body,
                  'error',
                  this.deploymentEnum.deployment_notify_deployment_request_rejection_failed_heading
                );
              }
            })
          }
          
        }
      });
  }

   //datetime Validation
   transformDate(selectedDate: any) {
    return this.datePipe.transform((selectedDate.toISOString()), DATE_TIME_FORMAT);
  }
}
