import { Component, OnInit } from '@angular/core';
import { AzureBlobStorageService } from 'src/app/core/services/azure-blob-storage/azure-blob-storage.service';
import { InstanceService } from 'src/app/core/services/instance-management/instance.service';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';
import { InstanceManagement } from 'src/app/shared/enum/instance-management';
import * as XLSX  from 'xlsx';
import { Location } from '@angular/common';

@Component({
  selector: 'app-view-dblogs',
  templateUrl: './view-dblogs.component.html',
  styleUrls: ['./view-dblogs.component.scss'],
})
export class ViewDblogsComponent implements OnInit {
  breadCrumbItems: any;
  instanceData: any = {
    title: 'Instance Details',
    records: [],
  };
  instanceId: any;
  ViewData: any = [];
  public instanceEnum = InstanceManagement;
  dbLogFile: any;
  tableDatabaseLogs:any;
  displayedColumnsDatabaseLogs: any = [];
  dataSourceValuesDatabaseLogs: any = {
  };
  dataSourceDatabaseLogs: any = {
    matchingCount:0,
    records: [ ],
  };
  searchTableValue: any = []
  searchTerm:string= '';
  initialData:any = [];
  emptyFieldRegex = /<(.|\n)*?>/g;
  pageSize: any = 5;
  currentPage: any = 0;
  pageNumber: any = 1;
  pageLength: any = 0;
  dbLogTableName: any='';
  processedData: any =[];
  constructor(
    private instanceService: InstanceService,
    private blobService: AzureBlobStorageService,
    private dialogService: DialogService,
    private location: Location,
  ) {}

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: 'Home', path: 'resources' },
      { label: 'Instance Operations', path: 'instanceoperations/instance-requests' },
      { label: 'Instance Requests', path: 'instanceoperations/instance-requests' },
      { label: 'Instance Tools',path: 'instanceoperations/instance-requests/instance-tools-landing'},
      { label: 'Instance Tools History', path: 'instanceoperations/instance-requests/history' },
      { label: 'Database logs', path: 'history/view-dblogs' },
    ];
    this.instanceId = localStorage.getItem('instanceID');
    let localData:any = localStorage.getItem('dbLogFileDetails');
    if(localData){
      this.dbLogTableName = JSON.parse(localData).dbLogTableName;
      this.dbLogFile = JSON.parse(localData).fileName;
    }
    this.getInstanceByID();
    this.downloadLogs();
    this.searchTableValue = this.dataSourceDatabaseLogs.records;
    this.instanceData.records = [
      {
        label: this.instanceEnum.instance_View_Field_customerName,
        data: "",
        id: 'custName',
      },
      {
        label:
          this.instanceEnum.instance_tools_card_field_instance_name,
        data: "",
        id: 'instName',
      },
      {
        label: this.instanceEnum.instance_View_Field_type,
        data: "",
        id: 'instType',
      },
      {
        label: this.instanceEnum.instance_tools_card_field_version,
        data: "",
        id: 'version',
      },
    ];
  }

  getInstanceByID() {
    try {
      this.instanceService
        .getInstanceByID(this.instanceId)
        .subscribe((resp: any) => {
          let result = resp.body;
          if (result != undefined && result != null) {
            this.ViewData = result;
            this.instanceData.records = [
              {
                label: this.instanceEnum.instance_View_Field_customerName,
                data: result?.customerName,
                id: 'custName',
              },
              {
                label:
                  this.instanceEnum.instance_tools_card_field_instance_name,
                data: result?.cname,
                id: 'instName',
              },
              {
                label: this.instanceEnum.instance_View_Field_type,
                data: result?.instanceType,
                id: 'instType',
              },
              {
                label: this.instanceEnum.instance_tools_card_field_version,
                data: result?.iciVersion,
                id: 'version',
              },
            ];
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  blobToString(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        /* read workbook */
        const data: string = e.target.result;

        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        // Extract headers from the first row
        const headers: any = jsonData[0];
        this.displayedColumnsDatabaseLogs = headers;
        let headerData: any = jsonData[0];
        headerData.forEach((data: any) => {
          headers[data] = {
            label: data,
            data: data,
          };
        });
        this.dataSourceValuesDatabaseLogs = headerData;
        // Extract rows excluding the header row
        const rows: any = jsonData.slice(1);
        const rowData = {};
        const dataObjects = rows.map((row: any) => {
          headers.forEach((header: any, index: any) => {
            rowData[header] = row[index];
          });
          return JSON.parse(JSON.stringify(rowData));
        });
        this.initialData = dataObjects;
        this.dataSourceDatabaseLogs ={
          matchingCount: this.initialData?.length,
          records: this.initialData.slice(this.currentPage, this.pageSize)
        }
        this.processedData = this.initialData;
        this.tableDatabaseLogs = {
          reference: 'tableDBLogsStatus',
          displayedColumns: this.displayedColumnsDatabaseLogs,
          dataSourceValues: this.dataSourceValuesDatabaseLogs,
          dataSource: this.dataSourceDatabaseLogs,
          pagination: {
            required: true,
            currentPage: this.currentPage,
            pageLength: this.pageLength,
            pageSize: this.pageSize
          },
        };
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsBinaryString(blob);
    });
  }

  downloadLogs() {
    try {
      this.instanceService
        .getBlobDownloadConnection(this.instanceId, this.dbLogFile)
        .subscribe((resp: any) => {
          if (resp != null && resp != undefined) {
            this.blobService.downloadImageExt(
              resp.sasUrl,
              resp.container,
              resp.blob,
              async (response: any) => {
                if (response._response.status == 200) {
                  response.blobBody.then(async (onres: any) => {
                    await this.blobToString(onres);
                  });
                }
              }
            );
          }
        });
    } catch (err) {
      console.log(err);
    }
  }

  Oncancel(){
    this.location.back();
  }

  filterData(): void {
    this.dataSourceDatabaseLogs = {
      matchingCount: this.initialData?.length,
      records:this.initialData
    }
    this.processedData = this.initialData;
    if (this.searchTerm?.replace(this.emptyFieldRegex, '').trim().length !== 0) {
      this.searchTableValue = this.dataSourceDatabaseLogs?.records.filter((item:any)  => {
        for (const key in item) {
          if (item.hasOwnProperty(key) && 
              item[key].toString().toLowerCase().replace(/\s+|"/g, '').includes(this.searchTerm.toLowerCase().replace(/\s+|"/g, ''))) {
            return true; 
          }
        }
        return false; 
      });
      this.processedData = this.searchTableValue;
      this.dataSourceDatabaseLogs = {
        matchingCount: this.searchTableValue?.length,
        records:this.searchTableValue
      }
    } 
    this.currentPage = 0;
    this.dataSourceDatabaseLogs = {
      matchingCount: this.processedData?.length,
      records:this.processedData?.slice(this.currentPage, this.pageSize)
    }
    this.tableDatabaseLogs = {
      reference: 'tableDBLogsStatus',
      displayedColumns: this.displayedColumnsDatabaseLogs,
      dataSourceValues: this.dataSourceValuesDatabaseLogs,
      dataSource: this.dataSourceDatabaseLogs,
      pagination: {
        required: true,
        currentPage: this.currentPage,
        pageLength: this.pageLength,
        pageSize: this.pageSize
      },
    };
  }

  ClearInput(event:any){
    this.searchTerm = '';
    this.currentPage = 0;
    this.dataSourceDatabaseLogs = {
      matchingCount: this.initialData?.length,
      records:this.initialData?.slice(this.currentPage, this.pageSize)
    }
    this.processedData= this.initialData;
    this.tableDatabaseLogs = {
      reference: 'tableDBLogsStatus',
      displayedColumns: this.displayedColumnsDatabaseLogs,
      dataSourceValues: this.dataSourceValuesDatabaseLogs,
      dataSource: this.dataSourceDatabaseLogs,
      pagination: {
        required: true,
        currentPage: this.currentPage,
        pageLength: this.pageLength,
        pageSize: this.pageSize
      },
    };
  }

  pageChanged(data: any) {
    let event = data.event;
    if (data.reference == 'tableDBLogsStatus') {
      this.pageSize = event.pageSize;
      this.currentPage = event.pageIndex;
      this.pageNumber = event.pageIndex + 1;
      let startIndex = this.currentPage * this.pageSize;
      let endIndex = startIndex + this.pageSize;
      this.dataSourceDatabaseLogs.records = this.processedData?.slice(startIndex, endIndex);
      this.tableDatabaseLogs = {
        reference: 'tableDBLogsStatus',
        displayedColumns: this.displayedColumnsDatabaseLogs,
        dataSourceValues: this.dataSourceValuesDatabaseLogs,
        dataSource: this.dataSourceDatabaseLogs,
        pagination: {
          required: true,
          currentPage: this.currentPage,
          pageLength: this.pageLength,
          pageSize: this.pageSize
        },
      };
    } 
  }
}
