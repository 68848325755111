import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { InstanceService } from 'src/app/core/services/instance-management/instance.service';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';
import { InstanceManagement } from 'src/app/shared/enum/instance-management';
import { Location } from '@angular/common';
import { FormControl } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Sort } from '@angular/material/sort';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { AzureBlobStorageService } from 'src/app/core/services/azure-blob-storage/azure-blob-storage.service';
import { LoaderService } from 'src/app/core/interceptors/loader/loader.service';

const EXPORT_ICON = `<svg xmlns="http://www.w3.org/2000/svg" width="138.972" height="146.126" viewBox="0 0 138.972 146.126">
<g id="Group_815" data-name="Group 815" transform="translate(-179.737 0)">
  <g id="Group_28" data-name="Group 28" transform="translate(179.737 0)">
    <path id="Path_410" data-name="Path 410" d="M625.94,195.658a3.224,3.224,0,0,0-2.947-1.972H564.151a3.191,3.191,0,0,0-3.189,3.189v74.439a3.191,3.191,0,0,0,3.189,3.19h58.842a3.194,3.194,0,0,0,3.19-3.189V196.877A3.131,3.131,0,0,0,625.94,195.658Zm-.466,75.655a2.484,2.484,0,0,1-2.48,2.48H564.151a2.48,2.48,0,0,1-2.48-2.48h0V196.877a2.484,2.484,0,0,1,2.48-2.48h58.842a2.494,2.494,0,0,1,2.3,1.563,2.75,2.75,0,0,1,.093.28,2.459,2.459,0,0,1,.085.64Z" transform="translate(-560.961 -193.686)" fill="#ccc"/>
    <path id="Path_411" data-name="Path 411" d="M642.355,223.867H624.631a1.418,1.418,0,1,1,0-2.836h17.723a1.418,1.418,0,1,1,0,2.836Z" transform="translate(-589.657 -206.291)" fill="#ccc"/>
    <path id="Path_412" data-name="Path 412" d="M642.355,237.677H624.631a1.418,1.418,0,1,1,0-2.836h17.723a1.418,1.418,0,1,1,0,2.836Z" transform="translate(-589.657 -212.657)" fill="#ccc"/>
    <path id="Path_413" data-name="Path 413" d="M596.536,232.166H583.581a1.6,1.6,0,0,1-1.6-1.6V215.287a1.6,1.6,0,0,1,1.595-1.6h12.955a1.6,1.6,0,0,1,1.595,1.6v15.284A1.6,1.6,0,0,1,596.536,232.166Z" transform="translate(-570.653 -202.908)" fill="#ccc"/>
    <path id="Path_414" data-name="Path 414" d="M623.03,265.953H582.975a1.418,1.418,0,1,1,0-2.836H623.03a1.418,1.418,0,0,1,0,2.836Z" transform="translate(-570.455 -225.691)" fill="#ccc"/>
    <path id="Path_415" data-name="Path 415" d="M623.03,279.767H582.975a1.418,1.418,0,1,1,0-2.836H623.03a1.418,1.418,0,0,1,0,2.836Z" transform="translate(-570.455 -232.059)" fill="#ccc"/>
    <path id="Path_416" data-name="Path 416" d="M623.03,293.572H582.975a1.418,1.418,0,1,1,0-2.836H623.03a1.418,1.418,0,0,1,0,2.836Z" transform="translate(-570.455 -238.422)" fill="#ccc"/>
    <path id="Path_417" data-name="Path 417" d="M623.03,307.385H582.975a1.418,1.418,0,1,1,0-2.836H623.03a1.418,1.418,0,0,1,0,2.836Z" transform="translate(-570.455 -244.79)" fill="#ccc"/>
    <path id="Path_418" data-name="Path 418" d="M623.03,321.191H582.975a1.418,1.418,0,1,1,0-2.836H623.03a1.418,1.418,0,0,1,0,2.836Z" transform="translate(-570.455 -251.154)" fill="#ccc"/>
  </g>
  <path id="Path_336" data-name="Path 336" d="M676.818,276.74a4.1,4.1,0,0,0-3.746-2.507H598.294a4.056,4.056,0,0,0-4.054,4.054v94.6a4.056,4.056,0,0,0,4.054,4.054h74.781a4.059,4.059,0,0,0,4.054-4.054v-94.6a3.981,3.981,0,0,0-.307-1.547Z" transform="translate(-396.565 -230.815)" fill="#e6e6e6"/>
  <path id="Path_478" data-name="Path 478" d="M674.842,375.586H604.958c-1.007,0-1.8-1.1-1.8-2.508V285.322c0-1.407.79-2.508,1.8-2.508h69.884c1.008,0,1.8,1.1,1.8,2.508v87.755C676.637,374.484,675.848,375.586,674.842,375.586Z" transform="translate(-400.678 -234.77)" fill="#fff"/>
  <path id="Path_336-2" data-name="Path 336" d="M676.818,276.74a4.1,4.1,0,0,0-3.746-2.507H598.294a4.056,4.056,0,0,0-4.054,4.054v94.6a4.056,4.056,0,0,0,4.054,4.054h74.781a4.059,4.059,0,0,0,4.054-4.054v-94.6a3.981,3.981,0,0,0-.307-1.547Zm-.593,96.148a3.157,3.157,0,0,1-3.153,3.153H598.294a3.154,3.154,0,0,1-3.153-3.152v-94.6a3.158,3.158,0,0,1,3.153-3.153h74.781A3.169,3.169,0,0,1,676,277.117a3.489,3.489,0,0,1,.119.356,3.125,3.125,0,0,1,.108.814Z" transform="translate(-396.565 -230.815)" fill="#3f3d56"/>
  <path id="Path_337" data-name="Path 337" d="M697.682,312.586H675.158a1.8,1.8,0,0,1,0-3.6h22.524a1.8,1.8,0,0,1,0,3.6Z" transform="translate(-433.034 -246.833)" fill="#3f3d56"/>
  <path id="Path_338" data-name="Path 338" d="M697.682,330.136H675.158a1.8,1.8,0,0,1,0-3.6h22.524a1.8,1.8,0,0,1,0,3.6Z" transform="translate(-433.034 -254.923)" fill="#3f3d56"/>
  <path id="Path_339" data-name="Path 339" d="M639.449,323.132H622.988a2.03,2.03,0,0,1-2.027-2.027V301.68a2.029,2.029,0,0,1,2.027-2.027h16.461a2.029,2.029,0,0,1,2.027,2.027v19.425a2.03,2.03,0,0,1-2.027,2.027Z" transform="translate(-408.882 -242.532)" fill="#6c63ff"/>
  <path id="Path_340" data-name="Path 340" d="M673.123,366.072h-50.9a1.8,1.8,0,0,1,0-3.6h50.9a1.8,1.8,0,0,1,0,3.6Z" transform="translate(-408.631 -271.488)" fill="#e6e6e6"/>
  <path id="Path_341" data-name="Path 341" d="M673.118,383.622h-50.9a1.8,1.8,0,1,1-.01-3.6h50.915a1.8,1.8,0,1,1,0,3.6Z" transform="translate(-408.627 -279.578)" fill="#e6e6e6"/>
  <path id="Path_342" data-name="Path 342" d="M673.123,401.172h-50.9a1.8,1.8,0,0,1,0-3.6h50.9a1.8,1.8,0,0,1,.01,3.6Z" transform="translate(-408.631 -287.667)" fill="#e6e6e6"/>
  <path id="Path_343" data-name="Path 343" d="M673.118,418.722h-50.9a1.8,1.8,0,1,1-.01-3.6h50.915a1.8,1.8,0,1,1,0,3.6Z" transform="translate(-408.627 -295.757)" fill="#e6e6e6"/>
  <path id="Path_344" data-name="Path 344" d="M673.118,436.272h-50.9a1.8,1.8,0,1,1-.01-3.6h50.915a1.8,1.8,0,1,1,0,3.6Z" transform="translate(-408.627 -303.847)" fill="#e6e6e6"/>
  <circle id="Ellipse_44" data-name="Ellipse 44" cx="29.108" cy="29.108" r="29.108" transform="translate(260.492 7.346)" fill="#6c63ff"/>
  <path id="Path_692" data-name="Path 692" d="M762.907,249.373V233.314h-8.27v16.059h-3.861l4,6.924,4,6.925,4-6.925,4-6.925Z" transform="translate(-468.722 -211.953)" fill="#fff"/>
</g>
</svg>`

const TASKHISTORYMODEL = "TaskHistoryModel";
const SQLFAILEDMODEL = "SqlFailedModel";
const INFRAMODEL = "InfraModel";

@Component({
  selector: 'app-view-gif',
  templateUrl: './view-gif.component.html',
  styleUrls: ['./view-gif.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class ViewGIFComponent implements OnInit {
  @ViewChild('stepper', { static: false }) mystepper!: MatStepper;
  ViewData: any;
  instanceGIFData: any = {
    title: 'Instance Details',
    records: [],
  };
  breadCrumbItems: any;
  instanceId: any;
  currentPage: any = 0;
  pageNumber: any = 1;
  pageSize: any = 5;
  pageLength: any = 0;

  public instanceEnum = InstanceManagement;
  firstStepControl = new FormControl('');
  secondStepControl = new FormControl('');
  thirdStepControl = new FormControl('');
  displayedColumnsTrackStatus: any = ['area', 'result', 'comments'];
  displayedColumnsTenantKeyStatus: any = ['key', 'value', 'result'];
  displayedColumnsCrossRefAttribute: any = [
    'entityInstanceId',
    'icmEntityName',
    'clientEntityName',
    'ctAttributeName',
    'icmClientReferencekey',
  ];
  dataSourceValuesCrossRefAttribute: any = {
    entityInstanceId: {
      label: 'Entity Instance ID',
      data: 'EntityInstanceId',
    },
    icmEntityName: {
      label: 'ICI Entity Name',
      data: 'IcmEntityName',
    },
    clientEntityName: {
      label: 'Client Entity Name',
      data: 'ClientEntityName',
    },
    ctAttributeName: {
      label: 'Contract Type Attribute Name',
      data: 'CTAttributeName',
    },
    icmClientReferencekey: {
      label: 'ICI Client Reference Key',
      data: 'IcmClientReferencekey',
    },
  };
  displayedColumnsEntityMappingAttribute: any = [
    'entityInstanceId',
    'icmEntityName',
    'clientEntityName',
    'ctAttributeName',
    'clientAttributeName',
  ];
  dataSourceValuesEntityMappingAttribute: any = {
    entityInstanceId: {
      label: 'Entity Instance ID',
      data: 'EntityInstanceId',
    },
    icmEntityName: {
      label: 'ICI Entity Name',
      data: 'IcmEntityName',
    },
    clientEntityName: {
      label: 'Client Entity Name',
      data: 'ClientEntityName',
    },
    ctAttributeName: {
      label: 'Contract Type Attribute Name',
      data: 'CTAttributeName',
    },
    clientAttributeName: {
      label: 'Client Attribute Name',
      data: 'ClientAttributeName',
    },
  };
  displayedColumnsServiceConnection: any = [
    'Area',
    'Result',
    'Comments',
  ];
  dataSourceValuesServerConnection: any = {
    Area: {
      label: 'Service',
      data: 'Area',
    },
    Result: {
      label: 'Status',
      data: 'Result',
    },
    Comments: {
      label: 'Details',
      data: 'Comments',
    },
  };
  displayedColumnsStagingTable: any = [
    'Id',   
    'ICIVersion',  
    'CustomerName',
    'FileName',
    'EntityName',
    'Status',
    'TotalRecords',
    'PassedRecords',
    'FailedRecords',
    'CreatedDate',
    'ProcessedDate',
  ];
  dataSourceValuesStagingTable: any = {
    Id: {
      label: 'Id',
      data: 'Id',
    },   
    ICIVersion: {
      label: 'ICI Version',
      data: 'ICIVersion',
    }, 
     CustomerName : {
      label: 'Customer Name',
      data: 'CustomerName',
    },
    FileName: {
      label: 'File Name',
      data: 'FileName',
    },
    EntityName: {
      label: 'Entity Name',
      data: 'EntityName',
    },
    Status: {
      label: 'Status',
      data: 'Status',
    },
    TotalRecords: {
      label: 'Total Records',
      data: 'TotalRecords',
    },
    PassedRecords: {
      label: 'Passed Records',
      data: 'PassedRecords',
    },
    FailedRecords: {
      label: 'Failed Records',
      data: 'FailedRecords',
    },
    CreatedDate: {
      label: 'Created Date',
      data: 'CreatedDate',
    },
    ProcessedDate: {
      label: 'Processed Date',
      data: 'ProcessedDate',
    },
  };
  displayedColumnsServiceBus: any = [
    'Name',
    'TopicName',
    'SubscriptionName',
    'DbResult',
    'AsbResult',
    'Result',
  ];
  dataSourceValuesServiceBus: any = {
    Name: {
      label: 'Name',
      data: 'Name',
    },
    TopicName: {
      label: 'Topic Name',
      data: 'TopicName',
    },
    SubscriptionName: {
      label: 'Subscription Name',
      data: 'SubscriptionName',
    },
    DbResult: {
      label: 'Database Result',
      data: 'DbResult',
    },
    AsbResult: {
      label: 'Service Bus Result',
      data: 'AsbResult',
    },
    Result: {
      label: 'Result',
      data: 'Result',
    },
  };
  signatureKey: any;
  recipientEmail: any = '';
  userEmail: any = '';
  signaturekeyData: any = [
    {
      id: 1,
      name: 'Sender',
    },
    {
      id: 2,
      name: 'AccountOwner',
    },
    {
      id: 3,
      name: 'Custom',
    },
  ];
  displayedColumnsClientAppCrossRefCT: any = [
    'attributename',
    'datatype',
    'isglobal',
    'ismandatory',
    'ismultiselect',
    'choicevalues'
  ];
  displayedColumnsEntityMappingCT: any = [
    'attributename',
    'datatype',
    'isglobal',
    'ismandatory',
    'ismultiselect',
    'choicevalues'
  ];

  dataSourceValuesTrackStatus: any = {
    area: {
      label: 'Service',
      data: 'Area',
    },
    result: {
      label: 'Status',
      data: 'Result',
    },
    comments: {
      label: 'Details',
      data: 'Comments',
    },
  };
  dataSourceValuesTenantKeyStatus: any = {
    key: {
      label: 'Key',
      data: 'Key',
    },
    value: {
      label: 'Value',
      data: 'Value',
    },
    result: {
      label: 'Result',
      data: 'Result',
    },
  };
  dataSourceValuesClientAppCrossRefCT: any = {
    attributename: {
      label: 'Attribute Name',
      data: 'AttributeName',
    },
    datatype: {
      label: 'Data Type',
      data: 'DataType',
    },
    isglobal: {
      label: 'Is Global',
      data: 'IsGlobal',
    },
    ismandatory: {
      label: 'Is Mandatory',
      data: 'IsMandatory',
    },
    ismultiselect: {
      label: 'Is MultiSelect',
      data: 'IsMultiSelect',
    },
    choicevalues: {
      label: 'Choice Values',
      data: 'ChoiceValues',
    },
  };
  dataSourceValuesEntityMappingCT: any = {
    attributename: {
      label: 'Attribute Name',
      data: 'AttributeName',
    },
    datatype: {
      label: 'Data Type',
      data: 'DataType',
    },
    isglobal: {
      label: 'Is Global',
      data: 'IsGlobal',
    },
    ismandatory: {
      label: 'Is Mandatory',
      data: 'IsMandatory',
    },
    ismultiselect: {
      label: 'Is MultiSelect',
      data: 'IsMultiSelect',
    },
    choicevalues: {
      label: 'Choice Values',
      data: 'ChoiceValues',
    },
  };
  dataSourceTrackStatus: any = {
    records: [],
  };
  dataSourceTenantKeyStatus: any = {
    records: [],
  };
  dataSourceClientAppCrossRefCT: any = {
    matchingCount: 0,
    records: [],
  };
  dataSourceEntityMappingCT: any = {
    matchingCount: 0,
    records: [],
  };
  dataSourceCrossRefAttribute: any = {
    records: [],
  };
  dataSourceEntityMappingAttribute: any = {
    matchingCount: 0,
    records: [],
  };
  dataSourceServerConnection: any = {
    records: [],
  };
  dataSourceStagingTable: any = {
    records: [],
  };
  dataSourceServiceBus: any = {
    records: [],
  };

  dataSourceValidationStatus: any = {
    records: [],
  };
  tableTrackStatus: any;
  tableTenantKeyStatus: any;
  tableClientAppCrossRefCT: any;
  tableEntityMappingCT: any;
  tableCrossRefAttribute: any;
  tableEntityMappingAttribute: any;
  tableServerConnection: any;
  tableStaging: any;
  tableServiceBus: any;
  instanceName: any;
  integrationType: any = [];
  iciVersion: any;
  configError: any = false;
  configValidationError: any = false;
  configException: any = false; 
  sortBy: any = '';
  clientAppCrossRefCTSort: any;
  entityMappingCTSort: any;
  entityMappingAttributeSort: any;
  validationStatusTable: any;
  emptyFieldRegex = /<(.|\n)*?>/g;
  gifDetails: any;
  historyPeriod: any;
  gifFileName: any;

  constructor(
    private instanceService: InstanceService,
    private dialogService: DialogService,
    private router: Router,
    private location: Location,
    sanitizer: DomSanitizer,
    iconRegistry: MatIconRegistry,
    private permissionService: PermissionsService,
    private notificationService: NotificationService,
    private blobService: AzureBlobStorageService,
    private loader: LoaderService,
    private cdref: ChangeDetectorRef
  ) {
    iconRegistry.addSvgIconLiteral(
      'export_icon',
      sanitizer.bypassSecurityTrustHtml(EXPORT_ICON)
    );
  }

   ngOnInit(): void {
      this.breadCrumbItems = [
        { label: 'Home', path: 'resources' },
        { label: 'Instance Operations', path: 'instanceoperations/instance-requests' },
        { label: 'Instance Requests', path: 'instanceoperations/instance-requests' },
        { label: 'Instance Tools',path: 'instanceoperations/instance-requests/instance-tools-landing'},
        { label: 'Instance Tools History', path: 'instanceoperations/instance-requests/history' },
        { label: 'GIF Troubleshooting', path: 'history/view-gif' },
      ];
    this.instanceId = localStorage.getItem('instanceID');
    let localData: any = localStorage.getItem('gifTroubleShooterLocalData');
    if (localData) {
      this.instanceName = JSON.parse(localData).instanceName;
      this.iciVersion = JSON.parse(localData).version;
      this.integrationType = JSON.parse(localData).integrationType;
      this.gifFileName = JSON.parse(localData).fileName;
    }

    this.instanceGIFData.records = [
      {
        label: this.instanceEnum.instance_tools_card_field_instance_name,
        data: this.instanceName,
        id: 'instName',
      },
      {
        label: this.instanceEnum.instance_tools_card_field_version,
        data: this.iciVersion,
        id: 'version',
      },
    ];
    this.downloadLogs();
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  downloadLogs() {
    let element = {
      fileName: this.gifFileName
    };
    try {
      this.instanceService
        .getBlobDownloadConnection(this.instanceId, element.fileName)
        .subscribe((resp: any) => {
          this.loader.show();
          if (resp != null && resp != undefined) {
            this.blobService.downloadImageExt(
              resp.sasUrl,
              resp.container,
              resp.blob,
              async (response: any) => {
                if (response._response.status == 200) {
                  response.blobBody.then(async (onres: any) => {
                    const blobContents = await this.blobToString(onres);
                    const data = JSON.parse(blobContents);
                    this.historyPeriod = data?.NumberOfDaysHistory;
                    this.gifTroubleShooterDetails(data);
                    this.loader.hide();
                  });
                }
              }
            );
          } else {
            this.loader.hide();
          }
        });
    } catch (err) {
      console.log(err);
    }
  }

  blobToString(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsText(blob);
    });
  }

  onNext() {
    this.mystepper.next();
  }

  onPrevious() {
    this.mystepper.previous();
  }

  onCancel() {
    this.location.back();
  }

  exportToExcel() {
    try {
     if (this.integrationType != undefined && this.integrationType != null && this.integrationType != '') {
      let param = {
        instanceId: this.instanceId,
        integrationType: this.integrationType[0],
        integrationMode: this.integrationType[0] == this.instanceEnum.instance_gif_Integration_type_messaging_based ? this.integrationType.length > 1 ? this.integrationType[1] : null : null,
        integrationDataFlow: this.integrationType[0] == this.instanceEnum.instance_gif_Integration_type_messaging_based ? this.integrationType.length > 2 ? this.integrationType[2] : null : null,
        connectionServerName: this.integrationType[0] == this.instanceEnum.instance_gif_Integration_type_file_based ? this.integrationType.length > 1 ? this.integrationType[1] : null : null,
        clientEntityName: this.integrationType[0] == this.instanceEnum.instance_gif_Integration_type_file_based ? this.integrationType.length > 2 ? this.integrationType[2] : null : null,
        RequestType: 'excel'
      }
      this.instanceService
        .gifExportExcel(param).subscribe((resp: any) => {
        });
      this.location.back();
     }
    } catch (error) {
      console.log(error);
    }
  }

  gifTroubleShooterDetails(gifData: any) {
    try {
      this.gifDetails = gifData;
      this.getInfraStructureDetail();
      this.getTenantKeyDetail();
      this.getClientAppCrossReferenceCTDetails(this.currentPage, this.pageSize);
      this.getEntityMappingCTDetails(this.currentPage, this.pageSize);
      this.getCrossRefAttributeDetail(this.currentPage, this.pageSize);
      this.getStagingDetail(this.currentPage, this.pageSize);
      this.getServerConnectionDetail();
      this.getServiceBusDetail();
      this.getEntityMappingAttributeDetails(this.currentPage, this.pageSize);
      this.configException =  this.gifDetails?.ClientAppCrossReferenceCT?.Exception != null || this.gifDetails?.ClientAppCrossReferenceCT?.Exception != null || 
      this.gifDetails?.ClientAppCrossReferenceCT?.Exception != null || this.gifDetails?.ClientAppCrossReferenceCT?.Exception != null;
    } catch (error) {
      console.log(error);
    }
  }

  pageChanged(data: any) {
    let event = data.event;
    if (data.reference == 'tableClientAppCrossRefCT') {
      this.pageSize = event.pageSize;
      this.currentPage = event.pageIndex;
      this.pageNumber = event.pageIndex + 1;
      this.getClientAppCrossReferenceCTDetails(
        this.currentPage,
        this.pageSize
      );
    } else if (data.reference == 'tableEntityMappingCT') {
      this.pageSize = event.pageSize;
      this.currentPage = event.pageIndex;
      this.pageNumber = event.pageIndex + 1;
      this.getEntityMappingCTDetails(
        this.currentPage,
        this.pageSize
      );
    }
    else if (data.reference == 'tableCrossRefAttribute') {
      this.pageSize = event.pageSize;
      this.currentPage = event.pageIndex;
      this.pageNumber = event.pageIndex + 1;
      this.getCrossRefAttributeDetail(
        this.currentPage,
        this.pageSize
      );
    }
    else if (data.reference == 'tableEntityMappingAttribute') {
      this.pageSize = event.pageSize;
      this.currentPage = event.pageIndex;
      this.pageNumber = event.pageIndex + 1;
      this.getEntityMappingAttributeDetails(
        this.currentPage,
        this.pageSize
      );
    }
    else if (data.reference == 'tableStaging') {
      this.pageSize = event.pageSize;
      this.currentPage = event.pageIndex;
      this.pageNumber = event.pageIndex + 1;
      this.getStagingDetail(
        this.currentPage,
        this.pageSize
      );
    }
  }

  getInfraStructureDetail() {
    this.dataSourceTrackStatus.records = this.gifDetails?.InfraStructure?.TaskTableResult;
    this.tableTrackStatus = {
      reference: 'tableTrackStatus',
      displayedColumns: this.displayedColumnsTrackStatus,
      dataSourceValues: this.dataSourceValuesTrackStatus,
      dataSource: this.dataSourceTrackStatus,
    };
    this.configValidationError = this.gifDetails?.IsValidateModelFail;
  }

  getTenantKeyDetail() {
    this.dataSourceTenantKeyStatus.records = this.gifDetails?.TenantKeys?.AkvKeysResult;
    this.tableTenantKeyStatus = {
      reference: 'tableTenantKeyStatus',
      displayedColumns: this.displayedColumnsTenantKeyStatus,
      dataSourceValues: this.dataSourceValuesTenantKeyStatus,
      dataSource: this.dataSourceTenantKeyStatus,
    };
    this.configValidationError = this.gifDetails?.IsValidateModelFail;
  }

  getClientAppCrossReferenceCTDetails(pageIndex: any, pageSize: any) {
    let startIndex = pageIndex * pageSize;
    let endIndex = startIndex + pageSize;
    this.dataSourceClientAppCrossRefCT = {
      matchingCount: this.gifDetails?.ClientAppCrossReferenceCT?.DBValidationsResult?.length,
      records: this.gifDetails?.ClientAppCrossReferenceCT?.DBValidationsResult?.slice(startIndex, endIndex),
    };

    this.tableClientAppCrossRefCT = {
      reference: 'tableClientAppCrossRefCT',
      displayedColumns: this.displayedColumnsClientAppCrossRefCT,
      dataSourceValues: this.dataSourceValuesClientAppCrossRefCT,
      dataSource: this.dataSourceClientAppCrossRefCT,
      pagination: {
        required: true,
        currentPage: this.currentPage,
        pageLength: this.pageLength,
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
      },
    };
    this.configError = this.gifDetails?.IsDBValidateModelFail;
  }

  getEntityMappingCTDetails(pageIndex: any, pageSize: any) {
    let startIndex = pageIndex * pageSize;
    let endIndex = startIndex + pageSize;
    this.dataSourceEntityMappingCT = {
      matchingCount: this.gifDetails?.EntityMappingCT?.DBValidationsResult?.length,
      records: this.gifDetails?.EntityMappingCT?.DBValidationsResult?.slice(startIndex, endIndex),
    };

    this.tableEntityMappingCT = {
      reference: 'tableEntityMappingCT',
      displayedColumns: this.displayedColumnsEntityMappingCT,
      dataSourceValues: this.dataSourceValuesEntityMappingCT,
      dataSource: this.dataSourceEntityMappingCT,
      pagination: {
        required: true,
        currentPage: this.currentPage,
        pageLength: this.pageLength,
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
      },
    };
    this.configError = this.gifDetails?.IsDBValidateModelFail;
  }

  getCrossRefAttributeDetail(pageIndex: any, pageSize: any) {
    let startIndex = pageIndex * pageSize;
    let endIndex = startIndex + pageSize;
    this.dataSourceCrossRefAttribute = {
      matchingCount: this.gifDetails?.CrossReferenceAttribute?.CrossRefAttributeResult?.length,
      records: this.gifDetails?.CrossReferenceAttribute?.CrossRefAttributeResult?.slice(startIndex, endIndex),
    };

    this.tableCrossRefAttribute = {
      reference: 'tableCrossRefAttribute',
      displayedColumns: this.displayedColumnsCrossRefAttribute,
      dataSourceValues: this.dataSourceValuesCrossRefAttribute,
      dataSource: this.dataSourceCrossRefAttribute,
      pagination: {
        required: true,
        currentPage: this.currentPage,
        pageLength: this.pageLength,
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
      },
    };
    this.configError = this.gifDetails?.IsDBValidateModelFail;
  }

  getEntityMappingAttributeDetails(pageIndex: any, pageSize: any) {
    let startIndex = pageIndex * pageSize;
    let endIndex = startIndex + pageSize;
    this.dataSourceEntityMappingAttribute = {
      matchingCount: this.gifDetails?.EntityMappingAttribute?.EntityMapAttrResult?.length,
      records: this.gifDetails?.EntityMappingAttribute?.EntityMapAttrResult?.slice(startIndex, endIndex),
    };

    this.tableEntityMappingAttribute = {
      reference: 'tableEntityMappingAttribute',
      displayedColumns: this.displayedColumnsEntityMappingAttribute,
      dataSourceValues: this.dataSourceValuesEntityMappingAttribute,
      dataSource: this.dataSourceEntityMappingAttribute,
      pagination: {
        required: true,
        currentPage: this.currentPage,
        pageLength: this.pageLength,
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
      },
    };
    this.configError = this.gifDetails?.IsDBValidateModelFail;
  }

  getServerConnectionDetail() {
    this.dataSourceServerConnection.records = this.gifDetails?.ServerConnectionResult?.ServerconnectionResult;
    this.tableServerConnection = {
      reference: 'tableServerConnection',
      displayedColumns: this.displayedColumnsServiceConnection,
      dataSourceValues: this.dataSourceValuesServerConnection,
      dataSource: this.dataSourceServerConnection,
    };
    this.configError = this.gifDetails?.IsDBValidateModelFail;
  }

  getStagingDetail(pageIndex: any, pageSize: any) {
    let startIndex = pageIndex * pageSize;
    let endIndex = startIndex + pageSize;
    this.dataSourceStagingTable = {
      matchingCount: this.gifDetails?.StagingTable?.StagingTableResult?.length,
      records: this.gifDetails?.StagingTable?.StagingTableResult?.slice(startIndex, endIndex),
    };

    this.tableStaging = {
      reference: 'tableStaging',
      displayedColumns: this.displayedColumnsStagingTable,
      dataSourceValues: this.dataSourceValuesStagingTable,
      dataSource: this.dataSourceStagingTable,
      pagination: {
        required: true,
        currentPage: this.currentPage,
        pageLength: this.pageLength,
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
      },
    };
    this.configError = this.gifDetails?.IsDBValidateModelFail;
  }

  getServiceBusDetail() {
    this.dataSourceServiceBus.records = this.gifDetails?.ServiceBus?.AzureserviceBusTableResult;
    this.tableServiceBus = {
      reference: 'tableServiceBus',
      displayedColumns: this.displayedColumnsServiceBus,
      dataSourceValues: this.dataSourceValuesServiceBus,
      dataSource: this.dataSourceServiceBus,
    };
    this.configError = this.gifDetails?.IsDBValidateModelFail;
  }

  sortData(sortBy: any, dataTypeModel: any) {
    let typeModel: any;
    if (dataTypeModel == SQLFAILEDMODEL) {
      typeModel = 'SpFailed';
    } else if (dataTypeModel == TASKHISTORYMODEL) {
      typeModel = 'TaskHistory';
    }
    if (sortBy.includes('CreatedDate') || sortBy.includes('StartDate') || sortBy.includes('EndDate')) {
      if (sortBy.includes('_desc')) {
        let sortData = sortBy.split('_')[0];
        this.gifDetails[typeModel]?.sort((a: any, b: any) => {
          return new Date(b[sortData]).getTime() - (new Date(a[sortData]).getTime());
        });
      } else {
        let sortData = sortBy;
        this.gifDetails[typeModel]?.sort((a: any, b: any) => {
          return new Date(a[sortData]).getTime() - (new Date(b[sortData]).getTime());
        });
      }
    } else {
      if (sortBy.includes('_desc')) {
        let sortData = sortBy.split('_')[0];
        this.gifDetails[typeModel]?.sort((a: any, b: any) => {
          return b[sortData].localeCompare(a[sortData]);
        });
      } else {
        let sortData = sortBy;
        this.gifDetails[typeModel]?.sort((a: any, b: any) => {
          return a[sortData].localeCompare(b[sortData]);
        });
      }
    }

  }
}
