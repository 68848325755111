<app-pageheader [pageTitle]="'Customer Package Upgrades'" [auditLog]="true" [auditLogData]="'mtpodupgrade'"
[applyFilter]="false" [clearFilter]="false"  [archive]="false" [breadCrumbs]="this.breadCrumbItems"></app-pageheader>

<app-preview-upgrade (closeD)="closedrawer($event)" *ngIf="enablePreviewDrawer" [podDetails]="podDetails" ></app-preview-upgrade>

<div class="page-container">
  <div class="idn-filter">
    <form class="idn-grid-filter">
      <mat-form-field class="idn-grid-filter-search mat-form-field-prefix-custom">
        <mat-label>{{multiTenantPods.multiTenantPods_Filter_Search_label_searchInstance}}</mat-label>
        <input type="text" (keydown.enter)="doFilter()" #search_input matInput [(ngModel)]="filter" name="filter"
          placeholder="Search by POD name, Region, Subscription and ICI version">
        <span class="idn-custom-search" matSuffix>
          <mat-icon (click)="doFilter()">search</mat-icon>
          <button type="button" mat-icon-button aria-label="Clear" (click)="ClearInput($event,'search')">
            <mat-icon>close</mat-icon> 
          </button>
        </span>
      </mat-form-field>
      <mat-form-field class="select" *ngIf="referenceData">
        <mat-label>{{multiTenantPods.multiTenantPods_ici_Version}}</mat-label>
        <mat-select (selectionChange)="doFilter()" #select_IciVersion [(ngModel)]="iciVersion" name="iciVersion">
          <mat-option *ngFor="let iciversion of referenceData.iciVersions" [value]="iciversion">
            {{iciversion}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="select" *ngIf="referenceData">
        <mat-label>{{multiTenantPods.multiTenantPods_Region}}</mat-label>
        <mat-select (selectionChange)="doFilter()" #select_Region [(ngModel)]="region" name="region">
          <mat-option *ngFor="let region of referenceData.deploymentRegions" value={{region.id}}>
            {{region.displayName}}</mat-option>
        </mat-select>
      </mat-form-field>
       <mat-form-field class="select" *ngIf="referenceData">
        <mat-label>{{multiTenantPods.multiTenantPods_upgrade_Status}}</mat-label>
        <mat-select (selectionChange)="doFilter()" #select_UpgradeStatus [(ngModel)]="upgradeStatus" name="upgradeStatus">
          <mat-option *ngFor="let upgradestatus of upgradeStatusList" value={{upgradestatus.id}}>
            {{upgradestatus.displayName}}</mat-option>
        </mat-select>
      </mat-form-field>
    </form>
    <button mat-stroked-button color="primary" (click)="clearFilter()">
      <mat-icon class="icon-btn-spacer">filter_list_off</mat-icon>
      <span>Clear Filter</span>
    </button>
  </div>

  <div class="table-scroll">
    <table mat-table [dataSource]="dataSource" matSort style="width: 100%" #empTbSort="matSort" (matSortChange)="
      announceSortChange($event)" class="idn-grid-position" *ngIf="!noContent; else noContentTemplate">

      <!-- region Column -->
      <ng-container matColumnDef="deploymentRegion">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
          {{multiTenantPods.multiTenantPods_Region}}
        </th>
        <td mat-cell *matCellDef="let element"> {{element.deploymentRegion}} </td>
      </ng-container>

      <!-- subscription Column -->
      <ng-container matColumnDef="subscription">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
          {{multiTenantPods.multiTenantPods_Subscription}}
        </th>
        <td mat-cell *matCellDef="let element"> {{element.subscription}} </td>
      </ng-container>

      <!-- podName Column -->
      <ng-container matColumnDef="mastertenantcname">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by POD name">
          {{multiTenantPods.multiTenantPods_PodName}}
        </th>
        <td mat-cell *matCellDef="let element"> {{element.masterTenantCname}}</td>
      </ng-container>

      <!-- currentIciVersion Column -->
      <ng-container matColumnDef="iciversion">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Current ICI Version">
          Current ICI Version
        </th>
        <td mat-cell *matCellDef="let element"> {{element.iciVersion}} </td>
      </ng-container>

      <!-- targetIciVersion Column -->
      <ng-container matColumnDef="targeticiversion">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Target ICI Version">
          Target ICI Version
        </th>
        <td mat-cell *matCellDef="let element"> {{element.targetVersion}} </td>
      </ng-container>

      <!-- upgradeDate Column -->
      <ng-container matColumnDef="upgradedate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Upgrade Date">
          Upgrade Date
        </th>
        <td mat-cell *matCellDef="let element"> {{element.upgradeDate | date:"dd MMMM, yyyy "}} </td>
      </ng-container>

       <!-- upgradeDate Column -->
       <ng-container matColumnDef="upgradestatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Upgrade Status">
          Upgrade Status
        </th>
        <td mat-cell *matCellDef="let element" class="status-icons"> 
          {{element.upgradeStatus}} 
          <ng-container *ngIf="(element.upgradeStatus == 'Not Applicable')">
            <mat-icon class="material-symbols-outlined" matTooltip="No packages available for upgrade"> info_outline </mat-icon>
        </ng-container>
      </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>
          {{multiTenantPods.multiTenantPods_Actions}}
        </th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button aria-label="View" (click)="viewMultiTenant('view',element)"
            matTooltip="View Multi Tenant Pod">
            <mat-icon class="view-icon">remove_red_eye</mat-icon>
          </button>
          <button [matMenuTriggerFor]="gridMenu" mat-icon-button aria-label="Multi Tenant Pod Actions"
            matTooltip="Multi Tenant-POD Actions" *ngIf="isViewPreviewUpgrade && enableLaActivity">
            <mat-icon>more_horiz</mat-icon>
          </button>
          <mat-menu #gridMenu="matMenu" xPosition="before">
           <button mat-menu-item (click)="upgradeTenantPod(element)" *ngIf="enableLaActivity">
            <div class="idn-menu-items">
              <mat-icon>upgrade</mat-icon>{{multiTenantPods.btn_label}}
            </div>
            </button>
          </mat-menu>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
      </tr>
      <tr mat-row *matRowDef="let row; columns:displayedColumns;"></tr>
    </table>
  </div>
  <mat-paginator #paginator [pageIndex]="currentPage" [pageSize]="10" class="mat-paginator-sticky"
    (page)="pageChanged($event)" [length]="pageLength" [pageSizeOptions]="[5, 10, 15, 20, 25, 50]" showFirstLastButtons
    aria-label="Select page of periodic elements" [ngClass]="{'hide-pagination': noContent ? true : false}">
  </mat-paginator>
</div>


<ng-template #noContentTemplate>
  <div class="idn-no-result">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="203.276" height="214.71"
      viewBox="0 0 203.276 214.71">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_158" data-name="Rectangle 158" width="14.11" height="37.39" transform="translate(0 0)"
            fill="#515165" />
        </clipPath>
      </defs>
      <g id="Group_559" data-name="Group 559" transform="translate(2759.66 -7619.728) rotate(42)">
        <rect id="Rectangle_152" data-name="Rectangle 152" width="11.288" height="33.863"
          transform="translate(3187.267 7517.8)" fill="#7c7c97" />
        <rect id="Rectangle_153" data-name="Rectangle 153" width="5.644" height="33.863"
          transform="translate(3192.911 7517.8)" fill="#515165" />
        <rect id="Rectangle_154" data-name="Rectangle 154" width="28.219" height="86.774"
          transform="translate(3178.801 7551.663)" fill="#ababc5" />
        <path id="Union_2" data-name="Union 2"
          d="M0,23.284c0-.057,0-.113,0-.17H0V0H28.219V23.114h0c0,.056,0,.113,0,.17A14.11,14.11,0,0,1,0,23.284Z"
          transform="translate(3178.801 7617.272)" fill="#7c7c97" />
        <rect id="Rectangle_155" data-name="Rectangle 155" width="14.11" height="86.774"
          transform="translate(3192.911 7551.663)" fill="#7c7c97" />
        <g id="Group_558" data-name="Group 558" transform="translate(3140 7426.088)">
          <circle id="Ellipse_42" data-name="Ellipse 42" cx="53.264" cy="53.264" r="53.264" transform="translate(0 0)"
            fill="#cfdae2" />
          <circle id="Ellipse_43" data-name="Ellipse 43" cx="44.798" cy="44.798" r="44.798"
            transform="translate(8.466 8.466)" fill="#f6f2f1" />
        </g>
        <g id="Mask_Group_7" data-name="Mask Group 7" transform="translate(3192.911 7617.272)"
          clip-path="url(#clip-path)">
          <path id="Union_1" data-name="Union 1"
            d="M0,23.284c0-.057,0-.113,0-.17H0V0H28.219V23.114h0c0,.056,0,.113,0,.17A14.11,14.11,0,0,1,0,23.284Z"
            transform="translate(-14.11 0)" fill="#515165" />
        </g>
      </g>
    </svg>
    <h4>{{ multiTenantPods.multiTenantPods_no_result_msg }}</h4>
  </div>
</ng-template>