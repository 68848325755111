
    <mat-card class="idn-acc-list-card" (click)="filteredData(custData)" *ngIf="custData && !userData">
        <mat-card-content id="{{custData?.id}}">
          <div class="card-content">
            <div class="total-customers" >{{custData?.title}}</div>   
            <div class="num-customers">{{custData?.count}}</div> 
          </div>
          <div class="card-icon">
            <mat-icon>{{custData?.icon}}</mat-icon> 
          </div>
        </mat-card-content>
    </mat-card>
    <mat-card class="idn-acc-list-card" (click)="filteredData(userData)" *ngIf="(userData && !custData) && userData?.title != 'Failed' || (userData?.title == 'Failed' && userData?.viewCard) ">
      <mat-card-content id="{{userData?.id}}">
        <div class="card-content">
          <div class="total-customers">{{userData?.title}}</div>   
          <div class="num-customers">{{userData?.count}}</div> 
        </div>
        <div class="card-icon">
          <mat-icon *ngIf="userData?.title == 'Total Users'" svgIcon="TOTAL_USER"></mat-icon>
          <mat-icon *ngIf="userData?.title == 'Active'" svgIcon="ACTIVE_USER"></mat-icon>
          <mat-icon *ngIf="userData?.title == 'Inactive'" svgIcon="INACTIVE_USER"></mat-icon>
          <mat-icon *ngIf="userData?.title == 'Failed'" svgIcon="FAILED_USER"></mat-icon> 
        </div>
      </mat-card-content>
     </mat-card>

