<app-pageheader [breadCrumbs]="this.breadCrumbItems" [auditLog]="false"  [editPartner]="updatePartner == true ? true : false" [createRole]="false" [mapRole]="false"
  [applyFilter]="false" [clearFilter]="false" [archive]="false"></app-pageheader>
  <div class="page-container">
    <div class="idn-acc-wrapper">
      
        <mat-card class="idn-acc-list-card">
            <mat-card-header class="card-header">
                <mat-card-title class="card-title">{{PartnerMgmtEnum.partner_heading_partner_details}}</mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content class="idn-view-body">
                <div class="card-content">
    
                    <div class="idn-view-body-items">
                        <div class="idn-view-body-items-label">
                            {{PartnerMgmtEnum.partner_column_partner_name}}
                        </div>
                        <div class="idn-view-body-items-data">{{ViewData.name}}</div>
                    </div>
                    <div class="idn-view-body-items">
                        <div class="idn-view-body-items-label">
                          {{PartnerMgmtEnum.partner_column_created_by}}</div>
                        <div class="idn-view-body-items-data">{{ViewData.createdByDisplayName}}</div>
                    </div>
    
                    <div class="idn-view-body-items">
                        <div class="idn-view-body-items-label">
                          {{PartnerMgmtEnum.partner_column_created_on}}</div>
                        <div class="idn-view-body-items-data">{{ ViewData.createdAt | date: "dd MMM, yyyy 'at' HH:mm:ss '(UTC)'" }}</div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    
        <mat-card class="idn-acc-list-card-1">
            <mat-card-header class="card-header">
                <mat-card-title class="card-title">
                  {{PartnerMgmtEnum.partner_heading_partner_users}}
                </mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content class="acc-count-chips card-content">
                <span class="count-label">{{PartnerMgmtEnum.partner_label_user_limit}}</span>
                <span class="count">{{ViewData?.usersCount}}/{{ViewData?.partnerUserQuota}}</span>
            </mat-card-content>
        </mat-card>
  
        <mat-card class="idn-acc-list-card-2">
            <mat-card-content class="idn-view-body-description">
                <mat-icon>info</mat-icon>
                <div class="provision-desc">
                    <span>{{PartnerMgmtEnum.partner_userlimit_msg}}</span>
                </div>
                
            </mat-card-content>
        </mat-card>

    </div>
     <div class="idn-view-wrapper">
      <div class="idn-view-body" *ngIf="ViewData">
        <div class="idn-view-body-items">
          <div class="idn-view-body-items-label">{{PartnerMgmtEnum.partner_label_code}}</div>
          <div class="idn-view-body-items-data">{{ViewData.partnerCode}}</div>
        </div>
        <div class="idn-view-body-items">
          <div class="idn-view-body-items-label">{{PartnerMgmtEnum.partner_label_name}}</div>
          <div class="idn-view-body-items-data">{{ViewData.name}}</div>
        </div>
        <div class="idn-view-body-items">
          <div class="idn-view-body-items-label">{{PartnerMgmtEnum.partner_label_description}}</div>
          <div class="idn-view-body-items-data">{{ViewData.description}}</div>
        </div>
        <div class="idn-view-body-items">
          <div class="idn-view-body-items-label">{{PartnerMgmtEnum.partner_label_region}}</div>
          <div class="idn-view-body-items-data">{{ViewData.region}}</div>
        </div>
        <div class="idn-view-body-items">
          <div class="idn-view-body-items-label">{{PartnerMgmtEnum.partner_label_status}}</div>
          <div class="idn-view-body-items-data">
            <span [ngClass]="ViewData.status ? 'active': 'in-active'">{{ViewData.status ? 'Active' : 'In-Active'}}</span>
          </div>
        </div>
  
      </div>
     </div>
    
  </div>
  <div class="pss-form-button-wrapper">
    <button mat-stroked-button class="pss-btn-danger" (click)="deactivatePartner()" *ngIf="!IsActive && dectivatePartner">
      <mat-icon>block</mat-icon> <span>{{PartnerMgmtEnum.partner_deactivate_btn}}</span>
    </button>
    <button mat-stroked-button class="pss-btn-success" (click)="activatePartner()"  *ngIf="IsActive && activatePartnerPermission">
      <mat-icon>check</mat-icon> <span>{{PartnerMgmtEnum.partner_activate_btn}}</span>
    </button>
    <button mat-stroked-button (click)="onCancel()" >{{PartnerMgmtEnum.partner_cancel_btn}}</button>
  </div>
