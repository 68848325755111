<app-pageheader class="pageheader" [pageTitle]="'Instance Requests'" [mapRole]="true" [applyFilter]="false"
    [clearFilter]="false" [archive]="false" [breadCrumbs]="this.breadCrumbItems"></app-pageheader>
<div class="page-container">
    <div class="idn-acc-wrapper">
        <mat-card class="idn-acc-list-card">
            <mat-card-header class="card-header">
                <mat-card-title class="card-title">{{instanceEnum.workerTask_card_header_instance}}</mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content class="idn-view-body">
                <div class="card-content">
    
                    <div class="idn-view-body-items" id="customer_block">
                        <div class="idn-view-body-items-label" >
                            {{instanceEnum.instanceTools_CustomerName_label}}
                        </div>
                        <div class="idn-view-body-items-data" matTooltip="{{ViewData.customerName}}" matTooltipPosition="below" matTooltipClass="my-tooltip">{{ViewData.customerName}}</div>
                    </div>
                    <div class="idn-view-body-items" id="cname_block">
                        <div class="idn-view-body-items-label" >
                            {{instanceEnum.instanceTools_Cname_label}}</div>
                        <div class="idn-view-body-items-data" matTooltip="{{ViewData.cname}}" matTooltipPosition="below" matTooltipClass="my-tooltip">{{ViewData.cname}}</div>
                    </div>
    
                    <div class="idn-view-body-items">
                        <div class="idn-view-body-items-label">
                            {{instanceEnum.instanceTools_instanceType_label}}</div>
                        <div class="idn-view-body-items-data" matTooltip="{{ViewData.instanceType}}" matTooltipPosition="below" matTooltipClass="my-tooltip">{{ViewData.instanceType}}</div>
                    </div>
    
                    <div class="idn-view-body-items">
                        <div class="idn-view-body-items-label">
                            {{instanceEnum.instanceTools_iciversion_lable}}</div>
                        <div class="idn-view-body-items-data" matTooltip="{{ViewData.iciVersion}}" matTooltipPosition="below" matTooltipClass="my-tooltip">{{ViewData.iciVersion}}</div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    
    </div>
        <div class="form-container form-wrapper" *ngIf="selectedTaskId != null">
            <div class="title">
                <h2 *ngIf="selectedTaskId == eventTaskId">{{instanceEnum.workerTask_option_event_task_header}}</h2>
                <h2 *ngIf="selectedTaskId == scheduledTaskId">{{instanceEnum.workerTask_option_schedule_task_header}}</h2>
                <div class="decrypt-name">
                    <mat-slide-toggle [(ngModel)]="isDecrypted" [ngModelOptions]="{standalone: true}" color="primary"  (click)="$event.stopPropagation();">{{instanceEnum.workertask_form_field_use_decryption}}</mat-slide-toggle>
                    <mat-icon class="c-info" matTooltip="{{instanceEnum.instanceTools_worker_task_useDecryption_description}}"  matTooltipClass="my-tooltip" matTooltipPosition="above">info</mat-icon>
                </div>
            </div>
            <form class="pss-dashboard-form" [formGroup]="frmTask">
                <div class="pss-left-panel">
                    <mat-form-field class="pss-block-form">
                        <mat-label>ID</mat-label>
                        <input type="text" matInput minlength="4" maxlength="6" placeholder="Enter task ID" formControlName="id"  required>
                        <mat-error *ngIf="frmTask.controls.id.invalid">{{instanceEnum.workertask_message_valid_id}}</mat-error>
                    </mat-form-field>
                    <mat-form-field class="pss-block-form" *ngIf="selectedTaskId == scheduledTaskId">
                        <mat-label>{{instanceEnum.workertask_form_field_cron_expression}}</mat-label>
                        <input type="text" matInput maxlength="50" placeholder="Cron schedule (e.g., 0 12 * * ?)" [(ngModel)]="cronExpression" [ngModelOptions]="{standalone: true}" required>
                      </mat-form-field>
                    <mat-form-field class="pss-block-form">
                        <mat-label>{{instanceEnum.workertask_form_field_description}}</mat-label>
                        <textarea matInput maxlength="500" placeholder="Enter the Task Description" formControlName="description" class="idn-min-textarea-height"></textarea>
                        <mat-error *ngIf="frmTask.controls.description.invalid">{{errorDescription}} </mat-error>
                    </mat-form-field>
                </div>
                <div class="pss-right-panel">
                    <mat-form-field class="pss-block-form">
                        <mat-label>{{instanceEnum.workertask_form_field_name}}</mat-label>
                        <input type="text" matInput maxlength="50" placeholder="Enter task name upto 50 characters" formControlName="name" required>
                        <mat-error *ngIf="frmTask.controls.name.invalid">{{instanceEnum.workertask_message_valid_name}}</mat-error>
                    </mat-form-field>
                    <mat-form-field class="pss-block-form">
                        <mat-label>{{instanceEnum.workertask_form_field_type}}</mat-label>
                        <input type="text" matInput maxlength="500" placeholder="Enter task type (e.g., Icertis.CLM.Tasks.Notification.SendMailTask, Icertis.CLM.Tasks)" formControlName="type" required>
                      </mat-form-field>
                </div>
            </form>      
        </div>
        <div class="table-card">
            <h3>Task Configuration</h3>
            <div class="table-configuration" *ngIf="selectedTaskId == eventTaskId">
                <span class="table-title">{{instanceEnum.workertask_form_field_interest_configuration}}</span>
                <div class="table-scroll editable" >
                    <table #tableData mat-table *ngIf="dataSource" [dataSource]="dataSource" matSort style="width: 100%"
                        class="idn-grid-position">

                        <!-- key Column -->
                        <ng-container matColumnDef="Name">
                            <th mat-header-cell *matHeaderCellDef aria-required="true">
                                <span matTooltip="{{instanceEnum.workertask_interest_configuration_field_name_hover}}" matTooltipPosition="above" matTooltipClass="my-tooltip">{{instanceEnum.workertask_interest_configuration_field_name}}</span><sup class="sup-required">*</sup>
                            </th>
                            <td mat-cell *matCellDef="let element;let i=index">
                                <input type="text" matInput maxlength="100" placeholder="Event name" title="Event name" [(ngModel)]="interestConfiguration[i]['eventTaskInterestName']" [ngModelOptions]="{standalone: true}"
                                    required="true">
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Topic Name">
                            <th mat-header-cell *matHeaderCellDef>
                                <span matTooltip="{{instanceEnum.workertask_interest_configuration_field_topic_name_hover}}" matTooltipPosition="above" matTooltipClass="my-tooltip">{{instanceEnum.workertask_interest_configuration_field_topic_name}}</span> <sup class="sup-required">*</sup>
                            </th>
                            <td mat-cell *matCellDef="let element;let i=index">
                                <input type="text" matInput maxlength="100" placeholder="Topic name (e.g., Agreement)" title="Topic name (e.g., Agreement)" [(ngModel)]="interestConfiguration[i]['eventTaskInterestTopicName']" [ngModelOptions]="{standalone: true}"
                                    required>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Subscription Name">
                            <th mat-header-cell *matHeaderCellDef>
                                <span matTooltip="{{instanceEnum.workertask_interest_configuration_field_subscription_name_hover}}" matTooltipPosition="above" matTooltipClass="my-tooltip">{{instanceEnum.workertask_interest_configuration_field_subscription_name}}</span><sup class="sup-required">*</sup>
                            </th>
                            <td mat-cell *matCellDef="let element;let i=index">
                                <input type="text" matInput maxlength="100" placeholder="Subscription name (e.g., AgreementDeleted)" title="Subscription name (e.g., AgreementDeleted)" [(ngModel)]="interestConfiguration[i]['eventTaskInterestSubscriptionName']" [ngModelOptions]="{standalone: true}"
                                    required>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Parallel Workers">
                            <th mat-header-cell *matHeaderCellDef>
                                <span matTooltip="{{instanceEnum.workertask_interest_configuration_field_parallel_workers_hover}}" matTooltipPosition="above" matTooltipClass="my-tooltip">{{instanceEnum.workertask_interest_configuration_field_parallel_workers}}</span>
                            </th>
                            <td mat-cell *matCellDef="let element;let i=index">
                                <mat-slide-toggle [(ngModel)]="interestConfiguration[i]['eventTaskInterestParellelWorkers']" [ngModelOptions]="{standalone: true}" color="primary"  (click)="$event.stopPropagation();">{{interestConfiguration[i]['eventTaskInterestParellelWorkers']? 'On': 'Off' }}</mat-slide-toggle>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Filter">
                            <th mat-header-cell *matHeaderCellDef>
                                <span matTooltip="{{instanceEnum.workertask_interest_configuration_field_filter_hover}}" matTooltipPosition="above" matTooltipClass="my-tooltip">{{instanceEnum.workertask_interest_configuration_field_filter}}</span>
                            </th>
                            <td mat-cell *matCellDef="let element;let i=index">
                                <input type="text" matInput maxlength="100" placeholder="Filter condition (e.g., Action = 'Deleted')" title="Filter condition (e.g., Action = 'Deleted')" [(ngModel)]="interestConfiguration[i]['eventTaskInterestFilter']" [ngModelOptions]="{standalone: true}"
                                    required>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef>
                                {{instanceEnum.workertask_configuration_field_action}}
                            </th>
                            <td mat-cell *matCellDef="let row; let i = index; let last=last">
                                <mat-icon *ngIf="interestConfiguration.length>1" mat-raised-button color="primary"
                                    (click)="deleteRow(row)">cancel</mat-icon>
                                <mat-icon  *ngIf="last" 
                                    mat-raised-button color="primary" (click)="addRow(row);">add_box</mat-icon>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns:displayedColumns;"></tr>
                    </table>
                </div>
            </div>

            <div class="table-configuration">
                <span class="table-title">{{instanceEnum.workertask_form_field_setting_configuration}}</span>
                <div class="table-scroll editable" >
                    <table #tableData mat-table *ngIf="dataSource1" [dataSource]="dataSource1" matSort style="width: 100%"
                        class="idn-grid-position">

                        <!-- key Column -->
                        <ng-container matColumnDef="Name">
                            <th mat-header-cell *matHeaderCellDef>
                                <span matTooltip="{{instanceEnum.workertask_setting_configuration_field_name_hover}}" matTooltipPosition="above" matTooltipClass="my-tooltip">{{instanceEnum.workertask_setting_configuration_field_name}}</span>
                            </th>
                            <td mat-cell *matCellDef="let element;let i=index">
                                <input type="text" matInput maxlength="100" placeholder="Event name" title="Event name" [(ngModel)]="settingConfiguration[i]['taskSettingName']" [ngModelOptions]="{standalone: true}"
                                    required>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="isEncrypted">
                            <th mat-header-cell *matHeaderCellDef>
                                <span matTooltip="{{instanceEnum.workertask_setting_configuration_field_is_encrypted_hover}}" matTooltipPosition="above" matTooltipClass="my-tooltip">{{instanceEnum.workertask_setting_configuration_field_is_encrypted}}</span>
                            </th>
                            <td mat-cell *matCellDef="let element;let i=index">
                                <mat-slide-toggle [(ngModel)]="settingConfiguration[i]['taskSettingIsEncrypted']" [ngModelOptions]="{standalone: true}" color="primary"  (click)="$event.stopPropagation();">{{settingConfiguration[i]['taskSettingIsEncrypted'] ? 'Yes' : 'No'}}</mat-slide-toggle>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef>
                                {{instanceEnum.workertask_configuration_field_action}}
                            </th>
                            <td mat-cell *matCellDef="let row; let i = index; let last=last">
                                <mat-icon *ngIf="settingConfiguration.length>1" mat-raised-button color="primary"
                                    (click)="deleteSettingConfigurationRow(row)">cancel</mat-icon>
                                <mat-icon  *ngIf="last" 
                                    mat-raised-button color="primary" (click)="addSettingConfigurationRow(row);">add_box</mat-icon>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns1; sticky: true">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns:displayedColumns1;"></tr>
                    </table>
                </div>
            </div>
        </div>
    <!-- </div> -->
</div>
<div class="pss-form-button-wrapper">
    <button mat-raised-button color="primary" (click)="onSubmit()" [disabled]="validateForm()">{{instanceEnum.workertask_button_submit}}</button>


    <button mat-stroked-button (click)="onCancel()">{{instanceEnum.workertask_button_cancel}}</button>
</div>