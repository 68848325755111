import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InstanceManagementRoutingModule } from './instance-management-routing.module';
import { InstanceListComponent } from './instance-list/instance-list.component';
import { MaterialModule } from 'src/app/material.module';
import { PageHeaderModule } from '../pageheader/pageheader.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatIconModule } from '@angular/material/icon';
import { InstancetoolsHistoryComponent } from './instance-tools/instancetools-history/instancetools-history.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { ListIciWorkertaskComponent } from './ici-workertask-configuration/list-ici-workertask/list-ici-workertask.component';
import { ViewIciWorkertaskComponent } from './ici-workertask-configuration/view-ici-workertask/view-ici-workertask.component';
import { SharedComponentsModule } from 'src/app/shared/sharedComponents/sharedcomponents.module';
import { EditIciWorkertaskComponent } from './ici-workertask-configuration/edit-ici-workertask/edit-ici-workertask.component';


@NgModule({
  declarations: [  
    InstancetoolsHistoryComponent,
    ListIciWorkertaskComponent,
    ViewIciWorkertaskComponent,
    EditIciWorkertaskComponent,
  ],
  imports: [
    CommonModule,
    InstanceManagementRoutingModule,
    MaterialModule,
    PageHeaderModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    NgxMatSelectSearchModule,
    MatSelectModule,
    SharedComponentsModule,
    FormsModule,
  ],
  exports: [
    //InstanceListComponent
  ]
})
export class InstanceManagementModule { }
