import { LiveAnnouncer } from '@angular/cdk/a11y';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MultiTenantPodService } from 'src/app/core/services/multiTenantPod-management/multi-tenant-pod.service';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';
import { MultiTenantPods } from 'src/app/shared/enum/multi-tenant-pods';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { enableDisableIDNFeatures } from 'src/app/shared/enum/disable-idn-feature';
import { NotificationComponent } from 'src/app/shared/notification/notification-component/notification.component';
import { NotificationService } from 'src/app/shared/notification/notification.service';

@Component({
  selector: 'app-multi-tenantpod-list',
  templateUrl: './multi-tenantpod-list.component.html',
  styleUrls: ['./multi-tenantpod-list.component.scss'],
})
export class MultiTenantpodListComponent implements OnInit {
  @ViewChild('search_input') inputName: any;
  @ViewChild('select_IciVersion') filterIciVersion!: MatSelect;
  @ViewChild('select_Region') filterRegion!: MatSelect;
  @ViewChild('select_UpgradeStatus') filterUpgradeStatus!: MatSelect;
  displayedColumns: string[] = [
    'deploymentRegion',
    'subscription',
    'mastertenantcname',
    'iciversion',
    'targeticiversion',
    'upgradedate',
    'upgradestatus',
    'action',
  ];
  public multiTenantPods = MultiTenantPods;
  public enableDisableIdnFeaturesEnum = enableDisableIDNFeatures;
  sortBy: string = '';
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pageNumber = 1;
  filter: string = '';
  iciVersion: string = '';
  region: any = '';
  upgradeStatus: string = '';
  pageLength: any = 0;
  selectAll = 'All';
  dataSource: any = [];
  globalList: any;
  noContent: Boolean = false;
  enablePreviewDrawer: boolean = false;
  isViewPreviewUpgrade: boolean = false;
  upgradeStatusList: any = [
    { displayName: "Completed", id: 1 },
    { displayName: "Partially Completed", id: 2 },
    { displayName: "Planned", id: 3 },
    { displayName: "Failed", id: 4 },
    { displayName: "Not Started", id: 5 },
    { displayName: "Not Applicable", id: 6 },
  ]
  referenceData: any = { deploymentRegions: [], iciVersions: [], upgradeStatusList: this.upgradeStatusList };

  breadCrumbItems = [
    { label: 'Home', path: 'resources' },
    { label: 'Upgrades', path: 'upgrade/multi-tenantPod-management' },
    { label: 'Customer Package Upgrades', path: 'upgrade/multi-tenantPod-management' },
    { label: 'List', path: 'upgrade/multi-tenantPod-management' },
  ];
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;

  @ViewChild(MatSort) sort: MatSort | undefined;
  @ViewChild('empTbSort') empTbSort = new MatSort();
  isViewMultiTenantPOD: boolean = false;
  podDetails: any;
  enableLaActivity: any = false;
  constructor(
    private multiTenatPodService: MultiTenantPodService,
    private permissionService: PermissionsService,
    private titleService: Title,
    private router: Router,
    private dialogService: DialogService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    let disableTenantUpgradeFeature = localStorage.getItem('featuresTobeDisabled');
    if (!disableTenantUpgradeFeature?.includes(this.enableDisableIdnFeaturesEnum.enableLaActivity)) {
      this.enableLaActivity = true;
    } else {
      this.enableLaActivity = false;
    }
    this.isViewMultiTenantPOD =
      this.permissionService.getPermission('ViewMultiTenantPOD');
    this.isViewPreviewUpgrade = this.permissionService.getPermission(
      'PreviewMultiTenantPODUpgrade'
    );
    this.titleService.setTitle('Customer Package Upgrades | IDN');
    this.getMTPodReferenceData();
    this.getAllMultiPods();
  }

  public doFilter = () => {
    this.paginator.firstPage();
    let filters = localStorage.getItem('FilterRecordMtpod');
    if (filters != null) {
      let Json = {
        search: this.inputName.nativeElement.value,
        iciVersion:
          this.filterIciVersion.value == undefined ||
            this.filterIciVersion.value == this.selectAll
            ? ''
            : this.filterIciVersion.value,
        region:
          this.filterRegion.value == undefined ||
            this.filterRegion.value == this.selectAll
            ? ''
            : this.filterRegion.value,
        upgradeStatus:
          this.filterUpgradeStatus.value == undefined ||
            this.filterUpgradeStatus.value == this.selectAll
            ? ''
            : this.filterUpgradeStatus.value,
        pagesize: this.pageSize,
        pageNumber: this.pageNumber,
        currentPage: this.currentPage,
      };
      localStorage.setItem('FilterRecordMtpod', JSON.stringify(Json));
    } else {
      this.filter = this.inputName.nativeElement.value;
      this.iciVersion =
        this.filterIciVersion.value == undefined ||
          this.filterIciVersion.value == this.selectAll
          ? ''
          : this.filterIciVersion.value;
      this.region =
        this.filterRegion.value == undefined ||
          this.filterRegion.value == this.selectAll
          ? ''
          : this.filterRegion.value;
      this.upgradeStatus =
        this.filterUpgradeStatus.value == undefined ||
          this.filterUpgradeStatus.value == this.selectAll
          ? ''
          : this.filterUpgradeStatus.value;
    }
    this.getAllMultiPods();
  };

  clearFilter() {
    localStorage.removeItem('FilterRecordMtpod');
    this.inputName.nativeElement.value = '';
    this.filterRegion.value = '';
    this.filterIciVersion.value = '';
    this.filterUpgradeStatus.value = '';
    this.pageNumber = 1;
    this.pageSize = 10;
    this.currentPage = 0;
    this.doFilter();
  }

  ClearInput(event: any, filter: any) {
    this.inputName.nativeElement.value = '';
    this.doFilter();
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    if (sortState.direction == 'desc') {
      this.sortBy = sortState.active + '_' + sortState.direction;
    } else {
      this.sortBy = sortState.active;
    }
    this.getAllMultiPods();
  }

  getAllMultiPods() {
    try {
      let filterValue: any = localStorage.getItem('FilterRecordMtpod');
      if (
        filterValue != undefined &&
        filterValue != null &&
        filterValue != ''
      ) {
        this.filter = JSON.parse(filterValue).search;
        // this.inputName.nativeElement.value = this.filter;
        this.iciVersion =
          JSON.parse(filterValue).iciVersion != undefined &&
            JSON.parse(filterValue).iciVersion != this.selectAll
            ? JSON.parse(filterValue).iciVersion
            : '';
        this.region = this.filterRegion =
          JSON.parse(filterValue).region != undefined &&
            JSON.parse(filterValue).region != this.selectAll
            ? JSON.parse(filterValue).region
            : '';
        this.upgradeStatus =
          JSON.parse(filterValue).upgradeStatus != undefined &&
            JSON.parse(filterValue).upgradeStatus != this.selectAll
            ? JSON.parse(filterValue).upgradeStatus
            : '';
        this.pageSize = JSON.parse(filterValue).pagesize;
        this.pageNumber = JSON.parse(filterValue).pageNumber;
        this.currentPage = JSON.parse(filterValue).currentPage;
      }
      let filterResult: any = [];
      this.multiTenatPodService
        .getMultiTenantPODList(
          this.filter,
          this.sortBy,
          this.pageNumber,
          this.pageSize,
          this.iciVersion,
          this.region,
          this.upgradeStatus
        )
        .subscribe((resp: any) => {
          if (resp != undefined && resp != null) {
            this.noContent = false;
            filterResult = resp.body.records;
            this.paginator.length = resp.body.matchingCount;
          } else {
            this.noContent = true;
            filterResult = [];
          }
          this.dataSource = new MatTableDataSource<any>(filterResult);
          this.empTbSort.disableClear = true;
          this.paginator.pageIndex = this.currentPage;
          this.dataSource.sort = this.sortBy;
        });
    } catch (error) {
      console.log(error);
    }
  }

  getMTPodReferenceData() {
    this.multiTenatPodService.GetMTPODReferenceData().subscribe((resp) => {
      this.referenceData.deploymentRegions = resp.body.deploymentRegions;
      this.referenceData.iciVersions = resp.body.iciVersions;
      this.referenceData.iciVersions = this.referenceData.iciVersions?.sort((a: any, b: any) => { return b.localeCompare(a, undefined, { numeric: true, sensitivity: 'base' }); });
    });
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.pageNumber = event.pageIndex + 1;
    let filters = localStorage.getItem('FilterRecordMtpod');
    if (filters != null) {
      let Json = {
        search: this.inputName.nativeElement.value,
        iciVersion:
          this.filterIciVersion.value == undefined ||
            this.filterIciVersion.value == this.selectAll
            ? ''
            : this.filterIciVersion.value,
        region:
          this.filterRegion.value == undefined ||
            this.filterRegion.value == this.selectAll
            ? ''
            : this.filterRegion.value,
        upgradeStatus:
          this.filterUpgradeStatus.value == undefined ||
            this.filterUpgradeStatus.value == this.selectAll
            ? ''
            : this.filterUpgradeStatus.value,
        pagesize: this.pageSize,
        pageNumber: this.pageNumber,
        currentPage: this.currentPage,
      };
      localStorage.setItem('FilterRecordMtpod', JSON.stringify(Json));
    }

    this.getAllMultiPods();
  }

  viewMultiTenant(navLink: any, element: any) {
    let Json = {
      search: this.filter,
      iciVersion: this.iciVersion,
      region: this.region,
      pagesize: this.pageSize,
      pageNumber: this.pageNumber,
      currentPage: this.currentPage,
    };
    localStorage.setItem('FilterRecordMtpod', JSON.stringify(Json));
    localStorage.setItem('podId', element.id);
    let instance: any = this.isViewMultiTenantPOD
      ? 'multi-tenantPod-management'
      : 'multi-tenantPod-management';
    navLink = 'home/upgrade/' + instance + '/' + navLink;
    this.router.navigate([navLink]);
  }

  upgradePod(event: any) {
    this.podDetails = event;
    this.enablePreviewDrawer = true;
  }

  closedrawer(event: any) {
    this.enablePreviewDrawer = false;
  }

  upgradeTenantPod(ele: any) {
    this.dialogService
      .confirmDialogComments({
        title: "Confirm Package Upgrade",
        module: 'upgradeActivity',
        message: ele?.id,
        confirmText: 'Confirm',
        cancelText: 'Cancel',
      })
      .subscribe((res: any) => {
        if (res) {
          this.multiTenatPodService.CompatibleUpgradePackage(res.selectedIciVersion, ele?.id).subscribe((resp: any) => {
            if (resp.isSuccessful == true) {
              this.notificationService.showNotification(this.multiTenantPods.la_activity_success_body, 'success', this.multiTenantPods.la_activity_success_header);
            }
            else {
              let errormsg = resp.message;
              this.notificationService.showNotification(errormsg, 'error', this.multiTenantPods.la_activity_failed_header)
            }
          });
        }
      });
  }
}
