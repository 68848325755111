<app-idn-search-box (searchText)="onSearchData($event)"></app-idn-search-box>
<div class="idn-checkbox-list-data">
    <mat-checkbox class="master-check" [checked]="allComplete" color="primary" (change)="setAll($event.checked)">
        All
    </mat-checkbox>
    <div class="subtask-check" *ngFor="let data of dataSet.data">
        <mat-checkbox [(ngModel)]="data.completed" color="primary" (ngModelChange)="updateAllComplete($event)">
            {{data.name? data.name:data.productName}}
        </mat-checkbox>
    </div>
</div>