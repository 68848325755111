<app-pageheader [breadCrumbs]="this.breadCrumbItems" [auditLog]="false" [createRole]="false" [mapRole]="false"
        [applyFilter]="false" [clearFilter]="false" [archive]="false" ></app-pageheader>

<div class="table-header">
  {{this.sharedEnum.approval_request_header}}
  <mat-chip  class="count" role="none" aria-label="Count" >{{count}}</mat-chip>
</div>
<div class="idn-filter" >
  <form class="idn-grid-filter">
    <app-idn-search-box (searchText)="onSearchData($event)" [reference]="referenceName"></app-idn-search-box>
    <mat-form-field *ngIf="requestStatus?.length > 1">
        <mat-label>{{this.sharedEnum.request_type_label}}</mat-label>
        <mat-select #requesttype (selectionChange)="doFilter()">
          <mat-option [value]= "selectAll"> All</mat-option>
          <mat-option *ngFor="let reqstatus of requestStatus" [value]="reqstatus.id">
            {{reqstatus.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="select-status">
        <mat-label>Status</mat-label>
        <mat-select #requeststatus (selectionChange)="doFilter()">
          <mat-option [value]= "selectAll"> All</mat-option>
          <mat-option *ngFor="let status of statusList" [value]="status.id">
            {{status.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
  </form>
  <button mat-stroked-button color="primary" (click)="clearFilter()">
    <mat-icon class="icon-btn-spacer">filter_list_off</mat-icon>
    <span>Clear Filter</span>
  </button>
</div>
<div class="idn-table">
    <app-idn-table  class="logs-table" [data]="viewApprovalListData"  (viewButton)="ViewRequestDetails($event)" (onPageChanged)="pageChanged($event)" (onSort)="announceSortChange($event)"></app-idn-table>
</div>
   