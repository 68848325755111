import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MultiTenantPods } from 'src/app/shared/enum/multi-tenant-pods';
import { Router } from '@angular/router';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { SelectionModel } from '@angular/cdk/collections';
import { ThemePalette } from '@angular/material/core';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MultiTenantPodService } from 'src/app/core/services/multiTenantPod-management/multi-tenant-pod.service';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { enableDisableIDNFeatures } from 'src/app/shared/enum/disable-idn-feature';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { MatSelect } from '@angular/material/select';
import { MatSort, Sort } from '@angular/material/sort';

export interface extensions {
  select: boolean;
  instanceName: string;
  extensionName: string;
  version: string;
  targetIciVersion: string;
  deployed: string;
  compatible: string;
}

@Component({
  selector: 'app-pod-details',
  templateUrl: './view-multi-tenant-pod.component.html',
  styleUrls: ['./view-multi-tenant-pod.component.scss'],
})
export class ViewMultiTenantPodComponent implements OnInit {
  @ViewChild('search_input') inputName: any;
  @ViewChild('select_emailSent') emailSentFilter!: MatSelect;
  filter: string = '';
  podDetails: any;
  breadCrumbItems: any;
  isEditInstance: boolean = false;
  ViewData: any = {};
  PackageData: any = {};
  isApproveInstance: boolean = true;
  // dataSource:any;
  public mtPodEnum = MultiTenantPods;
  public enableDisableIdnFeaturesEnum = enableDisableIDNFeatures;
  displayedColumnsAdmin: string[] = [
    'instanceName',
    'solutionPackageName',
    'iciVersion',
    'isCompatible',
    'emailRecipient'
  ];
  displayedColumnsOps: string[] = [
    'instanceName',
    'solutionPackageName',
    'iciVersion',
    'isCompatible',
    'emailRecipient'
  ];
  region: string = ' ';
  podName: string = '';
  subscription: string = '';
  iciVersion: string = '';
  podId: number = 0;

  dataSource: any = [];
  selection = new SelectionModel<extensions>(true, []);
  color: ThemePalette = 'accent';
  noContent: Boolean = true;
  pageSize: number = 5;
  pageLength: any = 0;
  currentPage: number = 0;
  pageNumber: number = 1;
  enablePreviewDrawer: boolean = false;
  enableLaActivity: any = false;

  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort) sort: MatSort | undefined;
  @ViewChild('empTbSort') empTbSort = new MatSort();
  isViewPreviewUpgrade: boolean = false;
  isViewMtpodSnapshot: boolean = false;
  emailSent: any = '';
  emailRecipientList: boolean = false;
  emailRecipientData:any={
    title: 'Email Recipient',    
    list: [],
 };

  constructor(
    private router: Router,
    private multiTenatPodService: MultiTenantPodService,
    private permissionService: PermissionsService,
    private dialogService: DialogService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    let disableTenantUpgradeFeature = localStorage.getItem('featuresTobeDisabled');
    if (!disableTenantUpgradeFeature?.includes(this.enableDisableIdnFeaturesEnum.enableLaActivity)) {
      this.enableLaActivity = true;
    } else {
      this.enableLaActivity = false;
    }
    let multiTenantPods: string;
    let mtPodPath: string;
    multiTenantPods = 'Customer Package Upgrades';
    mtPodPath = 'upgrade/multi-tenantPod-management';
    this.isViewMtpodSnapshot = this.permissionService.getPermission(
      'ViewMultiTenantPODSnapshot'
    );
    this.isViewPreviewUpgrade = this.permissionService.getPermission(
      'PreviewMultiTenantPODUpgrade'
    );

    this.breadCrumbItems = [
      { label: 'Home', path: 'resources' },
      { label: 'Upgrades', path: mtPodPath },
      { label: multiTenantPods, path: mtPodPath },
      { label: 'View', path: 'multi-tenantPod-management' },
    ];

    this.podId = Number(localStorage.getItem('podId'));
    this.getMultiPodDetails();
  }

  getMultiPodDetails() {
    try {
      this.multiTenatPodService
        .getMultiTenantPODDetails(this.podId)
        .subscribe((resp: any) => {
          let result = resp.body;
          if (result != undefined && result != null) {
            this.ViewData = resp.body;
            this.podDetails = this.ViewData;
            this.mapMtPodDetails(resp.body);
            this.getMultiPodSnapshot();
          } else {
            this.ViewData = [];
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  getMultiPodSnapshot() {
    try {
      let filterResult: any = [];
      if (this.isViewMtpodSnapshot) {
        this.multiTenatPodService
          .getMultiTenantPODSnapShot(this.podId, this.ViewData?.targetVersion, this.filter, this.emailSent)
          .subscribe((resp: any) => {
            if (resp != undefined && resp != null) {
              this.noContent = false;
              filterResult = resp.body.records;
              filterResult = filterResult.sort((a: any, b: any) =>
                a.instanceName.localeCompare(b.instanceName)
              );
              this.PackageData = filterResult;
            } else {
              this.noContent = true;
              this.dataSource = [];
            }
            this.dataSource = new MatTableDataSource<any>(filterResult);
            this.dataSource.paginator = this.paginator;
            this.paginator.pageIndex = this.currentPage;
            this.dataSource.sort = this.sort;
            this.paginator.length = resp.body.matchingCount;
            this.empTbSort.disableClear = true;
          });
      }
    } catch (error) {
      console.log(error);
    }
  }

  mapMtPodDetails(podDetails: any) {
    this.podName = podDetails.masterTenantCname;
    this.region = podDetails.deploymentRegion;
    this.subscription = podDetails.subscription;
    this.iciVersion = podDetails.iciVersion;
  }

  upgradeMultiTenant() {
    this.enablePreviewDrawer = true;
  }

  closedrawer(event: any) {
    this.enablePreviewDrawer = false;
  }

  cancel() {
    this.router.navigate(['home/upgrade/multi-tenantPod-management']);
  }

  announceSortChange(column: any) {
    let sortedData = [];
    let initialData = JSON.parse(JSON.stringify(this.PackageData));   
      let columnName = column.active;
      if(column.active != 'isCompatible'){
      if(column.direction == 'asc') {
        sortedData = initialData?.sort((a: any, b: any)=>{ return a[columnName]?.toLowerCase().localeCompare(b[columnName]?.toLowerCase()) });
      }else if(column.direction == 'desc'){
        sortedData = initialData?.sort((a: any, b: any)=>{ return b[columnName]?.toLowerCase().localeCompare(a[columnName]?.toLowerCase()) });
      } else {
        sortedData = this.PackageData;
      }
      this.dataSource = new MatTableDataSource<any>(sortedData);
    } 
          
    this.paginator? this.paginator.length = this.dataSource?.length :'';
    this.paginator.pageIndex = this.currentPage;
    this.pageNumber = this.currentPage + 1;
    this.dataSource.paginator = this.paginator;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    const myval = this.selection.selected.values;
    return numSelected === numRows;
  }
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row: extensions) => {
        this.selection.select(row);
      });
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.pageNumber = event.pageIndex + 1;
  }

  selectRow(row: extensions) {
    this.selection.toggle(row);
  }

  upgradeTenantPod(ele: any) {
    this.dialogService
      .confirmDialogComments({
        title: "Confirm Package Upgrade",
        module: 'upgradeActivity',
        message: ele?.id,
        confirmText: 'Confirm',
        cancelText: 'Cancel',
      })
      .subscribe((res: any) => {
        if (res) {
          this.multiTenatPodService.CompatibleUpgradePackage(res.selectedIciVersion, ele?.id).subscribe((resp: any) => {
            if (resp.isSuccessful == true) {
              this.notificationService.showNotification(this.mtPodEnum.la_activity_success_body, 'success', this.mtPodEnum.la_activity_success_header);
            }
            else {
              let errormsg = resp.message;
              this.notificationService.showNotification(errormsg, 'error', this.mtPodEnum.la_activity_failed_header)
            }
          });
        }
      });
  }

  public doFilter = () => {
    this.paginator.firstPage();
    this.filter = this.inputName.nativeElement.value;
    this.emailSent = this.emailSentFilter.value == undefined || this.emailSentFilter?.value == "selectAll" ? '' : this.emailSentFilter.value;
    this.getMultiPodSnapshot();
  };

  ClearInput(event: any, filter: any) {
    this.inputName.nativeElement.value = '';
    this.doFilter();
  }

  clearFilter() {
    this.inputName.nativeElement.value = '';
    this.emailSentFilter.value = '';
    this.pageNumber = 1;
    this.pageSize = 5;
    this.currentPage = 0;
    this.doFilter();
  }

  isTooltipDisabled(e: any) {
    return e.scrollWidth <= e.clientWidth;
  }

  closeListDrawer(event:any){
    if(event == false){
      this.emailRecipientList = event
    } else{
      this.emailRecipientList = true;
      this.emailRecipientData = {
        title:"Email Recipient",      
        list: event.emailRecipientDetails
      }     
    }
  }
}
