<app-pageheader [breadCrumbs]="this.breadCrumbItems" [archive]="false" ></app-pageheader>
<div class="page-container">
    <div class="idn-view-wrapper">
      <div class="idn-view-header">
        <div class="data-left">
          <div class="idn-view-header-label">{{ this.viewData?.featureCategoryId == this.featureCategories.certificationRequest? "Certification" : this.sharedEnum.view_page_instance_heading}} :</div>
          <div class="idn-view-header-data">
            <h3>{{this.viewData?.featureCategoryId == this.featureCategories.certificationRequest? viewData?.entityName :viewData?.instanceCName}}</h3>
            <em><b>Created on: {{viewData?.requestedAt | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'" }} by {{viewData?.requestedBy}}</b></em>
          </div>
        </div>
      </div>
    <div class="pss-steps-container">
        <div class="idn-view-body">
            <mat-grid-list cols="4" rowHeight="80px">
                <mat-grid-tile [colspan]="1">
                    <div class="idn-view-body-items">
                        <div class="idn-view-body-items-label">{{this.sharedEnum.view_approval_request_heading}}</div>
                        <div class="idn-view-body-items-data"><span *ngIf="viewData?.featureCategoryId">{{viewData?.featureCategory}}</span></div> 
                    </div>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="1">
                    <div class="idn-view-body-items">
                        <div class="idn-view-body-items-label">{{this.sharedEnum.view_partnerName_label}}</div>
                        <div class="idn-view-body-items-data">
                            <div class="overflow-data" matTooltip="{{this.viewData?.partnerName[0]? this.viewData?.partnerName[0] : ''}}"
                                  matTooltipClass="my-tooltip" matTooltipPosition="below">{{this.viewData?.partnerName[0]?  this.viewData?.partnerName[0] : 'NA'}}</div><a *ngIf="this.viewData?.partnerName?.length > 0" aria-label="View partners" class="idn-grid-rec-count"
                                  href="javascript:void (0)" (click)="closeListDrawer(this.viewData)">{{0 > this.viewData?.partnerName?.length - 1
                                  ? '' : this.viewData?.partnerName?.length - 1 > 0 ? '+'+(this.viewData?.partnerName?.length - 1) : ''}}</a>
                              </div>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="1">
                    <div class="idn-view-body-items">
                        <div class="idn-view-body-items-label">{{this.sharedEnum.view_customerName_label}}</div>
                        <div class="idn-view-body-items-data">{{ viewData?.customerName}} </div>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="1">
                    <div class="idn-view-body-items">
                        <div class="idn-view-body-items-label">{{this.sharedEnum.cName_label}}</div>
                        <div class="idn-view-body-items-data">{{ viewData?.entityName }}</div>
                    </div>
                </mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="4" rowHeight="80px">
                <mat-grid-tile [colspan]="1">
                    <div class="idn-view-body-items">
                        <div class="idn-view-body-items-label">{{this.sharedEnum.requestedBy_label}}</div>
                        <div class="idn-view-body-items-data">
                            <div class="request-by">
                                <span class="view-user-info">{{viewData?.requestedBy}}</span>
                                <span class="view-timestamp">on {{viewData?.requestedAt | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'"}}</span>
                            </div>
                        </div>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="1" *ngIf="featureCategoryId == featureCategories.debuggingUtilities">
                    <div class="idn-view-body-items">
                        <div class="idn-view-body-items-label">{{this.sharedEnum.duration_label}}</div>
                        <div  *ngIf="viewData?.statusId != approvalStatus.approved" class="idn-view-body-items-data">{{viewData?.requestedDuration}} Hours</div>
                        <div *ngIf="viewData?.statusId == approvalStatus.approved" class="idn-view-body-items-data">{{viewData?.approvedDuration}} Hours</div>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="1">
                    <div class="idn-view-body-items">
                        <div class="idn-view-body-items-label">{{this.sharedEnum.status_label}}</div>
                        <div class="idn-view-body-items-data'">
                            <div class="status-div">
                                <span class="approved-cls" *ngIf="viewData?.statusId == approvalStatus.pending "><mat-icon class="pending">hourglass_empty</mat-icon></span>
                                <span class="approved-cls" *ngIf="viewData?.statusId == approvalStatus.approved "><mat-icon class="approved">check_circle_outline</mat-icon></span>
                                <span class="approved-cls"*ngIf="viewData?.statusId == approvalStatus.rejected "><mat-icon class="rejected">cancel</mat-icon></span> 
                             {{viewData?.status}} 
                            </div>                            
                        </div>
                    </div>
                </mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="4" rowHeight="80px" *ngIf="!takeMeThereButtonVisibility?.includes(viewData?.featureCategoryId)">
                <mat-grid-tile [colspan]="3">
                    <div class="idn-view-body-items">
                        <div class="idn-view-body-items-label">{{this.sharedEnum.requester_comment_label}}</div>
                        <div class="idn-view-body-items-data">{{viewData?.requesterComment}}</div>
                    </div>
                </mat-grid-tile>
            </mat-grid-list>
        </div>
    </div>
    <div class="pss-form-button-wrapper view-request-footer"> 
        <button mat-raised-button color="primary" *ngIf="viewData?.statusId == approvalStatus.pending && (approveHangfire || approveDebuggingUtility) &&  !takeMeThereButtonVisibility?.includes(viewData?.featureCategoryId)" mat-button (click)="approveRequest()">
            {{this.sharedEnum.approve_btn_label}}
        </button>
        <button class="reject-btn" *ngIf="viewData?.statusId == approvalStatus.pending && (approveHangfire || approveDebuggingUtility) &&  !takeMeThereButtonVisibility?.includes(viewData?.featureCategoryId)" mat-button (click)="rejectRequest()">
            {{this.sharedEnum.reject_btn_label}}
        </button>
        <button mat-raised-button color="primary" *ngIf="viewData?.statusId == approvalStatus.pending && takeMeThereButtonVisibility?.includes(viewData?.featureCategoryId) && (isApproveRejectInstance || isApproveRejectDeployment || isApproveRejectCertification)" mat-button (click)="navigateToView()">
            {{this.sharedEnum.take_me_there_btn_label}}
        </button>
        <button class="cancel-btn" mat-button (click)="cancelRequest()">
            {{this.sharedEnum.cancel_btn_label}}
        </button>
    </div>

<app-idn-list-drawer *ngIf="listPartner" [data]="partnerData" (closeEvent)="closeListDrawer($event)"></app-idn-list-drawer>
