import { Component, OnInit, ViewChild } from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import { AuditLogService } from 'src/app/core/services/audit-log/audit-log.service';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { FormControl, FormGroup } from '@angular/forms';
import { DateFilterPipe } from 'src/app/core/Pipes/dateFIlter/date-filter.pipe';


interface Option {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-audit-log',
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.scss'],
  providers:[DateFilterPipe],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AuditLogComponent implements OnInit {
  displayedColumns: string[] = ['type', 'name', 'email', 'dateTime', 'action'];
  columnsToDisplay = ['event', 'old', 'new',];
  dataSource :any
  dataSourceDetails :any;
  isDetailsDrawer: boolean = false;
  panelOpenState = false;
  @ViewChild(MatSort) sort: MatSort | undefined;
  @ViewChild(MatPaginator, { static: true })
  paginator!: MatPaginator;
  @ViewChild('empTbSort') empTbSort = new MatSort();
  eventArray:any
  auditData : any = []
  auditDataNewValues:any={}
  auditDataOldValues:any={};
  keys :any = []
  newKeysArr :any = []
  @ViewChild('search') inputName:any;
   //listing
  filterVal: string = "";
  sortBy: string = "";
  pageNumber:number = 1;
  pageSize :number= 10;
  currentPage :number= 0;
  pageLength: number= 0;
  maxDate :any=new Date();
  @ViewChild('start') start: any;
  @ViewChild('end') end: any;
  @ViewChild('month') mont !:MatSelect;

  list:any=[]
  isInstanceToolsEvent:boolean=false;
  globalList: any;
  breadCrumbItems:any=[]
  usrCustLabel:string =''
  isCloudOpsAuditLog:boolean=false;
  notificationDateFrom: string = "";
  notificationDateTo: string = "";
  auditDetailsUserTitle :String = "";
  infraDBScheduleEntry:string = 'InfraDB_ScheduleEntry';
  constructor( 
    private auditservice : AuditLogService,
    private dateFilter : DateFilterPipe) { }

  auditFilter: FormGroup = new FormGroup({
    byMonths: new FormControl(''),
    start: new FormControl(''),
    end: new FormControl('')
  });

  ngOnInit(): void {   
    if(localStorage.getItem('auditLogData') == "sku"){
      this.breadCrumbItems = [
        { label: "Home", path: "resources" },
        { label: localStorage.getItem('auditLogData')+ ' Management', path: localStorage.getItem('auditLogData')+'-management/sku-master'},
        { label:  'SKU Master', path: localStorage.getItem('auditLogData')+'-management/sku-master'},
        { label: "Audit Log", path: "audit-log" }
      ];
    } else if(localStorage.getItem('auditLogData') == "release-request"){
      this.breadCrumbItems = [
        { label: "Home", path: "resources" },
        { label: 'SKU Management', path: 'sku-management/release-request'},
        { label:  'Release Request', path: 'sku-management/release-request'},
        { label: "Audit Log", path: "audit-log" } 
      ];
    }else if(localStorage.getItem('auditLogData') == "mtpodupgradecalendar"){
      this.breadCrumbItems = [
        { label: "Home", path: "resources" },
        { label: "Upgrades", path: "upgrade/pod-upgrade" },
        { label: 'Pod Upgrade Calendar', path: 'upgrade/pod-upgrade'},
        { label: "Audit Log", path: "audit-log" } 
      ];
    }else if(localStorage.getItem('auditLogData') == "idn-workbench"){
      this.breadCrumbItems = [
        { label: "Home", path: "resources" },
        { label: "Development and Utilities", path: "developmentandutilities/idn-workbench-management" },
        { label: 'IDN Workbench', path: 'developmentandutilities/idn-workbench-management'},
        { label: "Audit Log", path: "audit-log" }
      ];
    }else if(localStorage.getItem('auditLogData') == "certification"){
      this.breadCrumbItems = [
        { label: "Home", path: "resources" },
        { label: 'Development and Utilities', path: 'developmentandutilities/certification-request'},
        { label: 'Certification Requests', path: 'developmentandutilities/certification-request'},
        { label: "Audit Log", path: "audit-log" }
      ];
    }
    else if(localStorage.getItem('auditLogData') == "deploymentrequest"){
      this.breadCrumbItems = [
        { label: "Home", path: "resources" },
        { label: 'Instance Operations', path: 'instanceoperations/deployment'},
        { label: 'Deployment Requests', path: 'instanceoperations/deployment'},
        { label: "Audit Log", path: "audit-log" }
      ];
    }
    else if(localStorage.getItem('auditLogData') == "instance"){
      this.breadCrumbItems = [
        { label: "Home", path: "resources" },
        { label: 'Instance Operations', path: 'instanceoperations/instance-requests'},
        { label: localStorage.getItem('auditLogData')+ ' Requests', path: 'instanceoperations/instance-requests'},
        { label: "Audit Log", path: "audit-log" }
      ];
    }
    else if(localStorage.getItem('auditLogData') == "mtpodupgrade"){
      this.breadCrumbItems = [
        { label: "Home", path: "resources" },
        { label: 'Upgrades', path: 'upgrade/multi-tenantPod-management' },
        { label: 'Customer Package Upgrades', path: 'upgrade/multi-tenantPod-management'},
        { label: "Audit Log", path: "audit-log" }
      ];
    }
    else if(localStorage.getItem('auditLogData') == "ICIDeploymentRequest"){
      this.breadCrumbItems = [
        { label: "Home", path: "resources" },
        { label: 'SKU Management', path: 'sku-management/ici-component-deployment'},
        { label: 'ICI SKU Deployment', path: 'sku-management/ici-component-deployment'},
        { label: "Audit Log", path: "audit-log" }
      ];
    } else if(localStorage.getItem('auditLogData') == "ICIWorkerTaskConfiguration"){
      this.breadCrumbItems = [
        { label: 'Home', path: 'resources' },
        {label: 'Instance Operations', path: 'instanceoperations/instance-requests'},
        {label: 'Instance Requests', path: 'instanceoperations/instance-requests'},
        { label: 'Instance Tools',path: 'instanceoperations/instance-requests/instance-tools-landing'},
        {label: 'ICI Worker Tasks', path: 'instanceoperations/instance-requests/ici-workertask-configuration/list'},
        { label: "Audit Log", path: "audit-log" },
      ];
    }else if(localStorage.getItem('auditLogData') == "extension"){
      this.breadCrumbItems = [
        { label: "Home", path: "resources" },
        { label: 'Development and Utilities', path: 'developmentandutilities/extension-management'},
        { label: 'Extension Management', path: 'developmentandutilities/extension-management'},
        { label: "Audit Log", path: "audit-log" }
      ];
    }else if(localStorage.getItem('auditLogData') == "role"){
      this.breadCrumbItems = [
        { label: "Home", path: "resources" },
        { label: "Administration", path: "administration/role-management" },
        { label: "Role Management", path: "administration/role-management" },
        { label: "Audit Log", path: "audit-log" }
      ];
    }else if(localStorage.getItem('auditLogData') == "partner"){
      this.breadCrumbItems = [
        { label: "Home", path: "resources" },
        { label: "Administration", path: "administration/partner-management" },
        { label: "Partner Management", path: "administration/partner-management" },
        { label: "Audit Log", path: "audit-log" }
      ];
    }
    else if(localStorage.getItem('auditLogData') == "customer"){
      this.breadCrumbItems = [
        { label: "Home", path: "resources" },
        { label: "Administration", path: "administration/customer-management" },
        { label: "Customer Management", path: "administration/customer-management" },
        { label: "Audit Log", path: "audit-log" }
      ];
    }
    else if(localStorage.getItem('auditLogData') == "user"){
      this.breadCrumbItems = [
        { label: "Home", path: "resources" },
        { label: "Administration", path: "administration/user-management" },
        { label: "User Management", path: "administration/user-management" },
        { label: "Audit Log", path: "audit-log" }
      ];
    }
  
    else{
      this.breadCrumbItems = [
        { label: "Home", path: "resources" },
        { label: localStorage.getItem('auditLogData')+ ' Management', path: localStorage.getItem('auditLogData')+'-management'},
        { label: "Audit Log", path: "audit-log" }
      ];
    }
    this.getAuditListbyID(localStorage.getItem('auditLogData'))
  }

  ngOnDestroy(): void {
    localStorage.removeItem('auditLogData');
  }

  options: Option[] = [

    { value: '30-days', viewValue: 'Last 30 Days' },

    { value: '3-months', viewValue: 'Last 3 Months' },

    { value: '6-months', viewValue: 'Last 6 Months' },

  ];

  clearInputFields(e:string){
    switch(e){
        case 'byMonthsAudit':
          this.start.nativeElement.value = ""
          this.end.nativeElement.value = ""
          this.auditFilter.patchValue({
            start: "" ,
            end: ""
          })
          break;
        case 'datePickerAudit':
          this.mont.value=null
          this.auditFilter.patchValue({
            byMonths: ""
          })
          break;
    }
  }
  toggleDetailsDrawer(){
    this.isDetailsDrawer = !this.isDetailsDrawer;
  }
  toggleDetailsDrawer1(e:any){
    this.auditDetailsUserTitle = "";
    this.isDetailsDrawer = !this.isDetailsDrawer;
      this.auditData=e
      if(this.auditData.tableName == "UserCustomer" && this.auditData.type=="Created"){
            this.usrCustLabel = "Customer added to the user"
      }else if(this.auditData.tableName == "UserCustomer" && this.auditData.type=="Deleted"){
        this.usrCustLabel = "Customer removed for the user"
      }else{
        this.usrCustLabel =''
      }
      this.auditDataNewValues= e.newValues == "" ? e.newValues : JSON.parse(e.newValues);
      this.auditDataOldValues= e.oldValues == "" ? e.oldValues : JSON.parse(e.oldValues);
      if(this.auditData.tableName == "SolutionCertification"){
        this.formatDateData('ReviewerApprovedOn');
        this.formatDateData('ReviewerInitiatedOn');
        this.formatDateData('RequestedOn');
      }
      this.keys = this.auditDataNewValues =="" ?Object.keys(this.auditDataOldValues):Object.keys(this.auditDataNewValues);
    
      if(this.auditDataNewValues.hasOwnProperty('Title') || this.auditDataOldValues.hasOwnProperty('Title')){
          this.auditDetailsUserTitle = this.auditDataNewValues.Title != undefined ? this.auditDataNewValues.Title : this.auditDataOldValues.Title;
      }

      if (localStorage.getItem('auditLogData') == "instance" && 
          (this.auditData.type.includes('Downloaded') || this.auditData.type.includes('Role Restarted') || 
          this.auditData.type.includes('Redish Flushed') || this.auditData.type.includes('Task Restarted'))){      
            this.isInstanceToolsEvent=true;
      }else{
            this.isInstanceToolsEvent=false;
      }
     if(this.auditData.tableName == "Instance User Provisioned" && this.auditData.type=="Updated"){
      this.auditData.type ="";  
     }
      if(localStorage.getItem('auditLogData') == 'mtpodupgradecalendar' && this.auditData.type == 'CloudOps Notified'){
        this.isCloudOpsAuditLog = true;
      }
      else{
        this.isCloudOpsAuditLog = false;
      }
  }

  //Function to convert date from local to UTC using dateFilter Pipe
  formatDateData(eventType:any){
    if(this.auditDataNewValues != ""  && this.auditDataNewValues.hasOwnProperty(eventType)){
      this.auditDataNewValues[eventType] = this.dateFilter.transform(this.formatDateforPipe(this.auditDataNewValues[eventType]));
    }
    if(this.auditDataOldValues!= "" && this.auditDataOldValues.hasOwnProperty(eventType)){
      this.auditDataOldValues[eventType] = this.dateFilter.transform(this.formatDateforPipe(this.auditDataOldValues[eventType]));
    }
  }

  //Function to check the date format and convert it to UTC.
  formatDateforPipe(date:any){
    if(date != undefined && !date.includes('Z')){
      return date+'Z';
    }else{
      return date;
    }
  }

  getAuditListbyID(id:any){
    try{
    this.auditservice.getAuditListById(id,this.pageNumber, this.pageSize, this.filterVal, this.sortBy,this.notificationDateFrom,this.notificationDateTo).subscribe((resp: any) => {
      if (resp.status == 200) {
        this.dataSource = new MatTableDataSource(resp.body.records);
        this.empTbSort.disableClear = true;
        this.paginator.pageIndex = this.currentPage;  //for listing
        this.paginator.length = resp.body.matchingCount;  //for listing
        this.empTbSort.disableClear = true;
        this.dataSource.sort = this.empTbSort;
        this.globalList = resp.body.records;
      }
      else{
        this.dataSource = new MatTableDataSource([]);
        this.empTbSort.disableClear = true;
        this.paginator.pageIndex = this.currentPage;  //for listing
        this.paginator.length = 0;  //for listing
        this.empTbSort.disableClear = true;
        this.dataSource.sort = this.empTbSort;
        this.globalList = [];
      }
      
      this.dataSource.filteredData.forEach((element:any)=>{
          if(element.tableName=="SolutionPackage")
              element.tableName= "Customer Extension Package";
      });

    });
  } catch (exception) {
    console.log(exception);
  }
  


  }

  public doFilter = (event: any, name: string) => {
    const cd = new Date();
    this.pageNumber = 1;
    this.pageSize = 10;
    this.currentPage = 0;
    switch (name) {
      case 'search':
        this.filterVal = this.inputName.nativeElement.value;
        this.getAuditListbyID(localStorage.getItem('auditLogData'));
        break;
        case 'chooseList':
          if (this.mont.value == "30-days") {
            this.notificationDateTo = this.formatDate(cd);
            cd.setDate(cd.getDate() - 30)
            this.notificationDateFrom = this.formatDate(cd);
            this.getAuditListbyID(localStorage.getItem('auditLogData'));
  
          } else if (this.mont.value == "3-months") {
            this.notificationDateTo = this.formatDate(cd);
            cd.setDate(cd.getDate() - 90)
            this.notificationDateFrom = this.formatDate(cd);
            this.getAuditListbyID(localStorage.getItem('auditLogData'));
  
          } else if (this.mont.value == "6-months") {
            this.notificationDateTo = this.formatDate(cd);
            cd.setDate(cd.getDate() - 180)
            this.notificationDateFrom = this.formatDate(cd);
            this.getAuditListbyID(localStorage.getItem('auditLogData'));
  
          }
          break;
          case 'customDate':
            if (this.auditFilter.value.start != "" && this.auditFilter.value.end != "" && this.auditFilter.value.start != null && this.auditFilter.value.end != null) {
              this.notificationDateFrom = this.formatDate(this.auditFilter.value.start);
              this.notificationDateTo = this.formatDate(this.auditFilter.value.end);
              this.getAuditListbyID(localStorage.getItem('auditLogData'));
    
            }
            break;
      default:
        break;
    }
  }

  clearFilter() {
    this.auditFilter.patchValue({
      byMonths: "",
      start: "",
      end: ""
    })
    this.inputName.nativeElement.value = ''
    this.mont.value = ''
    this.filterVal = "";
    this.sortBy = "";
    this.pageNumber = 1;
    this.pageSize = 10;
    this.currentPage = 0;
    this.notificationDateFrom = "";
    this.notificationDateTo = "";
    this.getAuditListbyID(localStorage.getItem('auditLogData'));
  }

  ClearInput() {
    this.inputName.nativeElement.value = '';
    this.filterVal ='';
    this.sortBy = "";
    this.pageNumber = 1;
    this.pageSize = 10;
    this.currentPage = 0;
    this.getAuditListbyID(localStorage.getItem('auditLogData'));
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    if (event.pageIndex > this.currentPage) {
      if (Math.floor(event.length / event.pageSize) == event.pageIndex) {
        this.pageNumber = event.pageIndex + 1
        this.currentPage = event.pageIndex
      }
      else {
        this.pageNumber++;
        this.currentPage++;
      }
    } else {
      if (event.pageIndex == 0) {
        this.pageNumber = 1;
        this.currentPage = 0;
      }
      else {
        this.pageNumber--;
        this.currentPage--;
      }
    }
    this.getAuditListbyID(localStorage.getItem('auditLogData'));
  }

      /** Announce the change in sort state for assistive technology. */
    announceSortChange(sortState: Sort) {
      this.sortBy = sortState.active + '_' + sortState.direction;
      this.getAuditListbyID(localStorage.getItem('auditLogData'));
    }

    formatDate(date: any) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
  
      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
  
      return [year, month, day].join('-');
    }

    isDisabled(e: any) {
      return e.scrollWidth <= e.clientWidth;
    }
}
