import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpEvent,
  HttpResponse
} from '@angular/common/http';
import { from } from 'rxjs';
import { lastValueFrom, Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PermissionsService } from '../../services/common/permissions.service';
import { CacheService } from '../../services/cache/cache.service';

@Injectable({
  providedIn: 'root'

})
export class ValidateUserInterceptor implements HttpInterceptor {
  baseApiUrl: string = environment.appGatewayUrl;
  headers: any =  environment.headers;
  cachedUrl : any = ["api/Instance/",
    "api/Customer/GetCustomerDeploymentSnapshot",
    "api/ICITool/EsignProviderDetails",
     "api/MTPodUpgradeCalendar/MTPods",
     "api/User/UserCategories",
     "api/User/Partners",
     "api/User/UserRoles",
     "api/User/Customers"
  ]
  notStoredUrl: any =[   
    "api/Instance/GetFeatureAccessRequestDetails",
    "api/Instance/InstanceHealth",
    "api/Instance/GetInstanceRequests"
  ]

  constructor(
    private permissionService: PermissionsService,
    private cacheService: CacheService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // If the request is a GET request, we can cache the response   
    let isIncluded = this.cachedUrl.some((link:any)=> {return req.url.includes(link)})
    let isExcluded = this.notStoredUrl.some((link:any)=> {return req.url.includes(link)})
    
    if (req.method === 'GET' && isIncluded && !isExcluded) {
      // Check if the response is already in the cache
      const cachedResponse = this.cacheService.get(req.urlWithParams);      
      if (cachedResponse) {
        // Return cached response if available
        return new Observable(observer => {
          observer.next(new HttpResponse({ body: cachedResponse, status: 200 }));
          observer.complete();
        });
      }

      // If not in cache, proceed with the HTTP request
      return next.handle(req).pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            // Store the response in the cache
            this.cacheService.set(req.urlWithParams, event.body);
          }
        })
      );
    }

    // If it's not a GET request, just pass it through
    return next.handle(req);
  }

  async handle(request: HttpRequest<any>, next: HttpHandler) {
    let isActive;
    let isTermsAccepted;
    //Check if reqsuest is POST or PUT and also Check if it is authenticatedUser or not
    if ((request.method === 'POST' || request.method === 'PUT') && localStorage.getItem("authenticatedUser") != null && localStorage.getItem("authenticatedUser") != undefined) {
      isActive = this.permissionService.isActiveUser();
      isTermsAccepted = this.permissionService.isTermsAccepted();
    }

    //Check if user is active or not, if not then redirect to unauthorized page otherwise pass the request.
    if (isActive != undefined && isActive) {
      return await lastValueFrom(next.handle(request))
    } else if (isActive != undefined) {
      return await lastValueFrom(next.handle(request))
    } else {
      return await lastValueFrom(next.handle(request))
    }

  }
}

