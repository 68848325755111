import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { IdnConfigurationService } from 'src/app/core/services/common/idn-configuration.service';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';
import { LogoutString } from 'src/app/shared/enum/logoutString';
import { fromEvent } from 'rxjs';
import { map, throttleTime } from 'rxjs/operators';
import { SharedComponent } from 'src/app/shared/enum/sharedComponentEnum';
@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {
  progress:number = 20;
  isPartnerDeveloper:boolean = false;
  isPartnerAdmin:boolean = false;
  isViewInstance:boolean =false;
  isViewExtension:boolean =false;
  partnerAccess:boolean =false;
  whatsNewMessage = "What's new in IDN!";
  public logoutEnum = LogoutString;
  public sharedEnum = SharedComponent;
  questions:any;
  isShow: boolean=false;
  topPosToStartShowing = 0;
  setInterval = setInterval;
  url: string = "https://developer-help.icertis.com/idn-1-x-release-notes/";
  constructor(
    private titleService: Title,
    private dialogService: DialogService,
    private router: Router,
    private permissionService: PermissionsService,
    private idnConfiguration:IdnConfigurationService,
  ) { }
  

  ngOnInit(): void {
    this.questions=[{question: this.sharedEnum.starter_faq_question1, answer:this.sharedEnum.starter_faq_answer1},
    {question:this.sharedEnum.starter_faq_question2, answer:this.sharedEnum.starter_faq_answer2},
    {question:this.sharedEnum.starter_faq_question3, answer:this.sharedEnum.starter_faq_answer3},
    {question:this.sharedEnum.starter_faq_question4, answer:this.sharedEnum.starter_faq_answer4, link:"https://developer-help.icertis.com/docs/raising-tickets-in-servicenow/"},
    {question:this.sharedEnum.starter_faq_question5, answer:this.sharedEnum.starter_faq_answer5, link:"https://developer-help.icertis.com/docs/raising-tickets-in-servicenow/#deploy-to-production"},
  ]
    this.titleService.setTitle('Resources | Icertis Developer Network');
    this.permissionService.GetIDNConfiguration().subscribe(resp =>{
      this.resources[0].link = resp.body.content.idnHelpURL;
    });
    this.partnerAccess = this.permissionService.hasGlobalDataAccess();
    this.isViewInstance = this.permissionService.getPermission('ViewInstance');
    this.isViewExtension = this.permissionService.getPermission('ViewExtension');
    this.isPartnerAdmin = (!this.partnerAccess && this.isViewInstance && this.isViewExtension);
    this.isPartnerDeveloper = (!this.partnerAccess && this.isViewInstance && (this.isViewExtension == false));
    
  }

  breadCrumbItems: any = [
    { label: "Home", path: "resources"},
    { label: "Resources", path: "resources"}
  ];

  startGame() {
    if( this.progress <= 99){
      this.progress = this.progress + 1;
    }
    console.log (this.progress);
  }

  opensnack(progress: string) : void {
    console.log(progress);
  }

  resources: any = [
    { id: "1", heading: "IDN Help", image: "../../../assets/images/resources/tech_connect.jpg", link:'', description: "Get access to a library of Icertis learning material to help you get started"},
  ];

  redirectLink(navLink: any): void {

    this.dialogService.confirmDialog({
      title: this.logoutEnum.redirect_dialog_heading,
      module: 'resources',
      message: this.logoutEnum.redirect_dialog_body,
      confirmText: this.logoutEnum.redirect_dialog_primary_btn,
      cancelText: this.logoutEnum.redirect_dialog_secondary_btn
    }).subscribe((res: any) => {
      if(res){
        window.open(navLink, "_blank");
      }
    })
  }

  openSite(siteUrl:any) {
    this.dialogService.confirmDialog({
      title: this.logoutEnum.redirect_dialog_heading,
      module: 'resources',
      message: this.logoutEnum.redirect_dialog_body,
      confirmText: this.logoutEnum.redirect_dialog_primary_btn,
      cancelText: this.logoutEnum.redirect_dialog_secondary_btn
    }).subscribe((res: any) => {
      if(res){
        window.open(siteUrl, '_blank');
      }
    });
 }

 onWhatsNew() {
  this.idnConfiguration.emitWhatsNewFeature(true);
  }

  downloadFile(){
    let link = document.createElement("a");
    link.download = "Quick Start Quide";
    link.href = "assets/docs/Icertis Developer Network - Quick Start Guide - V1.0 2.pdf";
    link.click();
  }

  gotoTop(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }

  isElementVisible(element: HTMLElement) {
    var rect = element.getBoundingClientRect();
    var windowHeight = window.innerHeight || document.documentElement.clientHeight;
    return (
      rect?.top >= 0 &&
      rect?.bottom <= windowHeight
    );
  }

  ngAfterViewChecked(){
    let element = document?.getElementById('top-section')
    if(element){
      this.isShow = !this.isElementVisible(element);
    }
  }

}
