import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-idn-checkbox-list',
  templateUrl: './idn-checkbox-list.component.html',
  styleUrls: ['./idn-checkbox-list.component.scss']
})
export class IdnCheckboxListComponent implements OnInit {
  @Input() dataSet! :any;
    allComplete: any;
  productsData:boolean=false;
  recievedData: any;
  @Output() selectedData = new EventEmitter<any>();
  outputData: any =[];
  emptyFieldRegex = /<(.|\n)*?>/g;
  storeData: any;

  ngOnInit(): void {
    this.dataSet?.data?.forEach((data:any)=>{
      data['completed']=false;
    })
    this.recievedData = JSON.parse(JSON.stringify(this.dataSet.data));
    let data: any = localStorage.getItem('customerFilterData');
    this.storeData = JSON.parse(data);
    if (this.storeData) {
      this.dataSet?.data?.forEach((data: any) => {
        if ( this.dataSet.reference == 'regions' && this.storeData?.regionId?.includes(data.id) ) {
          data['completed'] = true;
        }
       else if(this.storeData?.skuId?.includes(data.skuId)){
          data['completed'] = true;
        }
      })
      this.allComplete = this.dataSet?.data != null && this.dataSet?.data?.every((task:any) => task.completed);
    }
  }

  ngOnChanges(): void {
    this.dataSet?.data?.forEach((data:any)=>{
      data['completed']=false;
    })
    if (this.storeData) {
      this.dataSet?.data?.forEach((data: any) => {
        if ( this.dataSet.reference == 'regions' && this.storeData?.regionId?.includes(data.id) ) {
          data['completed'] = true;
        }
        else if(this.storeData?.skuId?.includes(data.skuId)){
          data['completed'] = true;
        }
      })
      this.allComplete = this.dataSet?.data != null && this.dataSet?.data?.every((task:any) => task.completed);
    }
    
    this.recievedData = JSON.parse(JSON.stringify(this.dataSet.data));
  }

  updateAllComplete(event:any) {
    this.allComplete = this.dataSet?.data != null && this.dataSet?.data?.every((task:any) => task.completed);
    this.updateOriginalList()
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.dataSet.data == null) {
      return;
    }
    this.dataSet.data?.forEach((task:any) => (task.completed = completed));    
    this.updateOriginalList();     
  }

  onSearchData(data:any){
    if(data?.replace(this.emptyFieldRegex, '').trim().length !== 0){
      if (this.dataSet.reference == 'regions') {
        this.dataSet.data = this.recievedData.filter((x: any) =>
          x.name.toLowerCase().includes(data.toLowerCase())
        );
      } else {
        this.dataSet.data = this.recievedData.filter((x: any) =>
          x.productName.toLowerCase().includes(data.toLowerCase())
        );
      }
    }else{
      this.dataSet.data = this.recievedData;
      this.dataSet.data?.forEach((x: any) =>{
        if(this.outputData.includes(x.id)){
          x.completed = true;
        }
      });
      this.allComplete = this.dataSet?.data != null && this.dataSet?.data?.every((task:any) => task.completed);
    }
    
  }

  updateOriginalList(){
    this.recievedData.forEach((data:any, index:any) => {
      let item; 
      if(this.dataSet.reference == 'regions'){
        item = this.dataSet.data.find((item1:any) => item1.id === data.id);
      } else {
        item = this.dataSet.data.find((item1:any) => item1.skuId === data.skuId);
      }
      if (item) {
        this.recievedData[index] = { ...data, ...item };
      }
    });
    this.outputData = this.recievedData
      ?.filter((data: any) => {
        return data.completed;
      })
      ?.map((item: any) => {
        if(this.dataSet.reference == 'regions'){
          return item.id;
        } else {
          return item.skuId;
        }
      });
    this.selectedData.emit(this.outputData);
  }

}
