<app-pageheader class="pageheader" [pageTitle]="'Instance Requests'" [instanceTools]="true"
    [breadCrumbs]="this.breadCrumbItems">
</app-pageheader>
<div class="page-container">
    <div class="idn-acc-wrapper">
        <mat-card class="idn-acc-list-card">
            <mat-card-header class="card-header">
                <mat-card-title class="card-title">{{instanceEnum.instanceTools_landingPage_heading}}</mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content class="idn-view-body">
                <div class="card-content">

                    <div class="idn-view-body-items">
                        <div class="idn-view-body-items-label">
                            {{instanceEnum.instanceTools_CustomerName_label}}
                        </div>
                        <div class="idn-view-body-items-data">{{ViewData?.customerName}}</div>
                    </div>
                    <div class="idn-view-body-items">
                        <div class="idn-view-body-items-label">
                            {{instanceEnum.instanceTools_Cname_label}}</div>
                        <div class="idn-view-body-items-data">{{ViewData?.cname}}</div>
                    </div>

                    <div class="idn-view-body-items">
                        <div class="idn-view-body-items-label">
                            {{instanceEnum.instanceTools_instanceType_label}}</div>
                        <div class="idn-view-body-items-data">{{ViewData?.instanceType}}</div>
                    </div>

                    <div class="idn-view-body-items">
                        <div class="idn-view-body-items-label">
                            {{instanceEnum.instanceTools_iciversion_lable}}</div>
                        <div class="idn-view-body-items-data">{{ViewData?.iciVersion}}</div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

    </div>

<div class="rejected-div" *ngIf="(requestStatus == this.approvalStatus.Rejected) && !requestDebuggingUtilitiesAccess && !this.hasApprovalAccessToDebuggingUtilitiesProd">
    <div class="rejected-heading">{{instanceEnum.instanceTools_access_denied}}</div>
    <div class="reason-field">
        <div class="reason-lbl">{{instanceEnum.instanceTools_reason_lbl}}</div>
        <div>{{debuggingUtilitiesRequestDetails[0]?.approverComment}}</div>
    </div>
        <div class="request-access">
           <span class="request-access-text"> {{instanceEnum.instanceTools_requestAccess_msg}} </span>  <a href="#" (click)="requestAccess($event) ;$event.preventDefault(); $event.stopPropagation();"> {{instanceEnum.instanceTools_RequestAccess_btn}}</a>.
        </div>
</div>

    <div class="time_up" *ngIf="isTimeUp && (requestStatus != this.approvalStatus.Rejected || requestStatus != this.approvalStatus.Pending) && !this.hasApprovalAccessToDebuggingUtilitiesProd">
        <div>
            <mat-icon class="time-up-icon" svgIcon="time_up"></mat-icon>
            <div class="request-text">
                <div class="time-up-text">{{instanceEnum.instanceTools_RequestAccess_timeUp}}</div>
                <div class="request-link">
                    {{instanceEnum.instanceTools_restoreAccess_msg}}<a href="#" (click)="requestAccess($event);$event.preventDefault(); $event.stopPropagation();"> {{instanceEnum.instanceTools_RequestAccess_btn}}</a>.
                </div>
            </div>
        </div>
    
    </div>
    <div  *ngIf="requestDebuggingUtilitiesAccess && (requestStatus == null || requestStatus == this.approvalStatus.Rejected || requestStatus == this.approvalStatus.Approved) && !isTimeUp && (ViewData?.instanceType == instanceEnum.instance_constant_production && isViewICIToolLogActionsProd) && !this.hasApprovalAccessToDebuggingUtilitiesProd"> 
        <div class="request-info">
            <mat-icon class="c-info">info</mat-icon>
            <span class="info-msg">{{instanceEnum.instanceTools_requestAccess_message}}</span>
        </div>
        <div class="request-input">
            <mat-form-field class="pss-block-form">
                <mat-label>{{instanceEnum.instanceToos_selectHours_lbl}}</mat-label>
                <mat-select name="hours" [(value)]="selectedHours">
                    <mat-option *ngFor="let hours of duration" [value]="hours">
                        {{hours}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="pss-block-form">
                <mat-label class="mat-label-text">{{instanceEnum.instanceTools_reason_lbl}}</mat-label>
                <textarea type="text" [(ngModel)]="comments" name="comment" matInput required></textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="req-pending" *ngIf="(requestStatus == this.approvalStatus.Pending) && !this.hasApprovalAccessToDebuggingUtilitiesProd">
        <div>
            <div class="icon-cls">
                <mat-icon class="time-up-icon" svgIcon="request-pending"></mat-icon>
            </div>
            <div class="pending-text">
               {{instanceEnum.instanceTools_RequestAccessPending_msg}} <br> <b>{{instanceEnum.instanceTools_requestAcess_requestedOn}}</b> : {{debuggingUtilitiesRequestDetails[0].requestedAt | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'"}}
                <br><b>{{instanceEnum.instanceTools_requestAccess_requestedDuration}}</b> : {{ debuggingUtilitiesRequestDetails[0].requestedDuration}} hours.
            </div>
        </div>
    </div>
    <div>

        <div class="idn-carousel" *ngIf="(ViewData?.instanceType != instanceEnum.instance_constant_production && isViewICIToolLogActions) || (ViewData?.instanceType == instanceEnum.instance_constant_production && (hasApprovalAccessToDebuggingUtilitiesProd || (isViewICIToolLogActionsProd && (requestStatus == this.approvalStatus.Approved) && !isTimeUp && !requestDebuggingUtilitiesAccess)))"> 
          
           <div class="debug-header">
            <span class="actions-heading" *ngIf="(debuggingActions?.length > 0)">{{instanceEnum.instance_tool_debugging_utilities}}</span>
            <div class="time-left" *ngIf="requestStatus == this.approvalStatus.Approved && !isTimeUp && !hasApprovalAccessToDebuggingUtilitiesProd">
                <span class="time-left-lbl">{{instanceEnum.instanceTools_requestAccess_timeLeft}}</span>
            <span [ngClass]="highlightTimer ? 'lessTime': 'normal'" > {{countdown}} </span>
            </div>
           </div> 
            <div class="container">
                <div id="left-arrow" style="float: left">
                    <mat-icon (click)="scrollLeft('utilities')">chevron_left</mat-icon>
                </div>
        
                <div #widgetsContent class="middle">
                    <div class="info-widget"  *ngFor="let action of debuggingActions">
                        <div class="info-content">
                            <div class="content-text">
                                <div class="action-name">{{action.name}}<div class="info-icon"><mat-icon  matTooltip="{{action?.description}}"  matTooltipClass="my-tooltip" matTooltipPosition="above">info</mat-icon></div></div>
                                <div class="pss-chips-status" *ngIf="action.id != actions.configKey && action.id != actions.hangfireDashboard && action.id != actions.iciWorkerTask">
                                    <span  [ngClass]="action?.status == 'Success'? 'marker marker__active': action?.status == 'InProgress' ?'marker marker__in-progress' : action?.status == 'Failed'?'marker marker__failed':'marker marker__in-active' "  matTooltip="{{action?.status == 'InProgress'? 'In Progress' : action?.status}}"  matTooltipClass="my-tooltip" matTooltipPosition="above"></span>
                                    <span>{{action?.status == 'InProgress'? 'In Progress : ': 'last run : '}} {{action?.lastRun | date :  "dd MMM, yyyy"}}</span>
                                </div>
                            </div>
                            <div class="content-button">
                                <button mat-button class="idn-resources-button" (click)="navigateToolsPage(action)" *ngIf="action.id != actions.configKey &&  (action.id != actions.hangfireDashboard || (hangfireDashBoardRequestDetails?.length > 0 && hangfireDashBoardRequestDetails[0]?.featureCategoryId == featureCategories.hangfireDashboard && hangfireDashBoardRequestDetails[0]?.statusId != this.approvalStatus.Approved) || (debuggingUtilitiesRequestDetails?.length > 0 && debuggingUtilitiesRequestDetails[0]?.statusId == this.approvalStatus.Approved && !hangfireDashboardData) || (this.ViewData?.featureAccessRequests.length == 0) && (!hasApprovalAccessToDebuggingUtilitiesProd || !hasGlobalDataAccess)) && action.id != actions.iciWorkerTask">Request<mat-icon>arrow_right_alt</mat-icon></button>
                                <button mat-button class="idn-resources-button" *ngIf="action.id != actions.hangfireDashboard" (click)="navigateHistoryPage(action)">{{action.id == actions.configKey ? 'View Details' :action.id == actions.iciWorkerTask ? 'View Tasks' : 'View History'}}<mat-icon>arrow_right_alt</mat-icon></button>                               
                                <button mat-button class="idn-resources-button" (click)="navigateToolsPage(action)" *ngIf="action.id == actions.hangfireDashboard && (hangfireDashBoardRequestDetails?.length > 0 && hangfireDashBoardRequestDetails[0]?.featureCategoryId == featureCategories.hangfireDashboard && hangfireDashBoardRequestDetails[0]?.statusId == this.approvalStatus.Approved || (hasApprovalAccessToDebuggingUtilitiesProd || hasGlobalDataAccess))">View Dashboard<mat-icon>arrow_right_alt</mat-icon></button>
                            </div>
                        </div>
                    </div>
                </div>
        
                <div id="right-arrow" style="float: right">
                    <mat-icon (click)="scrollRight('utilities')">chevron_right</mat-icon>
                </div>
            </div>
        </div>
        <div class="idn-carousel" *ngIf="(isViewICIToolWriteActions && ViewData?.instanceType != instanceEnum.instance_constant_production) || (ViewData?.instanceType == instanceEnum.instance_constant_production && (hasApprovalAccessToDebuggingUtilitiesProd || (isViewICIToolWriteActionsProd && (featureCategoryId == featureCategories.debuggingUtilities &&requestStatus == this.approvalStatus.Approved) && !isTimeUp && !requestDebuggingUtilitiesAccess))) ">
            <span class="actions-heading" *ngIf="(instanceToolsActions?.length > 0)">{{instanceEnum.instance_tool_heading}}</span>
            <div class="container">
                <div id="left-arrow-tools" style="float: left">
                    <mat-icon (click)="scrollLeft('tools')">chevron_left</mat-icon>
                </div>
        
                <div #widgetsContentTools class="middle">
                    <div class="info-widget"  *ngFor="let action of instanceToolsActions">
                        <div class="info-content">
                            <div class="content-text">
                                <div class="action-name">{{action.name}}<div class="info-icon"><mat-icon matTooltip="{{action?.description}}" matTooltipClass="my-tooltip" matTooltipPosition="above">info</mat-icon></div></div>
                                <div class="pss-chips-status">
                                    <span  [ngClass]="action?.status == 'Success'? 'marker marker__active': action?.status == 'InProgress' ?'marker marker__in-progress' : action?.status == 'Failed'?'marker marker__failed':'marker marker__in-active' "  matTooltip="{{action?.status == 'InProgress'? 'In Progress' : action?.status}}"  matTooltipClass="my-tooltip" matTooltipPosition="above"></span>
                                    <span>{{action?.status == 'InProgress'? 'In Progress : ': 'last run : '}} {{action?.lastRun | date :  "dd MMM, yyyy"}}</span>
                                </div>
                            </div>
                            <div class="content-button">                                
                                <button mat-button class="idn-resources-button" (click)="navigateToolsPage(action)">Request<mat-icon>arrow_right_alt</mat-icon></button>
                                <button mat-button class="idn-resources-button" (click)="navigateHistoryPage(action)">View History<mat-icon>arrow_right_alt</mat-icon></button>
                            </div>
                        </div>
                    </div>
                </div>
        
                <div id="right-arrow-tools" style="float: right">
                    <mat-icon (click)="scrollRight('tools')">chevron_right</mat-icon>
                </div>
            </div>
        </div>

      
    </div>   
</div>
<div class="pss-form-button-wrapper">
    <button mat-raised-button color="primary" (click)="ConfirmAccessRequest($event)"  [disabled]="isRequestPending || validateRequest()">{{instanceEnum.instanceTools_confirm_btn}} </button>
    <!-- *ngIf="requestDebuggingUtilitiesAccess" -->
    <button mat-stroked-button (click)="Oncancel()">{{instanceEnum.instanceTools_cancel_btn}}</button>
</div>