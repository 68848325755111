import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { CertificationsService } from 'src/app/core/services/certifications-management/certifications.service';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { catchError, Subscription, throwError } from 'rxjs';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { AzureBlobStorageService } from 'src/app/core/services/azure-blob-storage/azure-blob-storage.service';
import { EnvService } from 'src/app/core/services/env.service';
import { Router } from '@angular/router';
import { CertificationManagement } from 'src/app/shared/enum/certificationManagement';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { QuillEditorComponent } from 'ngx-quill';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';
import { InstanceService } from 'src/app/core/services/instance-management/instance.service';
@Component({
  selector: 'app-update-certification',
  templateUrl: './update-certification.component.html',
  styleUrls: ['./update-certification.component.scss']
})
export class UpdateCertificationComponent implements OnInit {

  @ViewChild('drawer', { static: true }) public drawer!: MatDrawer;
  @ViewChild(QuillEditorComponent) editor!: QuillEditorComponent;
  @ViewChild('fileInput') fileInput!: ElementRef;
  fileAttr = "Choose File";
  panelOpenState = false;
  certificationDetailsData: any;
  certificationCheckList: any;
  certificationDetailsSubscription!: Subscription;
  certificationChecklistSubscription!: Subscription;
  //File Updload variables
  file: any;
  fileSizeLimit = this.env.fileSizeLimitCertifications;
  fileArray: any[] = [];
  fileNameArray:any[]=[];
  eventObject: any = {};
  uniqueIdentifier: any;
  sas: any;
  azUrl: any;
  errorMessage:any;
  comments: string = "";
  checkListValidation: Boolean = false;
  certificationEnum=CertificationManagement;
  certificationId: any;
  isApproveCertification: Boolean = false;
  isRejectCertification: Boolean = false;
  editorStyle = {
    height: '200px'
  };
  checkListComment:any=[];
  isEditorTouched: any = [];
  savedAnswers: any=[];
  selectedAnswer: any = [];
  config={
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        ['clean'],                                         // remove formatting button 
      ],
    },
  }
  enableDraft: boolean = false;
  emptyComment: boolean = false;
  commentRegex = /<(.|\n)*?>/g;
  
  constructor(private certificationsService: CertificationsService, 
    private notificationService: NotificationService, 
    private blobService: AzureBlobStorageService,
    private permissionService: PermissionsService,
    private env: EnvService, private router: Router,
    private dialogService: DialogService,
    private instanceService: InstanceService, ) { }

  ngOnInit(): void {
    this.isApproveCertification = this.permissionService.getPermission("ApproveCertification");
    this.isRejectCertification = this.permissionService.getPermission("RejectCertification");
    let self = this;
    this.certificationsService.setDrawer(this.drawer);
    setTimeout(function(){
      self.certificationsService.open();
    });
    this.certificationDetailsSubscription = this.certificationsService.onCertificationDetails<any>().subscribe(
      data => {
        if (data != null && data != "") {
          this.certificationId = data.id;
          this.savedAnswers = data.checkListItemAnswersList;
          this.comments = data.reviewerComment;
        } else {
          let certificationDetails = localStorage.getItem('certificationId');
          this.certificationId = certificationDetails
        }
        this.certificationsService.getCertificationDetails(this.certificationId).subscribe(response => {
          this.certificationDetailsData = response;
        })
      }
    );
    this.certificationChecklistSubscription = this.certificationsService.certificationChecklist().subscribe(response => {
      this.certificationCheckList = response;
      this.certificationCheckList.map((item: any) => {
        this.checklist.push({
              "checkListId": item.id,
              "answer": false,
              "comments": ''
            });
      });
      this.savedAnswers.forEach((list:any)=>{
        this.checkListComment[list.checkListId] = list.comments;
        this.selectedAnswer[list.checkListId] = list.answer;
        let inputList = {
          id:list.checkListId,
          answer: list.answer,
          comments:  list.comments
        }
        this.checkList(list.answer, inputList, list.comments);
    })
    });
    let _self = this;
    setTimeout(function(){
      if(_self.certificationDetailsSubscription) {
        _self.certificationDetailsSubscription.unsubscribe();
      }
    })
	}

  //Function for adding Aria-label to fix the accessability issues.
  onEditorCreated(editor: any) {
    const toolbarButtons = editor.getModule('toolbar').container.querySelectorAll('button');
    const toolbarColorSelect = editor.getModule('toolbar').container.querySelectorAll('span.ql-picker-label');
    toolbarButtons.forEach((button: any) => {
      button.setAttribute('aria-label', button.className);
    });
    toolbarColorSelect.forEach((select: any) => {
      select.setAttribute('aria-label', select.className);
    });
  }

  //Function to update the Final checklist
  checkList(select: any, list: any, checkListComment: any) {
    const objIndex = this.checklist.findIndex((item => item.checkListId == list.id));
    if(select !== ''){
      this.checklist[objIndex].answer = select
    }
    this.checklist[objIndex].comments = checkListComment;
    this.checkListValidation = this.checklist.every(item => (item.comments != null && item.comments != ''));
    this.enableDraft = this.checklist.some((item)=> (item.comments != null && item.comments != ''));
  }

  //Function to update comments
  updateComments(checked: any, list: any, comment: any){
    if(comment?.replace(this.commentRegex, '').trim().length === 0 && !comment.includes("<img")) {
      comment='';
      this.checkListComment[list.id]='';
    }
    this.checkList(checked, list, comment);
    
  }

  checklist: any[] = [];
  //Function to handle the slide action(yes or no)
  handleChange(event: any, list: any, checkListComment: any) {
      this.checkList(event.checked, list, checkListComment);
  }

  messageTemp: any;
  //Funtion to toggle the expansion panel for user to comment.
  toggleQuestion(comment: any, id: any) {
    this.messageTemp = comment;
  }

  //Function to access the file from the input and validate.
  uploadFileEvt(File: any) {
    if (File.target.files && File.target.files[0]) {
      this.file = File.target.files[0];
      if (this.file.type == "application/x-zip-compressed") {
        this.fileArray.push({ 'fileContent': this.file, 'fileName': this.file.name, 'isUploaded': false })
        var filesize = Number(((this.file.size / 1024) / 1024).toFixed(4));
        if(this.file.name.length >100){
          this.notificationService.showNotification('Filename is more than 100 chars', 'error', 'Filename length exceeded');
          return
        }
        if (filesize > this.fileSizeLimit) {
          this.notificationService.showNotification('File size is more than 5 MB', 'error', 'File size exceeded');
          return
        }
        this.fileAttr = '';
        this.fileAttr = File.target.files[0].name;
      }
      else {
        this.fileAttr = 'Choose File';
        this.fileInput.nativeElement.value = "";
        this.notificationService.showNotification(this.certificationEnum.certification_notify_invalidFileType_body, 'warning', this.certificationEnum.certification_notify_invalidFileType_heading);
      }
    } else {
      this.fileAttr = 'Choose File';
    }
  }

  //Function to asynchronously upload the file
  uploadFileAsynchronously() {
    this.setTokenForIdentifier();
    return new Promise(resolve => {
      setTimeout(() => {
        try {
          let counter = 0;
          this.fileArray.forEach((x: any) => {
            this.blobService.uploadFiles(this.sas.sasUrl, this.uniqueIdentifier, x.fileContent, x.fileName, (response: any) => {
              counter += 1;
              if (response._response != undefined) {
                this.azUrl = response._response.request.url;
                x["azUrl"] = this.azUrl;
                x.isUploaded = true;
                resolve("success")
              }
              else {
                resolve('Fail')
              }
            })
          })
      }
      catch (execption) {
        console.log(execption);
      }
      }, 1000);
    });
  }

  //funtion to set the sas token
  async setTokenForIdentifier() {
    try {
      await this.certificationsService.getUploadTokenForIdentifier(this.certificationDetailsData.id).subscribe(res => {
        if (res) {
          this.sas = res.body;
          this.uniqueIdentifier = this.certificationDetailsData.uniqueIdentifier;
        }
      })
    }
    catch (execption) {
      console.log(execption)
    }
  }

  //Function to approve and reject
  async onApproveOrReject(element: any) {
    let isFileUploaded = false;
    localStorage.setItem('certification_solution_id', this.certificationDetailsData.solutionId);
    if(element != this.certificationEnum.certification_enum_draft){
      this.dialogService.confirmDialog({
        title: this.certificationEnum.certification_Update_confirm_heading,
        module: 'certification',
        message: this.certificationEnum.certification_Update_body.toString().split("{}").join(element),
        confirmText: this.certificationEnum.certification_Update_primary_btn,
        cancelText: this.certificationEnum.certification_Update_secondary_btn
      }).subscribe((res: any) => {
        if(res){
          this.updateReview(element,isFileUploaded);
        }
      });
    } else {
      this.updateReview(element,isFileUploaded);
    }
  }

  //Helper function to call upload file and then initiate the approve, reject or save as draft.
  updateReview(element:any, isFileUploaded?:boolean){
    if(this.fileArray.length > 0) {
      this.uploadFileAsynchronously().then(async (result: any) => {
        if (result == "success") {
            isFileUploaded = true;
            this.fileArray.forEach((x: any) => {
              this.fileNameArray.push(x.fileName);
            });
            this.updateCertificationReview(element);
        }
        else {
          this.notificationService.showNotification(this.certificationEnum.certification_notify_fileUploadFailed_body, 'error', this.certificationEnum.certification_notify_fileUploadFailed_heading)
        }
      });
    } else {
      this.updateCertificationReview(element);
    }
  }

  //Function to update the review comments and satus for approve or reject
  updateCertificationReview(element: any) {
    try {
      let JSON = {
        "comment": this.comments,
        "supportingFiles": this.fileNameArray,
        "checkListAnswer": this.checklist,
        "Status": element == this.certificationEnum.certification_enum_approve ? this.certificationEnum.certification_enum_approved : element == this.certificationEnum.certification_enum_reject ? this.certificationEnum.certification_enum_rejected : this.certificationEnum.certification_enum_drafted
      }
      this.certificationsService.updateCertificationReview(JSON, this.certificationDetailsData.id).pipe(
        catchError(err => {
          if (element == this.certificationEnum.certification_enum_draft) {
            this.notificationService.showNotification(this.certificationEnum.certification_notify_failTo_saveAsDraft_body, 'error', this.certificationEnum.certification_notify_failTo_saveAsDraft_heading)
          } else {
            this.notificationService.showNotification(this.certificationEnum.certification_notify_certificationInitiateFailed_body_p1, 'error', this.certificationEnum.certification_notify_certificationInitiateFailed_heading)
          }
          return throwError(err);
        })
      ).subscribe(res => {
        const solutionId = localStorage.getItem('certification_solution_id')
        if (element == this.certificationEnum.certification_enum_approve) {
          this.certificationsService.statusCertified(solutionId).subscribe(res => console.log(res));
          if(this.isApproveCertification || this.isRejectCertification){
            this.instanceService.getUpdatedCount(true);
          }
          this.notificationService.showNotification(this.certificationEnum.certification_notify_approvalRequestApproved_body_p1 + ' ' + this.certificationDetailsData.packageName + ' ' + this.certificationEnum.certification_notify_approvalRequestApproved_body_p2, 'success', this.certificationEnum.certification_notify_approvalRequestApproved_heading);
        } else if (element == this.certificationEnum.certification_enum_reject) {
          this.certificationsService.certificationRejected(solutionId).subscribe(res => console.log(res));
          if(this.isApproveCertification || this.isRejectCertification){
            this.instanceService.getUpdatedCount(true);
          }
          this.notificationService.showNotification(this.certificationEnum.certification_notify_approvalRequestRejected_body_p1 + ' ' + this.certificationDetailsData.packageName + ' ' + this.certificationEnum.certification_notify_approvalRequestRejected_body_p2, 'success', this.certificationEnum.certification_notify_approvalRequestRejected_heading);
        } else {
          this.notificationService.showNotification(this.certificationEnum.certification_notify_saveAsDraft_body, 'success', this.certificationEnum.certification_notify_saveAsDraft_heading);
        }
        this.router.navigate(['home/developmentandutilities/certification-request/']);
      });
      this.closeDrawer();
    } catch (error) {
      console.log(error)
    }
  }

  //Function to close the drawer.
  closeDrawer() {
    this.certificationsService.closeIntiateCertification(true);
  }

  //Function to download files
  downloadAllFlies() {
    this.certificationsService.closeIntiateCertification(true);
  }

  //Function to unsubscribe certificationDetailsSubscription
  ngOnDestroy(){
    this.certificationsService.closeIntiateCertification(false);
    if(this.certificationDetailsSubscription) {
      this.certificationDetailsSubscription.unsubscribe();
    }
    if(this.certificationChecklistSubscription) {
      this.certificationChecklistSubscription.unsubscribe();
    }
  }

  //Function to valiate the final comment is not empty or has only white spaces.
  onContentChanged(event:any){
    let comment=event.html;
    if(comment?.replace(this.commentRegex, '').trim().length === 0 && !comment.includes("<img")) {
      this.emptyComment=true;
    } else{
      this.emptyComment=false;
    }
   
  }

  OnUpdate(){
    let validateInput = this.checklist.some((row:any)=>{
      return row.comments?.includes("<img");     
    })
    if(this.comments?.includes("<img") || validateInput){
      this.errorMessage = 'Comment includes image please remove image.'
      return true 
    }
    else{
      return false; 
    }
  }
}