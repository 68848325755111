import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { catchError, Subscription, throwError } from 'rxjs';
import { CertificationsService } from 'src/app/core/services/certifications-management/certifications.service';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';
import { CertificationManagement } from 'src/app/shared/enum/certificationManagement';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { USER_CATEGORY_ID } from 'src/app/shared/constants/idn-constants';
import { ExtensionService } from 'src/app/core/services/extension-management/extension.service';
import { InstanceService } from 'src/app/core/services/instance-management/instance.service';

const HELP_ICON = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="49" height="48" viewBox="0 0 49 48" >
    <defs>
      <filter id="Ellipse_40" x="0" y="0" width="79" height="78" filterUnits="userSpaceOnUse">
        <feOffset dy="8" input="SourceAlpha"/>
        <feGaussianBlur stdDeviation="5" result="blur"/>
        <feFlood flood-opacity="0"/>
        <feComposite operator="in" in2="blur"/>
        <feComposite in="SourceGraphic"/>
      </filter>
    </defs>
    <g id="Component_308_1" data-name="Component 308 - 1" transform="translate(0 0)">
      <g transform="matrix(1, 0, 0, 1, -15, -7)" filter="url(#Ellipse_40)">
        <ellipse id="Ellipse_40-2" data-name="Ellipse 40" cx="24.5" cy="24" rx="24.5" ry="24" transform="translate(15 7)" />
      </g>
      <path id="help_FILL0_wght400_GRAD0_opsz48" d="M92.576,195.705a1.169,1.169,0,1,0-.84-.342A1.143,1.143,0,0,0,92.576,195.705Zm-1.09-4.545h1.837a5.1,5.1,0,0,1,.2-1.479,3.665,3.665,0,0,1,1.261-1.541,5.447,5.447,0,0,0,1.37-1.588,3.655,3.655,0,0,0,.4-1.712,3.437,3.437,0,0,0-1.074-2.646,4.022,4.022,0,0,0-2.848-1,4.812,4.812,0,0,0-2.693.763,4.356,4.356,0,0,0-1.7,2.1l1.65.623a2.9,2.9,0,0,1,1.027-1.354,2.738,2.738,0,0,1,1.619-.482,2.5,2.5,0,0,1,1.712.576,1.881,1.881,0,0,1,.654,1.479,2.289,2.289,0,0,1-.4,1.292,6.2,6.2,0,0,1-1.183,1.261,6.331,6.331,0,0,0-1.385,1.6A4.238,4.238,0,0,0,91.487,191.16Zm.965,9.744a12.048,12.048,0,0,1-4.825-.981,12.545,12.545,0,0,1-6.646-6.646,12.438,12.438,0,0,1,0-9.681,12.444,12.444,0,0,1,2.677-3.953,12.764,12.764,0,0,1,3.969-2.662,12.438,12.438,0,0,1,9.681,0,12.432,12.432,0,0,1,6.615,6.615,12.438,12.438,0,0,1,0,9.681,12.764,12.764,0,0,1-2.662,3.969,12.444,12.444,0,0,1-3.953,2.677A12.125,12.125,0,0,1,92.452,200.9Zm0-1.868a10.575,10.575,0,1,0-7.487-3.1A10.185,10.185,0,0,0,92.452,199.036ZM92.452,188.452Z" transform="translate(-66.869 -164.53)" fill="#fff"/>
    </g>
    </svg>`

enum certificationListStatus {
  certified = 'Certified',
  reviewRequested = 'Review Requested',
  rejected = 'Rejected',
  reviewInProgress = 'Review In-Progress',
  certificationRequested='Certification Requested',
  systemCheckSuccess='System Check Success',
  systemCheckFailed='System Check Failed'
}


@Component({
  selector: 'app-certification-approver-list',
  templateUrl: './certification-approver-list.component.html',
  styleUrls: ['./certification-approver-list.component.scss']
})

export class CertificationApproverListComponent implements OnInit {
  displayedColumns: string[] = ['partnerName','customerName', 'extensionType', 'packageName', 'requesterName', 'status', 'actions'];
  dataSource: any;
  public certificationRequestEnum = CertificationManagement;
  certificationList: any;
  ExType: any[] = [{ value: 'Extension All' }];
  customerList: any
  selectAll = "Select All";
  downloadFilesActive: Boolean = false;
  drawerSubscription!: Subscription;
  breadCrumbItems: any = [
    { label: "Home", path: "resources" },
    { label: "Development and Utilities", path: "developmentandutilities/certification-request" },
    { label: "Certification Requests", path: "developmentandutilities/certification-request" },
    { label: "List", path: "development/utilityandservices/certification-request" },
  ];
  isUpdateCertification: Boolean = false;
  SearchInputval: any;
  partnersVal: any;
  extesionVal: any;
  partnerName: any = [];
  noContent: Boolean = false;
  isCertificationDrawerOpen: any;
  requestCertification:boolean = false;
  isCertificationDrawer:boolean = false;
  instanceId: any;
  intiateCertificationExceptionDrawerActive: boolean = false;
  certDetails: any;
  loggedinUserEmail: string | null ="";

  // Make a variable reference to certificationListStatus Enum
  certificationStatus = certificationListStatus;

  //Filter
  searchfilter: any = '';
  sortBy: any = 'modifiedAt_desc';
  pageNumber = 1;
  pageSize = 10;
  extensionType: any = '';
  partner: any = '';
  CertificationStatus: any = '';
  currentPage = 0;
  pageLength: any = 0;
  isCompleteReview: Boolean = false;
  isInitiateReview: Boolean = false;
  isExceptionalApproval: any;
  isInitiateManualCertification: any;
  hasGlobalDataAccess:boolean= false;
  canApproveCertification:boolean = false;
  listData:any;
  differenceInDaysandHours : any ;
  minutesDifference:any;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('searchInput') searchInput!: ElementRef;
  @ViewChild('partnerNameInput') partnerNameInput!: MatSelect;
  @ViewChild('extensionTypeInput') extensionTypeInput!: MatSelect;
  certificationEnum = CertificationManagement;
  certificationListRefreshSubscription!: Subscription;
  difference: any;
  isCustomerUser:boolean = false;
  userCategory: any;
  loggedInUserPartnerId: any;
  isApproveRejectCertification: boolean = false;
  constructor(
    private router: Router,
    private certificationsService: CertificationsService,
    private extensionService: ExtensionService,
    private notificationService: NotificationService,
    private permissionService: PermissionsService,    
    private dialogService: DialogService,
    private instanceService : InstanceService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIconLiteral(
      'HELP_ICON',
      sanitizer.bypassSecurityTrustHtml(HELP_ICON)
    );
  }

  ngOnInit(): void {
    this.isInitiateReview = this.permissionService.getPermission("InitiateReview");
    this.isCompleteReview = this.permissionService.getPermission("CompleteReview");
    this.requestCertification = this.permissionService.getPermission("InitiateAutomaticCertification");
    this.isInitiateManualCertification = this.permissionService.getPermission("InitiateManualCertification");
    this.isExceptionalApproval = this.permissionService.getPermission("InitiateManualCertification-ExceptionApproval");
    this.hasGlobalDataAccess = this.permissionService.hasGlobalDataAccess();
    this.canApproveCertification = this.permissionService.getPermission('ApproveCertification');
    this.loggedInUserPartnerId = this.permissionService.getPartnerDetails().id;
    this.isApproveRejectCertification = this.permissionService.getPermission("ApproveCertification") || this.permissionService.getPermission("RejectCertification");
    this.userCategory=this.permissionService.getUsercategoryId(); 
    if(this.userCategory == USER_CATEGORY_ID.Customer){
      this.isCustomerUser = true;
    }
    else{
      this.isCustomerUser = false;
      this.getApprovedPartners();
    }
    if(this.hasGlobalDataAccess || this.canApproveCertification){
      this.displayedColumns = ['partnerName','customerName', 'extensionType', 'packageName', 'requesterName','slaStatus', 'status', 'actions'];
    }else if( this.isCustomerUser){
      this.displayedColumns = ['extensionType', 'packageName', 'requesterName', 'status', 'actions'];
    }
    
    this.bindExtensionDropDown();

    this.getCertificationList();
    this.drawerSubscription = this.certificationsService.intiateCertificationToggleSubject.subscribe(data => {
      if (data) {
        this.intiateCertificationExceptionDrawerActive = false
        this.isUpdateCertification = false;
        if (this.isUpdateCertification == false) {
          setTimeout(() => this.getCertificationList(), 300)
        }
        this.downloadFilesActive = false;
      }
    });

    this.certificationListRefreshSubscription = this.certificationsService._certificationList.subscribe(res => {
      if(res == "redirectToCertificationList" || res == "redirectToCertificationListFromDetails") {
        this.getCertificationList();
      }
    })
  }

  getCertificationList() {
    let filterValue: any = localStorage.getItem("FilterRecordCertificate");
    this.loggedinUserEmail = localStorage.getItem("email");
    if (filterValue != undefined && filterValue != null && filterValue != '') {
      this.searchfilter = JSON.parse(filterValue).search
      this.extensionType = JSON.parse(filterValue).extensionType != undefined && JSON.parse(filterValue).extensionType != this.selectAll ? JSON.parse(filterValue).extensionType : ""
      this.partner = JSON.parse(filterValue).partner != undefined && JSON.parse(filterValue).partner != this.selectAll ? JSON.parse(filterValue).partner : "";
    }
    this.certificationsService.getApproverCertificationList(this.searchfilter, this.sortBy, this.pageNumber, this.pageSize, this.partner, this.extensionType, this.CertificationStatus).subscribe(response => {
      if (response != undefined && response != null && response != "") {
        this.noContent = false;
        this.dataSource = new MatTableDataSource(response.records);
        this.certificationList = Object.assign([], response);
        this.paginator.pageIndex = this.currentPage;
        this.paginator.length = response.matchingCount;
        this.paginator._intl.itemsPerPageLabel = 'Records per page';
        this.listData = response?.records;
        if(this.listData != undefined || this.listData != "")
        {
          this.calculateDateDifferences();
        }
      } else {
        this.noContent = true;
      }
    });
  }


  calculateDateDifferences() {
    this.differenceInDaysandHours = this.listData?.forEach((record:any) => {
      const reviewerApprovedOn = new Date(record?.reviewerApprovedOn);
      const slaResolutionDeadline = new Date(record?.slaResolutionDeadline);
      if(reviewerApprovedOn > slaResolutionDeadline){
        this.difference = Math.abs(reviewerApprovedOn.getTime() - slaResolutionDeadline.getTime());
      }
      else{
       this.difference = Math.abs(slaResolutionDeadline.getTime() - reviewerApprovedOn.getTime());
      }
      const days = Math.floor(this.difference / (1000 * 60 * 60 * 24));
      let remainingMilliseconds = this.difference % (1000 * 60 * 60 * 24);
      const hours = Math.floor(remainingMilliseconds / (1000 * 60 * 60));
      this.minutesDifference = Math.floor((this.difference % (1000 * 60 * 60)) / (1000 * 60));
      record.differenceInDaysAndHours = {
        days: days,
        hours: hours,
        minutes: this.minutesDifference
      };
    });
  }

  redirectToDetails(element: any) {    
    let Json:any;
    if(!this.isCustomerUser){
    Json = {
      'search': this.searchInput.nativeElement.value,
      'extensionType': this.extensionTypeInput.value,
      'partner': this.partnerNameInput.value,
      'pagesize': this.pageSize,
      'pageNumber': this.pageNumber,
      'currentPage': this.currentPage
    };
    }
    else{
      Json = {
        'search': this.searchInput.nativeElement.value,
        'extensionType': this.extensionTypeInput.value,        
        'pagesize': this.pageSize,
        'pageNumber': this.pageNumber,
        'currentPage': this.currentPage
      };
    }
    localStorage.setItem("FilterRecordCertificate", JSON.stringify(Json))
    this.router.navigate(['home/developmentandutilities/certification-request/details']);
    this.certificationsService.emitCertificationDetails(element);
    localStorage.setItem("certificationId", element.id);
  }

  // Extension drop down
  bindExtensionDropDown() {
    this.extensionService.getExtensionTypes(this.userCategory).subscribe((result: any) => {
      if (result != undefined && result != "" && result != null) {
        this.ExType = result;
      }
    });
  }

  // Partners List drop down
  getApprovedPartners() {
    this.certificationsService.getApprovedPartners().subscribe((res: any) => {
      if (res != null || res != undefined || res != "") {
        this.partnerName = res;
      }
    });
  }

  // Download Files
  downloadFiles(element: any) {
    this.certificationsService.emitCertificationDetails(element);
    if (!this.downloadFilesActive) {
      this.downloadFilesActive = true;
    }
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    if (event.pageIndex > this.currentPage) {
      if (Math.floor(event.length / event.pageSize) == event.pageIndex) {
        this.pageNumber = event.pageIndex + 1
        this.currentPage = event.pageIndex
      }
      else {
        this.pageNumber++;
        this.currentPage++;
      }
    } else {
      if (event.pageIndex == 0) {
        this.pageNumber = 1;
        this.currentPage = 0;
      }
      else {
        this.pageNumber--;
        this.currentPage--;
      }
    }
    this.filter();
  }

  filter() {
    let filters = localStorage.getItem('FilterRecordCertificate');
    if (filters != null) {
      let Json = {
        'search': this.searchInput.nativeElement.value,
        'extensionType': this.extensionTypeInput.value,
        'partner': this.partnerNameInput.value,
        'pagesize': this.pageSize,
        'pageNumber': this.pageNumber,
        'currentPage': this.currentPage
      };
      localStorage.setItem("FilterRecordCertificate", JSON.stringify(Json))
    }
    else {
      this.searchfilter = this.searchInput.nativeElement.value;
      if (this.userCategory != USER_CATEGORY_ID.Customer) {
        if (
          this.partnerNameInput.value != undefined &&
          this.partnerNameInput.value != '' &&
          this.partnerNameInput.value != this.selectAll
        ) {
          this.partner = this.partnerNameInput.value;
        } else {
          this.partner = '';
        }
      }
      if (this.extensionTypeInput.value != undefined && this.extensionTypeInput.value != '' && this.extensionTypeInput.value != this.selectAll) {
        this.extensionType = this.extensionTypeInput.value;
      } else {
        this.extensionType = '';
      }
    }
    this.getCertificationList();
  }

  // ** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    this.sortBy = sortState.active + (sortState.direction == "asc" ? '' : '_' + sortState.direction);
    this.getCertificationList();
  }

  clearFilter() {
    localStorage.removeItem("FilterRecordCertificate")
    this.searchInput.nativeElement.value = ""
    if (this.userCategory != USER_CATEGORY_ID.Customer) {
      this.partnerNameInput.value = '';
    }
    this.extensionTypeInput.value = ""
    this.pageNumber = 1;
    this.pageSize = 10;
    this.currentPage = 0;
    this.filter();
  }
  ClearInput(event: any, filter: any) {
    this.searchInput.nativeElement.value = '';
    this.filter();
  }

  ngOnDestroy() {
    if (this.drawerSubscription) {
      this.drawerSubscription.unsubscribe();
    }
    const url1: string ='/home/developmentandutilities/certification-request/details';
    if(window.location.pathname != url1){
      localStorage.removeItem('FilterRecordCertificate');
    }
  }

  initiateReviewbyReviewer(element: any) {
    const reviewerId = this.permissionService.getUniqueUserid();
    this.certificationsService.initiateReviewbyReviewer(element, reviewerId).pipe(
      catchError(err => {
        if (err.status != 200) {
          this.notificationService.showNotification(this.certificationEnum.certification_notify_certificationRequestFailed_body_p1 + element.packageName + this.certificationEnum.certification_notify_certificationRequestFailed_body_p2, 'error', this.certificationEnum.certification_notify_certificationRequestFailed_heading);
        }
        return throwError(err);
      })
    ).subscribe(response => {
      this.notificationService.showNotification(this.certificationEnum.certification_notify_certificationRequest_body_p1 + element.packageName + this.certificationEnum.certification_notify_certificationRequest_body_p2, 'success', this.certificationEnum.certification_notify_certificationRequest_heading);
      this.getCertificationList();
    });
  }

  //Complete Review
  completeReview(element: any) {
    if (!this.isUpdateCertification) {
      this.isUpdateCertification = true;
      this.certificationsService.emitCertificationDetails(element);
    }
  }

  ngAfterViewInit() {
    this.searchInput.nativeElement.value = this.searchfilter;
    this.extensionTypeInput.value = this.extensionType;
    this.partnerNameInput.value = this.partner
  }

  closeCertificationDrawer(event: any) {
    this.isCertificationDrawer=false;
  }

  toggleCertificationDrawer(data:any){
    if(data=='save'){
      this.isCertificationDrawer=false;
      this.getCertificationList();
    }else{
      this.isCertificationDrawer=data;
    }
  } 

  initiateManualCertification(element:any){
  this.certDetails=element;
    if(element.status == certificationListStatus.systemCheckSuccess) {
      this.certificationsService.initiateManualCertification(element).pipe(
          catchError(err => {
            if(err.status != 200) {
              this.notificationService.showNotification(this.certificationEnum.certification_notify_manualCertificationFailed_body_p1+element.packageName+this.certificationEnum.certification_notify_manualCertificationFailed_body_p2, 'error', this.certificationEnum.certification_notify_manualCertificationFailed_heading);
            }
            return throwError(err);
          })
      ).subscribe(response => {
        if(this.isApproveRejectCertification){
          this.instanceService.getUpdatedCount(true);
        }
        this.notificationService.showNotification(this.certificationEnum.certification_notify_manualCertificationRequested_body_p1+element.packageName+this.certificationEnum.certification_notify_manualCertificationRequested_body_p2, 'success', this.certificationEnum.certification_notify_manualCertificationRequested_heading);
        this.getCertificationList();
      })
     } else if(element.status == "System Check Failed") {
      this.intiateCertificationExceptionDrawerActive = true;
    }

  }
  withdrawRequest(element: any){
    try {
    this.dialogService
        .confirmDialogComments({
          title: "Confirm Request Withdrawal",
          module: 'withdraw-certification',
          message: 'To withdraw your certification request for package <b>'+element.packageName+'</b>, click <b>Confirm</b>',
          confirmText: 'Confirm',
          cancelText: 'Cancel',
        })
        .subscribe((res: any) => {
            if(res){

              let JSON = {
                id: element.id,
                requesterNote: res,
              };
              this.certificationsService.WithdrawManualCertification(JSON).subscribe((resp: any) => {                                 
                  this.notificationService.showNotification(
                    'Your certification request is withdrawn. Request status is changed to System Check Success.',
                    'success',
                    'Success'
                  );
                  this.getCertificationList();              
              });
            }
          });
        } catch (e) {
          console.log(e);
        }
  }

  redirectToHelp() {
    this.dialogService.confirmDialog({
      title: this.certificationEnum.redirect_dialog_heading,
      module: 'resources',
      message: this.certificationEnum.redirect_dialog_body,
      confirmText: this.certificationEnum.redirect_dialog_primary_btn,
      cancelText: this.certificationEnum.redirect_dialog_secondary_btn
    }).subscribe((res: any) => {
      if(res){
        window.open('https://developer-help.icertis.com/docs/managing-certifications-and-releasing-packages/#0-toc-title', '_blank');
      }
    });
 }



}
